<template>
  <b-container fluid>

    <!-- Title bar -->
    <div class="title-bar">
      <h1>{{ $t("titles.carousel") }}</h1>
    </div>
    <b-tabs @input="onTabChange">
      <b-tab >
        <template v-slot:title>{{$t('option.zzpr')}}</template>
        <CarouselUsers />

        </b-tab>
        <b-tab>
          <template v-slot:title>{{$t('labels.carouselmyoffer')}}</template>
          <CarouselMyOffer />
        </b-tab>
        <b-tab>
          <template v-slot:title>{{$t('labels.carouseloffer')}}</template>
          <CarouselOffer />
        </b-tab>
        </b-tabs>
  </b-container>
</template>
<script>
// import { mapGetters, mapState, mapActions } from 'vuex'
// import send_whatsapp_modal from './modals/SendWhatsappModal'
// import axios from 'axios'
import CarouselUsers from './parts/CarouselUsers.vue';
import CarouselOffer from './parts/CarouselOffer.vue';
import CarouselMyOffer from './parts/CarouselMyOffer.vue';

export default {
  name: 'navigation',
  data() {
    return {

    }
  },
  mounted(){
    this.$store.dispatch('get_carousel_people')

  },
  methods:{
    onTabChange(){
      this.$store.dispatch('get_carousel_people')
    }
  }
  ,components:{CarouselUsers,CarouselMyOffer,CarouselOffer}
}
</script>