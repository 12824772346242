<template>
	<div class="popup">
        <div class="popup-header">
          <div >
            {{ username_by_id(contract.user_id) }} @ {{userplanning.project_name}}
          </div>
          <div class="popup-close" @click="close_modal">
            <font-awesome-icon icon="times" />
          </div>
        </div>

        <div class="popup-body">
          <b-container style="width:100% !important; max-width: 100% !important;">
            <b-row>
                <b-col cols="2">
                    {{$t("labels.client")}}  
                </b-col >
                <b-col>
                    <strong>{{company_by_id(project_by_id(contract.project_id).company_id).company_name }}</strong>
                </b-col>
                <b-col cols="2">
                    {{$t("labels.authorizedsignatory")}} 
                </b-col>
                <b-col>
                    <strong>{{username_by_id(userplanning.sign_id)}}</strong>
                </b-col>                
            </b-row>
            <b-row>
                <b-col cols="2">
                    {{$t("labels.title")}}
                </b-col>
                <b-col>
                    <strong>{{userplanning.project_name}}</strong>
                </b-col>
                <b-col cols="2">
                    {{$t("labels.duration")}}
                </b-col>
                <b-col>
                    <strong>{{new Date(userplanning.startdate).toLocaleDateString()}} {{$t("labels.to")}} {{new Date(userplanning.enddate).toLocaleDateString()}}</strong>
                </b-col>                
            </b-row>            
            <hr>
            <HoursSchedule ref="schedule" :contract_id="this.contract_id" :user_id="this.contract.user_id" :week_number="parseInt(week_number)"></HoursSchedule>
          </b-container>
        </div>

        <div class="popup-footer">
            <div v-if="current_user.role=='user' && !weekisclosed" class="btn btn-primary" @click="close_week()">{{ $t("buttons.closeweek") }}</div>
            <div class="btn btn-primary" @click="save_and_close()">{{ $t("buttons.close") }}</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import HoursSchedule from '../parts/HoursSchedule'
//const querystring = require("querystring");

export default {
    name: 'user-hours-modal',
    data() {
        return {
            weekisclosed:true
        }        
    },
	mounted: function () {
        this.weekisclosed = this.$refs && this.$refs.schedule && this.$refs.schedule.week_is_closed()

	}, 
    props: {
        contract_id: Number,
        week_number:Number
    },
    computed: {
        contract() {
            return this.contract_by_id(this.contract_id) 
        },
        userplanning() {
          return this.planning_by_id(this.contract.planning_id)
        },
        ...mapGetters([
            'project_metas',
            'project_by_id',
            'project_new',
            'contract_by_id',
            'user_by_id',
            'username_by_id',
            'planning_by_id',
            'company_by_id',
            'confirm_modal',

        ]),
        ...mapState([
            'projects',
            'settings',
            'companies',
            'hours',
            'current_user',

        ])
    },
    methods: {

        close_week(){
            this.$refs.schedule.close_week();
        },

        save_and_close() {
            this.close_modal()
        },
        ...mapActions([
            'save_project',
            'add_project'
        ]),
        close_modal() {
            this.$emit('close')
        }        
    },
    watch:{

    },
    components:{HoursSchedule}
}
</script>