import axios from 'axios';
import Vue from 'vue'
import { mapGetters, mapState, mapActions } from 'vuex'
import popup_confirm from '../components/modals/PopupConfirm.vue'
import { Preferences } from '@capacitor/preferences';
import { weekNumberYear } from 'weeknumber'
import dayjs from 'dayjs';

var weekOfYear = require("dayjs/plugin/weekOfYear");
var isoWeek = require("dayjs/plugin/isoWeek");
var weekday = require("dayjs/plugin/weekday");

dayjs.extend(isoWeek);
dayjs.extend(weekOfYear);
dayjs.extend(weekday);
import en from 'dayjs/locale/en';

dayjs.locale({
  ...en,
  weekStart: 1,
});

Vue.mixin({
  data() {
    return {
      innerWidth: 0,
      generated_contract_hours:[],
      invoicecosts:[]
    };
  },
  created() {
    this.innerWidth = window.innerWidth;
    window.addEventListener('resize', () => {
      this.innerWidth = window.innerWidth;
    });
  },
  computed: {
    ...mapGetters([
      'contract_by_id',
      'planning_by_id',
      'declaratie_by_id',
      'company_by_id',
      'user_by_id',
      'find_last_planning_user',
      'server_url',
      'hourcode_by_id',
      'factuur_by_id',
      'username_by_id',
      'carouselURL',
      'carouselToken'


    ]),
    ...mapState([
      'current_user',
      'hours',
      'contracts',
      'planning',
      'settings',
      'signatures'
    ]),
    isMobile() {
      return this.innerWidth <= 760;
    },
    frontend(){
      return process.env.VUE_APP_FRONT_END
    },
    buildtarget() { 
      return process.env.VUE_APP_BUILD_TARGET
    },  

  },
  methods: {
    ...mapActions([
      'generate_contract_company',
      'generate_contract',
      'generate_urenbon',
      'save_user',
      'save_company'

    ]),
    async setObject(key, value) {
      // console.log("SetObject>>>>", key, value)
      await Preferences.set({
        key: key,
        value: JSON.stringify({
          name: value
        })
      });
    },
    async getObject(key) {
      const ret = await Preferences.get({ key: key });
      // console.log("RETURN GET Object ", ret)
      return JSON.parse(ret.value);
    },
    formatDate(date) {
      var today = new Date(date);
      var week = this.getWeekNumber(today);
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      return "[Week " + week[1] + "] " + dd + "-" + mm + "-" + yyyy
    },
    formatFloat(val) {
      if (val != undefined && typeof val == 'string') { val.replace(',', '.'); }
      return val
    },
    getWeekNumber(d) {
/*      // Copy date so don't modify original
      d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
      // Set to nearest Thursday: current date + 4 - current day number
      // Make Sunday's day number 7
      d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
      // Get first day of year
      var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
      // Calculate full weeks to nearest Thursday
      var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
      // Return array of year and week number
      return [d.getUTCFullYear(), weekNo];
      */
     let res = weekNumberYear(d)
    //  console.log("GET WEEK FOR ",d,res)
     return [res.year, res.week]
    },
    getWeekNumbervalue(d) {
      d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
      d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
      var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
      var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
      return weekNo;
    },
    getWeekOfYear(year, week) {
      // console.log("getWeekOfYear",year,week)

      // The first monday of the first week includes at least one day of the year
      let firstMondayOfYear = dayjs().year(year).isoWeek(week).day(1);

      let firstMondayOfYearWeek1 = dayjs().year(year).isoWeek(1).day(1);
      // console.log("Monday 1:", firstMondayOfYearWeek1.format("YYYY-MM-DD"));
      if(firstMondayOfYearWeek1.date()>=6 && parseInt(firstMondayOfYearWeek1.year()) === parseInt(year)){
        firstMondayOfYear = firstMondayOfYear.subtract(7, "days");
        // console.log("SUBTRACT 7 DAYS")

      }
    
      // Now make sure it really is the first monday of the year
      // console.log("YEARCHECK", firstMondayOfYear.format("YYYY-MM-DD"))
      // console.log("YEARCHECK", firstMondayOfYear.year(), year)

      let t =  new Array(7)
        .fill(firstMondayOfYear)
        .map((day, idx) => day.add(idx, "day").format("YYYY-MM-DD"));

      // check if 4 january is in this week
      let jan4 = year.toString()+"01-04"
      if(t.indexOf(jan4)){
        return t
      } else {
          
          
        if (parseInt(firstMondayOfYear.year()) !== parseInt(year)) {
          // console.log("ÄDD 7 DAYS")
          firstMondayOfYear = firstMondayOfYear.add(7, "days");
        }
        // console.log("Monday 2:", firstMondayOfYear.format("YYYY-MM-DD"),firstMondayOfYear.date());
        // if (firstMondayOfYear.date() >8) {
        //   firstMondayOfYear = firstMondayOfYear.subtract(7, "days");
        // }
        // return the week for that "real" first monday of the year
        t =  new Array(7)
          .fill(firstMondayOfYear)
          .map((day, idx) => day.add(idx, "day").format("YYYY-MM-DD"));
          // console.log(">DDATES>>>>>",t)
          return t
      }
    },
    getYearvalue(d) {
      d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
      return d.getFullYear();
    },
    getMonday(d) {
      d = new Date(d);
      var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
      return new Date(d.setDate(diff));
    },
    dateToNL(date) {
      var today = new Date(date);
      if(today instanceof Date && !isNaN(today.valueOf())){
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        return dd + "-" + mm + "-" + yyyy
      } else { return "" }
    },
    dayOfWeek(date) {
      var today = new Date(date);
      let weekdays = [this.$i18n.t('labels.daysunday'), this.$i18n.t('labels.daymonday'), this.$i18n.t('labels.daytuesday'), this.$i18n.t('labels.daywednesday'), this.$i18n.t('labels.daythursday'), this.$i18n.t('labels.dayfriday'), this.$i18n.t('labels.daysaturday')]
      return weekdays[today.getDay()];

    },
    dateToYYYYMMDD(date) {
      var today = new Date(date);
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      return yyyy + "-" + mm + "-" + dd
    },
    workdaysincontractweek(contract_id, week){
      let planning = this.planning.filter(pl=>pl.id == this.contract_by_id(contract_id).planning_id)[0] 
      let sd = new Date (planning.startdate)
      let ed = new Date (planning.enddate)
      let dates = []
      while(sd < ed){
        if(this.getWeekNumbervalue(sd)== week){
          dates.push(this.dateToYYYYMMDD(sd))
        }
        sd.setDate(sd.getDate()+1)
      }
      return dates
    },
    checkExtraBranche(user, branche_id){
      if(user.extra_branches==undefined || user.extra_branches=='[]'){
        return false
      } else {
        let ubranches = JSON.parse(user.extra_branches);
        return ubranches.indexOf(branche_id)!=-1
      }
    },
    contractHours(contract_id) {
      let weeks = []
      // console.log("contracthours", contract_id, this.generated_contract_hours[contract_id])
      if (this.generated_contract_hours[contract_id]) {
        return this.generated_contract_hours[contract_id]
      } else {
        let contract = this.contract_by_id(contract_id)
        if(contract){
          let localplanning = this.planning_by_id(contract.planning_id)
          if (localplanning) {
            var d = new Date(localplanning.startdate);
            var startdate = this.getMonday(d)
            d = new Date(localplanning.enddate);
            var enddate 
            if(d.getDay()==1){
              enddate = new Date(d.getTime() + 6 * 24 * 60 * 60 * 1000)
            } else {
                enddate = this.getMonday(d.getTime() + 6 * 24 * 60 * 60 * 1000)
            }
            enddate.setDate(enddate.getDate() - 1)
            while (startdate < enddate) {
              let endweek = new Date(startdate.getTime() + 6 * 24 * 60 * 60 * 1000)
              var loop = new Date(startdate);
              var totalhoursSigned = 0
              var totalhours = 0
              var close_hours = false
              while (loop <= endweek) {
                let thisday = this.dateToYYYYMMDD(loop)//loop.getFullYear()+"-"+(loop.getMonth()+1)+"-"+loop.getDate()
                var newDate = loop.setDate(loop.getDate() + 1);
                loop = new Date(newDate);
                this.hours.filter(obj => {
                  return obj.contract_id === contract_id
                }).filter(item => {
                  return item.workdate === thisday
                }).forEach(houritem => {
                  if (houritem.agree_company == 1) {
                    if(houritem.hours){
                      totalhoursSigned += parseFloat(houritem.hours)
                    }
                    // check declaraties
                    for (const onregel of contract.onregelmatig) {
                      let declaratie_cat = this.declaratie_by_id(onregel.declaratie_id)
                      if (declaratie_cat && houritem[declaratie_cat.name] != undefined && declaratie_cat.active == 1) {
                        totalhoursSigned += parseFloat(houritem[declaratie_cat.name])
                      }
                    }                    
                  } else {
                    if(houritem.hours){
                      totalhours += parseFloat(houritem.hours)
                    }
                    for (const onregel of contract.onregelmatig) {
                      let declaratie_cat = this.declaratie_by_id(onregel.declaratie_id)
                      if (declaratie_cat && houritem[declaratie_cat.name] != undefined && declaratie_cat.active == 1) {
                        totalhours += parseFloat(houritem[declaratie_cat.name])
                      }
                    }                    
                  }
                  if (!close_hours) {
                    close_hours = houritem.close_hours == 1
                  }
                });
              }
              var today = new Date();

              if (startdate <= today && (this.current_user.role == 'user' || this.current_user.role == 'admin' || (this.current_user.id == localplanning.sign_id && close_hours && (totalhours > 0 || totalhoursSigned > 0)))) {

                // if ((this.current_user.role == 'user' || this.current_user.role == 'admin' || (this.current_user.id == localplanning.sign_id && close_hours && (totalhours > 0 || totalhoursSigned > 0)))) {
                if (close_hours) {
                  if (totalhoursSigned > 0) {
                    weeks.push({ 'week': this.getWeekNumber(startdate)[1], 'year': this.getWeekNumber(startdate)[0], 'signed': totalhoursSigned, 'unsigned': totalhoursSigned, 'waiting': 0, 'closed': close_hours })
                  } else {
                    weeks.push({ 'week': this.getWeekNumber(startdate)[1], 'year': this.getWeekNumber(startdate)[0], 'signed': totalhoursSigned, 'unsigned': totalhours, 'waiting': totalhours, 'closed': close_hours })
                  }
                } else {
                  weeks.push({ 'week': this.getWeekNumber(startdate)[1], 'year': this.getWeekNumber(startdate)[0], 'signed': totalhoursSigned, 'unsigned': totalhours, 'waiting': 0, 'closed': close_hours })

                }
              }
              startdate.setDate(startdate.getDate() + 7);
            }
          }
        }
        this.generated_contract_hours[contract_id] = weeks.reverse();
        return this.generated_contract_hours[contract_id]
      }
    },
    checkfilecharacters(f){
      if(f==null) return false
    // eslint-disable-next-line
    let specialChars =/[`!@#$%^&*+=\[\]{};':"\\|,<>\/?~]/;
      if (specialChars.test(f.name)){
        return false
      }
      else {
        return true
      }
    },
    flushContractHours(){
      this.generated_contract_hours=[];
    },
    profile() {
      this.$router.push({ path: '/user/' + this.current_user.id });
    },
    async generate_company_pdf(contract_id) {
      await this.generate_contract_company(contract_id);
      let routeUrl = this.server_url + '/media_contracts/opdrachtgever_overeenkomst-' + contract_id + ".pdf"
      setTimeout(function () {
        window.open(routeUrl, '_blank')
      }, 100);
      return true
    },
    async generate_pdf(item) {
      await this.generate_contract(item.contract_id);
      let routeUrl = this.server_url + '/media_contracts/overeenkomst-' + item.contract_id + ".pdf"
      setTimeout(function () {
        window.open(routeUrl, '_blank')
      }, 100);
    },
    async generate_urenbonpdf(contract_id) {
      await this.generate_urenbon(contract_id);
      let routeUrl = this.server_url + '/media_contracts/urenbon-' + contract_id + ".pdf"
      setTimeout(function () {
        window.open(routeUrl, '_blank')
      }, 750);
    },
    flushWeekTotalInvoices(){
      this.invoicecosts = []
    },
    getWeekTotalInvoice(contract_id, week, year, sale = true, hourcode='') {
      let availableInvoiceCosts = this.invoicecosts.filter( ic =>
        ic.contract_id == contract_id &&
        ic.week == week &&
        ic.year == year &&
        ic.sale == sale &&
        ic.hourcode == hourcode 
      )
      // console.log(availableInvoiceCosts, contract_id, week, year, sale, hourcode)
      if(availableInvoiceCosts.length>0){
        //previously calculated
        return availableInvoiceCosts[0].costs
      } else {
        let contract = this.contract_by_id(contract_id)
        var d = new Date("Jan 01, " + year + " 01:00:00");
        var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
        d.setDate(diff)
        // console.log("gw", d)
        var w
        if(day==1){
           w = d.getTime() + 604800000 * (week-1);

        } else {
          w = d.getTime() + 604800000 * (week);

        }
        var n1 = new Date(w);
        var n2 = new Date(w + 518400000)
        var declaraties = new Object()
        var totalnormal = 0
        var totalextra = 0
        let contracthours = this.hours.filter(h => h.contract_id == contract_id)
        while (n1 <= n2) {
          let thisday = this.dateToYYYYMMDD(n1)
          // console.log(thisday)
          var newDate = n1.setDate(n1.getDate() + 1);
          contracthours.filter(ahour => ahour.workdate == thisday).forEach(availhours => {
            //skip other hourcode when hourcode is supplied
            if(hourcode=='' || availhours.hourcode == hourcode) {
              if(availhours.hours) {totalnormal += parseFloat(availhours.hours)}
              for (const onregel of contract.onregelmatig) {
                let declaratie_cat = this.declaratie_by_id(onregel.declaratie_id)
                if (availhours[declaratie_cat.name] != undefined && declaratie_cat.active == 1) {
                  if (declaratie_cat.name in declaraties) {
                    if(availhours[declaratie_cat.name]){
                      declaraties[declaratie_cat.name] += parseFloat(availhours[declaratie_cat.name])
                    }
                  } else {
                    if(availhours[declaratie_cat.name]){
                      declaraties[declaratie_cat.name] = parseFloat(availhours[declaratie_cat.name])
                    }             
                  }
                }
              }
          }
        
          })        
          // this.hours.filter(ahour => (ahour.workdate == thisday && ahour.contract_id == contract_id)).forEach(availhours => {
          //   //skip other hourcode when hourcode is supplied
          //   if(hourcode=='' || availhours.hourcode == hourcode) {
          //     if(availhours.hours) {totalnormal += parseFloat(availhours.hours)}
          //     for (const onregel of contract.onregelmatig) {
          //       let declaratie_cat = this.declaratie_by_id(onregel.declaratie_id)
          //       if (availhours[declaratie_cat.name] != undefined && declaratie_cat.active == 1) {
          //         if (declaratie_cat.name in declaraties) {
          //           if(availhours[declaratie_cat.name]){
          //             declaraties[declaratie_cat.name] += parseFloat(availhours[declaratie_cat.name])
          //           }
          //         } else {
          //           if(availhours[declaratie_cat.name]){
          //             declaraties[declaratie_cat.name] = parseFloat(availhours[declaratie_cat.name])
          //           }             
          //         }
          //       }
          //     }
          // }
        
          // })
          // console.log(thisday,declaraties, totalnormal)
          n1 = new Date(newDate);
        }
        let realplanningfilter = this.planning.filter(p => p.user_id==contract.user_id && p.project_id == contract.project_id)
        // console.log(realplanningfilter)
        let realplanning = this.planning_by_id(this.contract_by_id(contract_id).planning_id)
        if(realplanningfilter.length>0){
          realplanning = realplanningfilter[0]
        }
        // var totalcosts = sale ? totalnormal * parseFloat(this.planning_by_id(this.contract_by_id(contract_id).planning_id).verkooptarief) : totalnormal * parseFloat(this.planning_by_id(this.contract_by_id(contract_id).planning_id).tarief);
        var totalcosts = sale ? totalnormal * parseFloat(realplanning.verkooptarief) : totalnormal * parseFloat(realplanning.tarief);
        // console.log(totalnormal)
        contract.onregelmatig.forEach(onregelItem => {
          let onregelRule = this.declaratie_by_id(onregelItem.declaratie_id)
          if (onregelRule.active == 1 && declaraties[onregelRule.name] != 0) {
            if (onregelRule.type == "total" && declaraties[onregelRule.name]) {
              totalextra += parseFloat(declaraties[onregelRule.name])
            } else {
              if (sale) {
                if(onregelItem.client_amount && declaraties[onregelRule.name]){
                  totalextra += parseFloat(onregelItem.client_amount) * declaraties[onregelRule.name]
                }
              } else {
                if(onregelItem.zzpr_amount && declaraties[onregelRule.name]){
                  totalextra += parseFloat(onregelItem.zzpr_amount) * declaraties[onregelRule.name]
                }
              }
            }
          }

        });
      this.invoicecosts.push(
          {contract_id: contract_id ,
          week : week ,
          year : year ,
          sale : sale ,
          hourcode : hourcode,
          costs:  totalcosts + totalextra})        
        return totalcosts + totalextra;
      }
    },
    getWeekInvoiceSignDate(contract_id, week, year) {
      let dates = this.getWeekDates(week, year)
      let agree_date = null
      dates.forEach(d =>{
        if(agree_date==null) {
          this.hours.filter(ahour => ahour.contract_id == contract_id)
            .filter( ahour => {
              if(ahour.workdate == d){
                if(agree_date==null&&ahour.agree_date) {
                  agree_date = ahour.agree_date
                }
              }}
          );
        }
      })
      return this.dateToNL(new Date(agree_date))
    },
    getWeekDates(week, year) {
      // console.log("getWeekDates", week, year)
      // console.log("getWeekDates", this.getWeekOfYear(parseInt(year),parseInt(week)))
      return this.getWeekOfYear(parseInt(year),parseInt(week))
      /*
      let d = new Date("Jan 01, " + year + " 01:00:00");
      let day = d.getDay()
      let diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
      d.setDate(diff)
      var w
      if(day==1){
        w = d.getTime() + 604800000 * (week-1);
      } else{
        w = d.getTime() + 604800000 * (week);
      }
      // let w = d.getTime() + 604800000 * (week);
      let n1 = new Date(w);
      let n2 = new Date(w + 518400000)
      let dates = []
      while (n1 <= n2) {
        let thisday = this.dateToYYYYMMDD(n1);
        let newDate = n1.setDate(n1.getDate() + 1);
        dates.push(thisday)
        n1 = new Date(newDate);
      }
      return dates;
      */
    },
    getDistance(lat1, lng1, lat2, lng2) {
      let theta = lng1 - lng2;
      let dist = Math.sin(this.deg2rad(lat1)) * Math.sin(this.deg2rad(lat2)) + Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * Math.cos(this.deg2rad(theta));
      dist = Math.acos(dist);
      dist = this.rad2deg(dist);
      let miles = dist * 60 * 1.1515;
      return (miles * 1.609344);
    },
    deg2rad(deg) {
      return deg * (Math.PI / 180)
    },
    rad2deg(rad) {
      return rad * (180 / Math.PI)
    },
    current_user_active() {
      if(!this.current_user){
        return false
      }
      let timediff = (this.find_last_planning_user(this.current_user.id) - new Date()) / (1000 * 60 * 60 * 24)
      return !(timediff > 0 || timediff > -7)
    },
    plus1week(date) {
      var firstDay = new Date(date);
      return new Date(firstDay.getTime() + 14 * 24 * 60 * 60 * 1000);
    },
    weektotalhtml(contract_id, week) {
      let totalnormal = 0
      let declaraties = new Object()
      let contract = this.contract_by_id(contract_id)
      let wy = week.split("-")

      for (let adate of this.getWeekDates(parseInt(wy[0]), parseInt(wy[1]))) {
      //  console.log(adate)
        this.hours.filter(ahour => ahour.contract_id == contract_id && ahour.workdate == adate && ahour.user_id == contract.user_id)
          .forEach(ahour => {
            //console.log( ahour)
            totalnormal += parseFloat(ahour.hours)
            for (const onregel of contract.onregelmatig) {
              let declaratie_cat = this.declaratie_by_id(onregel.declaratie_id)
              if (ahour[declaratie_cat.name] != undefined && declaratie_cat.active == 1) {
                if (declaratie_cat.name in declaraties) {
                  if(ahour[declaratie_cat.name]){
                    declaraties[declaratie_cat.name] += parseFloat(ahour[declaratie_cat.name])
                  }
                } else {
                  if(ahour[declaratie_cat.name]){
                    declaraties[declaratie_cat.name] = parseFloat(ahour[declaratie_cat.name])
                  }
                }
              }
            }

          });
        // for (let ahour of this.hours) {
        //   if (ahour.contract_id == contract_id && ahour.workdate == adate && ahour.user_id == contract.user_id) {
        //     totalnormal += parseFloat(ahour.hours)
        //     for (const onregel of contract.onregelmatig) {
        //       let declaratie_cat = this.declaratie_by_id(onregel.declaratie_id)
        //       if (ahour[declaratie_cat.name] != undefined && declaratie_cat.active == 1) {
        //         if (declaratie_cat.name in declaraties) {
        //           if(ahour[declaratie_cat.name]){
        //             declaraties[declaratie_cat.name] += parseFloat(ahour[declaratie_cat.name])
        //           }
        //         } else {
        //           if(ahour[declaratie_cat.name]){
        //             declaraties[declaratie_cat.name] = parseFloat(ahour[declaratie_cat.name])
        //           }
        //         }
        //       }
        //     }
        //   }
        // }
      }
      let retstring = "<h3>" + this.$i18n.t("labels.week") + " " + this.$i18n.t("labels.total") + "</h3>" + this.$i18n.t("titles.project_hours") + "<strong> " + totalnormal + "</strong><br/>";
      for (let k in declaraties) {
        retstring += k + "<strong> " + declaraties[k] + "</strong><br/>"
      }
      return retstring
    },
    hoursoverviewHTML(contract_id, week) {
      // let totalnormal = 0
      // let declaraties = new Object()
      console.log("hoursoverviewHTML", contract_id, week)
      let contract = this.contract_by_id(contract_id)
      let totals= {}
      let construct = "<table><tr><td>Datum</td><td>Type</td><td>Omschrijving</td><td>Aantal</td></tr><tr><td style='padding:10px'></td></tr>";
      let wy = week.split("-")
      for (let adate of this.getWeekDates(parseInt(wy[0]), parseInt(wy[1]))) {
          this.hours.filter(ahour => ahour.contract_id == contract_id && ahour.workdate == adate && ahour.user_id == contract.user_id)
          .forEach(ahour => {
            let omschrijving = ""
            if(ahour.hourcode){
              omschrijving =  this.hourcode_by_id(ahour.hourcode).name
            }
            if(ahour.hours && ahour.hours!=0){
              if(totals["Gewerkte uren"]) {
                totals["Gewerkte uren"] += parseFloat(ahour.hours)
              } else {
                totals["Gewerkte uren"] = parseFloat(ahour.hours)
              }
              construct += "<tr><td>"+this.dateToNL(adate)+"</td><td>Gewerkte uren</td><td>"+omschrijving+"</td><td>"+ahour.hours+"</td></tr>"
            }
            contract.onregelmatig.forEach(line => {
              if(line.active==1) {
                let declaratie = this.declaratie_by_id(line.declaratie_id)
                if(ahour[declaratie.name]){
                  construct += "<tr><td>"+this.dateToNL(adate)+"</td><td>"+declaratie.name+ "</td><td>"+omschrijving+"</td><td>"+ahour[declaratie.name]+"</td></tr>"
                  if(totals[declaratie.name]) {
                    totals[declaratie.name] += parseFloat(ahour[declaratie.name])
                  } else {
                    totals[declaratie.name] = parseFloat(ahour[declaratie.name])
                  }                  
                }
              }
              
            });
          })
      }
      construct += "<tr><td style='padding:10px'></td></tr>";
      for (const [key, value] of Object.entries(totals)) {
        construct += "<tr><td></td><td></td><td><strong> Totaal "+`${key}`.toLowerCase()+"</strong></td><td>"+`${value}`+"</td></tr>"

      }  
      construct+="</table>"
      return construct

    },
    update_crediteur(user_id) {
      // make sure boekhoudgemak is active
      if ((this.settings.database=="") || (this.settings.database==undefined)) {
          console.log("boekhoudgemak not active, return")
          return
      }
      let user = this.user_by_id(user_id)

      var crediteur_data = {}
      crediteur_data.city = user.city
      crediteur_data.email = user.email
      crediteur_data.mobilePhone = user.mobile
      crediteur_data.name = user.companyname.substring(0, 40);
      crediteur_data.person = user.fullname
      crediteur_data.shortname = user.firstname.substring(0, 8);
      crediteur_data.street2 = user.adres + " " + user.housenumber
      if(user.country!="NL"){
          crediteur_data.countryId = user.country
      } else {
          crediteur_data.countryId = ""
      }
      crediteur_data.vatNumber = user.vatcode
      crediteur_data.zipCode = user.postcode
      crediteur_data.cocRegistration = user.kvkcode
      crediteur_data.customerId = 7000 + user.id
      crediteur_data.debnum = user.debnum
      crediteur_data.supplierId = user.debnum
      crediteur_data.paymentConditionId =  user.betalingstermijn
      console.log("Sending Crediteur ", crediteur_data)

      try{
          axios.post('/api/create_crediteur', crediteur_data)  .then((response) => {
              console.log('succes',response)
              if (response.data.status && response.data.status != 200) {
                console.log("DAS MIS", response.data)
                let errormessages = ""
                response.data.messages.forEach(element => {
                    errormessages += element.message + "<br>"

                });
                if(response.data.data && response.data.data.messages){
                  response.data.data.messages.forEach(element => {
                      errormessages += element.message + "<br>"

                  });
                }                 

                this.$modal.hide('popup_modal')
                this.$modal.hide('popup_confirm')
                this.$modal.show(popup_confirm, {
                    message: errormessages,
                    header: this.$t('labels.boekhoudgemakfout'),
                }, {
                    name: 'popup_confirm',
                    draggable: false,
                    height: 'auto',
                    width: '90%'
                })
              }  else {            
                if ((user.debnum == undefined || user.debnum == "") && response.data.supplierId){
                    user.debnum = response.data.supplierId
                    this.save_user(user.id)
                }
                // save bankaccount
                let organization = response.data.organizationId
                var bank_data = {}
                bank_data.preferred = true
                bank_data.organizationId = organization
                bank_data.bic = user.bic
                bank_data.name = user.iban.substring(0,20)
                bank_data.iban = user.iban
                bank_data.accountholderName = user.companyname.substring(0,32)
                if(user.bic){
                  bank_data.bankTypeId = user.bic.substring(0,3) == "ING"? "P":"B"
                }
                axios.post('/api/save_bank', bank_data)  .then((response) => {
                  console.log('succes',response)
                  if (response.data.status && response.data.status != 200) {
                    console.log("DAS MIS", response.data)
                    let errormessages = ""
                    response.data.messages.forEach(element => {
                        errormessages += element.message + "<br>"
    
                    });
                    if(response.data.data && response.data.data.messages){
                      response.data.data.messages.forEach(element => {
                          errormessages += element.message + "<br>"
    
                      });
                    }                 
    
                    this.$modal.hide('popup_modal')
                    this.$modal.hide('popup_confirm')
                    this.$modal.show(popup_confirm, {
                        message: errormessages,
                        header: this.$t('labels.boekhoudgemakfout'),
                    }, {
                        name: 'popup_confirm',
                        draggable: false,
                        height: 'auto',
                        width: '90%'
                    })
                  }                   
                })



            }
          })
          } catch(err){
              console.log (err)
          }  
    },
    update_crediteur_snelstart(user_id) {
      // return;
      if ((this.settings.boekhoudprogramma!="snelstart") || (this.settings.boekhoudprogramma==undefined) || this.settings.SyncCrediteuren!=true) {
          console.log("boekhoudgemak not active, return")
          return
      }
      let user = this.user_by_id(user_id)

      var crediteur_data = {
        "relatiesoort": ["Leverancier"]
      }
      crediteur_data.naam = user.companyname.substring(0, 40);
      crediteur_data.vestigingsAdres = {}
      crediteur_data.vestigingsAdres.straat = user.adres + " " + user.housenumber
      crediteur_data.vestigingsAdres.postcode = user.postcode
      crediteur_data.vestigingsAdres.plaats = user.city
      crediteur_data.vestigingsAdres.contactpersoon = user.fullname
      crediteur_data.correspondentieAdres = Object.assign({}, crediteur_data.vestigingsAdres)
      crediteur_data.mobieleTelefoon = user.mobile
      crediteur_data.telefoon = user.mobile
      crediteur_data.email = user.email

      crediteur_data.btwNummer = user.vatcode
      crediteur_data.kvkNummer = user.kvkcode
      crediteur_data.iban= user.iban
      crediteur_data.bic = user.bic
      crediteur_data.debnum = user.debnum
      crediteur_data.id = user.debnum
  
      console.log("Sending Debiteur ", crediteur_data)

      try{
          axios.post('/api/snelstart_create_crediteur', crediteur_data)  .then((response) => {
              console.log('succes',response)
              if (response.data.status && response.data.status != 200) {
                let errormessages = ""
                  if(response.data.messages){
                    response.data.messages.forEach(element => {
                        errormessages += element.message + "<br>"
  
                    });
                  }
                  if(response.data.data && response.data.data.messages){
                    response.data.data.messages.forEach(element => {
                        errormessages += element.message + "<br>"
  
                    });
                  }              
                  console.log("errors",errormessages)    
                  this.$modal.hide('popup_modal')
                  this.$modal.hide('popup_confirm')
                  this.$modal.show(popup_confirm, {
                      message: errormessages,
                      header: this.$t('labels.snelstartfout'),
                  }, {
                      name: 'popup_confirm',
                      draggable: false,
                      height: 'auto',
                      width: '90%'
                  })
  
                }else{
                  if ((user.debnum == undefined || user.debnum == "") && response.data.relatiecode){
                    user.debnum = response.data.id
                    this.save_user(user.id)
                }

              }

              
          })
          } catch(err){
              console.log (err)
          }  
    },

    update_debiteur(company_id) {
      // make sure boekhoudgemak is active
      if ((this.settings.database=="") || (this.settings.database==undefined)) {
          console.log("boekhoudgemak not active, return")
          return
      }
      let company = this.company_by_id(company_id)
      var debiteur_data = {}
      debiteur_data.city = company.city
      debiteur_data.email = company.email
      debiteur_data.telephone = company.phone
      debiteur_data.name = company.company_name.substring(0, 40);
      debiteur_data.shortname = company.company_name.substring(0, 8);
      debiteur_data.street2 = company.adres + " " + company.housenumber
      debiteur_data.vatNumber = company.vatnumber
      debiteur_data.zipCode = company.postcode
      debiteur_data.cocRegistration = company.kvkcode
      debiteur_data.customerId = 9000 + company.id
      debiteur_data.debnum = company.debnum
      try {
          axios.post('/api/create_debiteur', debiteur_data).then((response) => {
              console.log('succes', response)
              if (response.data.status && response.data.status != 200) {
                console.log("DAS MIS", response.data)
                let errormessages = ""
                  if(response.data.messages){
                    response.data.messages.forEach(element => {
                        errormessages += element.message + "<br>"

                    });
                  }
                  if(response.data.data && response.data.data.messages){
                    response.data.data.messages.forEach(element => {
                        errormessages += element.message + "<br>"

                    });
                  }              
                  console.log("errors",errormessages)    
                  this.$modal.hide('popup_modal')
                  this.$modal.hide('popup_confirm')
                  this.$modal.show(popup_confirm, {
                      message: errormessages,
                      header: this.$t('labels.boekhoudgemakfout'),
                  }, {
                      name: 'popup_confirm',
                      draggable: false,
                      height: 'auto',
                      width: '90%'
                  })
              } else {
                let company = this.company_by_id(company_id)
                if ((company.debnum == undefined || company.debnum == "") && response.data.customerId) {
                    company.debnum = response.data.customerId
                    this.save_company(company.id)
                }
              }
          })
      } catch (err) {
          console.log(err)
      }
    },  
    update_debiteur_snelstart(company_id) {
    if ((this.settings.boekhoudprogramma!="snelstart") || (this.settings.boekhoudprogramma==undefined) || this.settings.SyncDebiteuren!=true) {
      console.log("boekhoudgemak not active, return")
      return
    }
    let company = this.company_by_id(company_id)

    var debiteur = {
      "relatiesoort": ["Klant"]
    }
    debiteur.naam = company.company_name.substring(0, 40);
    debiteur.vestigingsAdres = {}
    debiteur.vestigingsAdres.straat = company.adres + " " + company.housenumber
    debiteur.vestigingsAdres.postcode = company.postcode
    debiteur.vestigingsAdres.plaats = company.city
    // debiteur.vestigingsAdres.contactpersoon = user.fullname
    debiteur.correspondentieAdres = Object.assign({}, debiteur.vestigingsAdres)
    debiteur.telefoon = company.mobile
    debiteur.email = company.email

    debiteur.btwNummer = company.vatnumber
    debiteur.kvkNummer = company.kvkcode
    debiteur.iban= company.iban
    debiteur.bic = company.bic
    debiteur.debnum = company.debnum
    debiteur.id = company.debnum

    console.log("Sending Crediteur ", debiteur)

    try{
        axios.post('/api/snelstart_create_debiteur', debiteur)  .then((response) => {
            console.log('succes',response)
            if(response.status == 200){
              if ((company.debnum == undefined || company.debnum == "") && response.data.relatiecode){
                company.debnum = response.data.id
                this.save_company(company.id)
              } 
            }
            
        })
        } catch(err){
            console.log (err)
        }  
    },  

    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },    
    isSuperAdmin(id){
      if(!this.settings.superadmins){
        return true;
      } else {
        let currentSuperAdmins = JSON.parse(this.settings.superadmins)
        return currentSuperAdmins.indexOf(id) != -1
      }
    },
    currentUserIsSuperAdmin(){
      if(!this.settings.superadmins){
        return true;
      } else {
        let currentSuperAdmins = JSON.parse(this.settings.superadmins)
        return currentSuperAdmins.indexOf(this.current_user.id) != -1 || this.current_user.id==1
      }
    }, 
    hasSpecialRights(){
      var uu = this.settings.URsenderemail+this.settings.URnewsletter+this.settings.URapppages+this.settings.URdashboard.split(",")
      return uu.indexOf(this.current_user.id)!=-1;

    } ,
    specialRights(page){
      let s = this.settings[page]
        if(s){
        let uu = s.split(",")
        return uu.indexOf(this.current_user.id.toString())!=-1;
        } else {
          return false
        }
    },
    smsEnabled(){
      return ( this.settings.smsuser && this.settings.smspassword && this.settings.smsuser!=""  && this.settings.smspassword !="" )
    },
    switchPortal(){
      this.logout();
      this.$store.commit('resetPortal',"");
      this.setObject('PORTAL', "")
      this.setObject('PORTALEXTRA', "")
    },  
    profileto(id){
      return this.server_url + '/media_profiles/' + id+".jpg"
    },
    companyimageto(id){
      return this.server_url + '/media_company/' + id+".jpg"
    },
    async sendPurchaseInvoiceEmail(data){
      //data consists of
      // data.dates = all dates of given week in given year
      // data.user_id = user_id of invoice
      // data.contract_id = contract_id of invoice
      // data.hourcode = hourcode of invoice

      // data.week = week of invoice
      // data.inkoopfactuur = generate invoice
      // data.id = invoice_id
      console.log(">inkoop", data)
      try {
        await axios.post('/api/generate_inkoop', data).then(async () => {
          var planningdata = this.planning_by_id(this.contract_by_id(data.contract_id).planning_id)
          let message = {}
          message.email = this.user_by_id(data.user_id).email
          message.name = this.username_by_id(data.user_id)
          message.message = this.settings.purchasemessage.replaceAll(/\[first_name\]/gi, this.user_by_id(data.user_id).firstname)
          message.message = message.message.replaceAll(/\[week\]/gi, data.week)
          message.message = message.message.replaceAll(/\[project_name\]/gi, planningdata.project_name)
          message.message = message.message.replaceAll(/\[company_name\]/gi, this.company_by_id(this.project_by_id(this.contract_by_id(data.contract_id).project_id).company_id).company_name)
          message.message = message.message.replaceAll(/\[first_name\]/gi, this.user_by_id(data.user_id).firstname)

          message.subject = this.settings.purchasesubject.replaceAll(/\[first_name\]/gi, this.user_by_id(data.user_id).firstname)
          message.subject = message.subject.replaceAll(/\[week\]/gi, data.week)
          message.subject = message.subject.replaceAll(/\[project_name\]/gi, planningdata.project_name)
          message.subject = message.subject.replaceAll(/\[company_name\]/gi, this.company_by_id(this.project_by_id(this.contract_by_id(data.contract_id).project_id).company_id).company_name)
          message.subject = message.subject.replaceAll(/\[first_name\]/gi, this.user_by_id(data.user_id).firstname)
          // add default cc
          if(this.settings.purchasecc){
            message.cc = []
            message.cc.push(this.settings.purchasecc)
          }
          if(data.hourcode) {
            message.subject = message.subject.replaceAll(/\[hourcode\]/gi, this.hourcode_by_id(parseInt(data.hourcode)).name)
            message.message = message.message.replaceAll(/\[hourcode\]/gi, this.hourcode_by_id(parseInt(data.hourcode)).name)
          } else {
            message.subject = message.subject.replaceAll(/\[hourcode\]/gi, "")
            message.message = message.message.replaceAll(/\[hourcode\]/gi, "")
          }

          message.maindocuments = []
          if(data.inkoopfactuur=='yes'){
            if(data.hourcode) {
              message.maindocuments.push({ file: '/invoices/factuur-' + data.contract_id + "-" + data.user_id + "-" + parseInt(data.week)+ "-" + parseInt(data.hourcode) + ".pdf", name: "Factuur" });
            } else {
              message.maindocuments.push({ file: '/invoices/factuur-' + data.contract_id + "-" + data.user_id + "-" + parseInt(data.week) + ".pdf", name: "Factuur" });
            }
          }
          try {
              await axios.post('/api/contact_user', message).then((response) => {
                if (response.status == 200) {
                  // console.log("mark as exported")
                  let invoice = this.factuur_by_id(data.id)
                  invoice.mailedinkoop = 1
                  axios.post('/api/set_purchase_mailed', { id: data.id, mailedinkoop: 1 })
                }
            })
          } catch (err) {
            console.log(err)
          }
        })
      } catch (err) {
        console.log(err)
      }      
    },  
    async sendSaleInvoiceEmail(data){
      //data consists of
      // data.dates = all dates of given week in given year
      // data.user_id = user_id of invoice
      // data.contract_id = contract_id of invoice
      // data.hourcode = hourcode of invoice

      // data.week = week of invoice
      // data.inkoopfactuur = generate invoice
      // data.id = invoice_id
      // console.log(">sendSaleInvoiceEmail", data)

      try {
        await axios.post('/api/generate_verkoop', data).then(async (response) => {
          console.log("Generate done, ",response)
          var planningdata = this.planning_by_id(this.contract_by_id(data.contract_id).planning_id)
          if (data.invoiceemail) {
            let message = {}
            message.email = this.user_by_id(data.invoiceemail).email
            message.name = this.username_by_id(data.user_id)
            message.message = "Beste " + this.user_by_id(data.invoiceemail).firstname + ",<br>" +
              "Bijgaand tref je onze factuur met urenbon van week " + data.week + " met betrekking tot project " + this.username_by_id(data.user_id) + "<br>" +
              "Deze factuur heeft betrekking op het project " + planningdata.project_name
            if (planningdata.project_reference_number) {
              message.message += " (" + planningdata.project_reference_number + ")"
            }
            message.subject = "Factuur ZZPR.nl " + this.username_by_id(data.user_id) + " - week " + data.week + " - " + planningdata.project_name
            if (planningdata.project_reference_number) {
              message.subject += " (" + planningdata.project_reference_number + ")"
            }
            message.message = this.settings.salemessage
            message.message = message.message.replaceAll(/\[week\]/gi, data.week)
            message.message = message.message.replaceAll(/\[project_name\]/gi, planningdata.project_name)
            message.message = message.message.replaceAll(/\[zzper\]/gi, this.username_by_id(data.user_id))
            message.message = message.message.replaceAll(/\[first_name\]/gi, this.user_by_id(data.invoiceemail).firstname)
            if (planningdata.project_reference_number) {
              message.message = message.message.replaceAll(/\[project_reference_number\]/gi, "(" + planningdata.project_reference_number + ")")
            } else {
              message.message = message.message.replaceAll(/\[project_reference_number\]/gi, "")
            }

            message.subject = this.settings.salesubject
            message.subject = message.subject.replaceAll(/\[week\]/gi, data.week)
            message.subject = message.subject.replaceAll(/\[project_name\]/gi, planningdata.project_name)
            message.subject = message.subject.replaceAll(/\[zzper\]/gi, this.username_by_id(data.user_id))
            message.subject = message.subject.replaceAll(/\[first_name\]/gi, this.user_by_id(data.invoiceemail).firstname)
            if (planningdata.project_reference_number) {
              message.subject = message.subject.replaceAll(/\[project_reference_number\]/gi, "(" + planningdata.project_reference_number + ")")
            } else {
              message.subject = message.subject.replaceAll(/\[project_reference_number\]/gi, "")
            }
            if(data.hourcode) {
              message.subject = message.subject.replaceAll(/\[hourcode\]/gi, this.hourcode_by_id(parseInt(data.hourcode)).name)
              message.message = message.message.replaceAll(/\[hourcode\]/gi, this.hourcode_by_id(parseInt(data.hourcode)).name)
            } else {
              message.subject = message.subject.replaceAll(/\[hourcode\]/gi, "")
              message.message = message.message.replaceAll(/\[hourcode\]/gi, "")
            }
            message.maindocuments = []

            if(data.hourcode){
              message.maindocuments.push({ file: '/invoices/verkoopfactuur-' + data.contract_id + "-" + data.user_id + "-" + parseInt(data.week)+ "-" + parseInt(data.hourcode) + ".pdf", name: "Factuur" });
            } else {
              message.maindocuments.push({ file: '/invoices/verkoopfactuur-' + data.contract_id + "-" + data.user_id + "-" + parseInt(data.week) + ".pdf", name: "Factuur" });
            }
            //urenbonnen
            let bonnen = this.urenbonnen.filter(bon => {
              return bon.contract_id == data.contract_id && bon.week == parseInt(data.week)
            })
            if (bonnen.length > 0) {
              bonnen.forEach(bon => {
                if(bon.name.substring(0,6)!="intern"){
                  message.maindocuments.push({ file: '/' + bon.file, name: bon.name });
                }
              })
            }
          // add default cc
          if(this.settings.salecc){
            message.cc = []
            message.cc.push(this.settings.salecc)
          }                  
            try {
              await axios.post('/api/contact_user', message).then((response) => {
                console.log(">Maildone ", response)
                if (response.status == 200) {
                  // console.log("mark as exported")
                  data.salemailselect = 0
                  let invoice = this.factuur_by_id(data.id)
                  invoice.mailedverkoop = 1
                  axios.post('/api/set_sale_mailed', { id: data.id, mailedverkoop: 1 })
                }
              })
            } catch (err) {
              console.log(err)
              console.log(">MailError ", err)
              let invoice = this.factuur_by_id(data.id)
              invoice.mailedverkoop = 0
              axios.post('/api/set_sale_mailed', { id: data.id, mailedverkoop: 0 })                    

            }
          }
        })
      } catch (err) {
        console.log("Generate Error, ",err)

        console.log(err)
      }      
    },
    hassignature(day, user_id, contract_id){
      let c = this.signatures.filter(s=>s.user_id==user_id&&s.workdate==day&& s.contract_id==contract_id)
      let realplanning = this.planning_by_id(this.contract_by_id(contract_id).planning_id)
      return (c.length>0 && this.settings.RegisterWorkhours && realplanning.workhours=='yes')
    },
    hasonlysignature(day, user_id, contract_id){
      let c = this.signatures.filter(s=>s.user_id==user_id&&s.workdate==day&& s.contract_id==contract_id)
      let realplanning = this.planning_by_id(this.contract_by_id(contract_id).planning_id)
      // console.log("hasonlysignature", contract_id, realplanning.workhours)
      return (c.length>0  && realplanning.workhours!='yes')
    },
    showwerkbriefje(day, user_id, contract_id){
      let data = {}
      data.workdate = day
      data.user_id = user_id
      data.contract_id = contract_id
      data.force = true
      try {
        axios.post('/api/generate_worknote', data).then(() => {
          var routeUrl = this.server_url + '/media_signatures/worknote-'  + contract_id + "-" + user_id +"-"+ day+  ".pdf"
          window.open(routeUrl, '_blank')
          this.generate_inkoop = false

        })
      } catch (err) {
        console.log(err)
      }

    },
    showsignature(day, user_id, contract_id){
      try {
        var routeUrl = this.server_url + '/media_signatures/' + user_id + "-" + day + "-" + contract_id + ".png"
        window.open(routeUrl, '_blank')
      } catch (err) {
        console.log(err)
      }

    },
    dateRangeOverlaps(a_start, a_end, b_start, b_end) {
      // console.log(a_start, a_end, b_start, b_end)
      if (a_start <= b_start && b_start <= a_end) return true; // b starts in a
      if (a_start <= b_end   && b_end   <= a_end) return true; // b ends in a
      if (b_start <  a_start && a_end   <  b_end) return true; // a in b
      return false;
  },    
  async getConfirmHoursLink(contract_id, week){
    console.log(">>>getConfirmHoursLinkgetConfirmHoursLink", contract_id, week)
    let obj = {}
    obj.contract_id = contract_id
    obj.week = week
    console.log(">>>>", obj)//JSON.stringify(obj))
    var encrypted = ""

    await axios.post('/api/get_confirm_hash/', obj)  .then( async (response) => {
      console.log('succes',response)
      encrypted = response.data
      return encrypted

    })
  },
  
  async getCarouselToken(){
    if(this.carouselToken==null){
      var token=null;
      var id = null;
      await axios.post("https://carousel.flyingfreelancers.nl/api/login",{username:"development", password:"ZZPR_Secret!!!!"})
      .then(function (response) {
        token =  response.data[1]
        id = response.data[0].id
      })
      .catch(function (error) {
        console.log(error);
      });
      this.$store.commit('carouselID',id)
      this.$store.commit('carouselToken',token)
      console.log("<TOKEN",token)
      console.log("<this.TOKEN",this.carouselToken)
    }
  }
  }
})