<template>
  <b-modal id="BSAbsentModalID" centered size="lg" >
    <template #modal-header>
      <h2 class="modal-title">{{ $t("labels.setabsent") }}</h2>
      {{ contract_id }} {{weeknumber}} {{yearnumber}}
    </template>

    <form class="form">
      <div class="form-row" v-if="weeknumber==-1">
        <div class="col-3">
          <label for=""> {{ $t("labels.week") }}</label>
        </div>
        <div class="col-3">
          <input type="number" v-model="freeweeknumber"/>
          </div>
          <div class="col-3">
          <label for=""> {{ $t("labels.year") }}</label>
        </div>
        <div class="col-3">
          <input type="number" v-model="freeyearnumber"/>
          </div>
      </div>
      <div class="form-row"  v-if="current_user && current_user.role == 'admin'">
        <div class="col-3">
          <label for=""> {{ $t("labels.invoice_extra_description") }}</label>
        </div>
        <div class="col"  >
          <b-input-group>
            <b-form-textarea size="sm" rows="8" v-bind:type="reasonkey" v-bind:id="'bind-remarks'" :ref="reasonkey"
              class="form-control" v-model="reason" />
            <b-input-group-append>
              <b-button class="multi-append" squared href="javascript:;"
                @click="append_data_multi('reasonkey')">
                <font-awesome-icon icon="plus-circle" />
              </b-button>
            </b-input-group-append>
          </b-input-group>          
        </div>
      </div>
      <div v-else class="form-row" >user</div>
    </form>

    <template #modal-footer>
      <div v-if="wasabsent" @click="setPresent" class="btn btn-primary">
          <font-awesome-icon icon="eye" /> {{ $t('labels.setpresent') }}
      </div>
      
      <div @click="saveData" class="btn btn-primary">
        <font-awesome-icon icon="eye-slash" /> {{ $t('labels.setabsent') }}
      </div>
    </template>
  </b-modal>
</template>


<script>
import { mapGetters, mapState } from 'vuex'
import axios from 'axios'

export default {
  name: 'invoice-data-modal',
  data() {
    return {
      reason: null,
      reasonkey: "reasonkey",
      wasabsent:false,
      freeweeknumber:0,
      freeyearnumber:0,
    }
  },
  props: {
    contract_id: Number,
    weeknumber: Number,
    yearnumber: Number
  },
  computed: {

    ...mapState([
      'invoiceextra',
      'current_user',

    ]),
    ...mapGetters([
    'append_data_string',

    ])
  },
  mounted(){

    this.freeweeknumber = this.getWeekNumbervalue(new Date())
    // console.log("mounted", this.freeweeknumber)
    this.freeyearnumber = new Date().getFullYear()
    
  },
  methods: {

    append_data_multi(key) {
      var current = ""
      if (this.reason && this.reason != null) {
        current = "\n" + this.reason
      }
      this.reason = this.append_data_string + current
      this.componentKey += 1;
      if (this.$refs[key]) {
        if (this.$refs[key][0]) {
          this.$refs[key][0].focus()
          this.$nextTick(() => {
            this.$refs[key][0].selectionEnd = 9
          })
        } else {
          this.$refs[key].focus()
          this.$nextTick(() => {
            this.$refs[key].selectionEnd = 9;
          })
        }
      }
    },
    saveData(){
      if(this.weeknumber!=-1){
        axios.post('/api/set_week_absent', {contract_id:this.contract_id, week:this.weeknumber, year:this.yearnumber, absent:1, reason:this.reason}).then(async () => {
        this.$store.dispatch('get_invoiceextra')})

         this.close_modal()
        } else {
          axios.post('/api/set_week_absent', {contract_id:this.contract_id, week:this.freeweeknumber, year:this.freeyearnumber, absent:1, reason:this.reason}).then(async () => {
            this.$store.dispatch('get_invoiceextra')
          })          
        }

    },
    setPresent() {
      try {
        axios.post('/api/set_week_absent', {contract_id:this.contract_id, week:this.freeweeknumber, year:this.freeyearnumber, absent:0, reason:""}).then(async () => {
          this.$store.dispatch('get_invoiceextra')
        })
      } catch (err) {
        console.log(err)
      }
    },
    close_modal() {
      this.$bvModal.hide('BSAbsentModalID')
    },
  },
  watch:{
    freeweeknumber(){
      let data = this.invoiceextra.filter(i => i.contract_id == this.contract_id && i.week == this.freeweeknumber && i.year == this.yearnumber && i.absent == 1)
      if(data.length>0){
        this.reason = data[0].reason
        this.wasabsent = true
      } else{
        this.reason = ""
        this.wasabsent = false
      }
    },
    freeyearnumber(){
      let data = this.invoiceextra.filter(i => i.contract_id == this.contract_id && i.week == this.freeweeknumber && i.year == this.yearnumber && i.absent == 1)
      if(data.length>0){
        this.reason = data[0].reason
        this.wasabsent = true
      } else{
        this.reason = ""
        this.wasabsent = false

      }
    },    
    invoiceextra(){
      let data = this.invoiceextra.filter(i => i.contract_id == this.contract_id && i.week == this.freeweeknumber && i.year == this.yearnumber && i.absent == 1)
      if(data.length>0){
        this.reason = data[0].reason
        this.wasabsent = true
      } else{
        this.reason = ""
        this.wasabsent = false
      }
    },
    weeknumber(){
      let data = this.invoiceextra.filter(i => i.contract_id == this.contract_id && i.week == this.weeknumber && i.year == this.yearnumber)
      if(data.length>0){
        this.reason = data[0].reason
      }
    }

  }
}    
</script>
