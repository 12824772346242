<template>
  <b-container fluid>

    <!-- Title bar -->
    <div class="title-bar">
      <h1>{{ $t("titles.projects") }}</h1>
      <div @click="resetSettings()" class="btn btn-primary">
              <font-awesome-icon icon="undo" /> {{ $t("buttons.reset") }}
          </div>      
      <div v-if="createAny('project')" @click="edit_project({ id: 0 })" class="btn btn-primary">
        <font-awesome-icon icon="plus" /> {{ $t("buttons.addproject") }}
      </div>
    </div>

    <!-- Filter bar -->
    <b-row class="filter-bar">
      <b-col md="4" xl>
        <b-form-group :label="$t('labels.search')" label-size="lg">
          <b-form-input id="filter-input" v-model="projectsettings.filter" type="search" :placeholder="$t('strings.typetosearch')">
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="4" xl v-if="createAny('project')">
        <b-form-group :label="$t('labels.branche')">
          <b-form-select name="branches" class="form-control" v-model="projectsettings.currentbranche">
            <option value="-1"> {{ $t("option.all") }}</option>
            <option v-for="(branche, idx) in activebranches" :key="idx" v-bind:value="branche.id">
              {{ branche["name"] }}
            </option>
          </b-form-select>

        </b-form-group>
      </b-col>
      <b-col cols="12" v-if="createAny('project')">
        <b-form-checkbox v-model="projectsettings.only_public" name="checkbox-1" value="1" unchecked-value="0">
          {{ $t('labels.showproject')}}
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row v-if="this.brancheworkfields.length > 0">
      <b-col md="4" xl style="text-align:left">
        <h4>{{ $t("labels.workfields") }}</h4>
        <ul style=" list-style-type: none;  columns: 4;">
          <li v-for="workfield in this.brancheworkfields" :key="workfield.id">
            <input type="checkbox" :id="workfield.id" :value="workfield.id" v-model="selectedWorkfields">
            <label style="padding-left:10px;" :for="workfield.id"> {{ workfield.name }}</label>
          </li>
        </ul>
      </b-col>
    </b-row>

    <!-- Table -->
    <b-table :items="branche_projects" :fields="fields" :current-page="projectsettings.currentPage" :per-page="perPage" :filter="projectsettings.filter"
      :filter-included-fields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection" sort-icon-left @filtered="onFiltered" @row-clicked="onProjectClicked">
      <template #cell(pulldown)="row">
        <b-dropdown id="dropdown-dropup" dropup :text="$t('buttons.doaction')" variant="primary">
          <b-dropdown-item v-if="row.item.zzprusers  && row.item.zzprusers.length==0" @click="confirm_delete_project(row.item.id)">
            <font-awesome-icon icon="trash" /> {{$t('labels.deleteproject')}}
          </b-dropdown-item>
          <b-dropdown-item @click="editProjectModal(row.item)">
            <font-awesome-icon icon="cog" /> {{$t('labels.editproject')}}
          </b-dropdown-item>
          <b-dropdown-item @click="clone_project(row.item)">
            <font-awesome-icon icon="clone" /> {{$t('labels.duplicate')}}
          </b-dropdown-item>
          <b-dropdown-item @click="notifyUsersForProject(row.item)">
            <font-awesome-icon icon="exclamation" /> {{$t('labels.notify')}}
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <template #cell(projectnumber)="row">
        {{ row.item.projectnumber }}
      </template>
      <template #cell(extra)="row">
        <span v-html='status(row.item.extra)'></span>
      </template>
      <template #cell(id)="row">
        <span v-html='companyInfo(row.item)'></span>
      </template>
      <template #cell(zppractions)="row">
        <a href="javascript:;" :style="AppPrimaryButton()" class="btn btn-primary" @click="react_to_project(row.item.id)">
          <font-awesome-icon icon="heart" /> {{ $t('labels.subscribe_project') }}
        </a>
      </template>
    </b-table>
    <b-row>
      <b-col>
        <b-pagination :style="AppPrimaryColor()" v-model="projectsettings.currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import popup_modal from './modals/PopupModal.vue'
import subscribe_to_project_modal from './modals/SubscribeToProjectModal.vue'

export default {
  name: 'projectsscreen',
  data() {
    return {
      // currentPage: 1,
      perPage: 10,
      sortBy: 'id',
      sortDesc: true,
      sortDirection: 'desc',
      // filter: null,
      filterOn: [],
      // currentbranche: -1,
      selectedWorkfields: [],
      selectedID: null,
      // only_public: "1"

    }
  },
  computed: {
    totalRows: {
      get() {
        return this.branche_projects.length
      },
      set() { }
    },
    branche_projects() {
      let brancheprojects = []
      for (var i = 0; i < this.projects.length; i++) {
        let addbranche = this.projectsettings.currentbranche == -1
        let addworkfields = this.selectedWorkfields.length == 0
        let company = this.company_by_id(this.projects[i].company_id)
        if (!addbranche && company.branche_id == this.projectsettings.currentbranche) {
          addbranche = true
        }

        let projectcopy = this.projects[i]
        let hidepublic = true;

        if (this.projectsettings.only_public == "1" && (this.projects[i].ispublic == 0 || this.projects[i].ispublic == null)) {
          hidepublic = false
        }
        projectcopy.company_name = company.company_name
        projectcopy.branche_id = company.branche_id
        projectcopy.projectnumber =  this.projects[i].id
        
        if (!addworkfields) {
          let inter = this.projects[i].workfields.filter(value => this.selectedWorkfields.includes(value))
          addworkfields = inter ? inter.length > 0 : false

        }

        if (hidepublic && (addbranche && addworkfields || (this.projectsettings.currentbranche == -1))) {
          // niet actieve branches verbergen
          if ((this.branche_by_id(company.branche_id).active == 1)) {
            brancheprojects.push(projectcopy)
          }
        }
      }
      return brancheprojects
    },
    activebranches() {
      let _activebranches = [];
      for (var i = 0; i < this.branches.length; i++) {
        if (this.branches[i].active) {
          _activebranches.push(this.branches[i])
        }
      }
      return _activebranches
    },
    fields() {
      if (this.createAny('project')) {
        return [
          { key: 'publishdate', formatter: this.formatDate, label: this.$i18n.t('meta.aangemaakt'), sortable: true, sortDirection: 'desc' },
          { key: 'id', label: this.$i18n.t('labels.companyname'), sortable: true, sortDirection: 'desc' },
          { key: 'projectnumber', label: this.$i18n.t('meta.projectrefnumber') ,sortable: true, sortDirection: 'desc'},
          { key: 'title', label: this.$i18n.t('labels.title'), sortable: true, sortDirection: 'desc' },
          { key: 'location', label: this.$i18n.t('labels.location'), sortable: true, sortDirection: 'desc' },
          { key: 'startdate', formatter: this.formatDate, label: this.$i18n.t('labels.startdate'), sortable: true, sortDirection: 'desc' },
          { key: 'enddate', formatter: this.formatDate, label: this.$i18n.t('labels.enddate'), sortable: true, sortDirection: 'desc' },
          // { key: 'extra', label: this.$i18n.t('meta.extra') },
          { key: 'pulldown', label: this.$i18n.t('labels.actions') },
        ]
      } else {
        return [
          { key: 'id', label: this.$i18n.t('labels.companyname'), sortable: true, sortDirection: 'desc' },
          { key: 'title', label: this.$i18n.t('labels.title'), sortable: true, sortDirection: 'desc' },
          { key: 'location', label: this.$i18n.t('labels.location'), sortable: true, sortDirection: 'desc' },
          { key: 'startdate', formatter: this.formatDate, label: this.$i18n.t('labels.startdate'), sortable: true, sortDirection: 'desc' },
          { key: 'enddate', formatter: this.formatDate, label: this.$i18n.t('labels.enddate'), sortable: true, sortDirection: 'desc' },
          { key: 'zppractions', label: this.$i18n.t('labels.actions') },
        ]

      }
    },
    ...mapState([
      'projects',
      'branches',
      'workfields',
      'projectsettings'
    ]),
    ...mapGetters([
      'company_by_id',
      'confirm_modal',
      'project_by_id',
      'branche_by_id',
      'username_by_id',
      'user_by_id',
      'createAny'

    ]),
    brancheworkfields: {
      get() {
        let br_workfields = []
        for (var i = 0; i < this.workfields.length; i++) {
          if (this.workfields[i].branche_id == this.projectsettings.currentbranche && this.workfields[i].active) {
            br_workfields.push(this.workfields[i])
          }
        }
        return br_workfields
      }
    }
  },
  methods: {
    resetSettings(){
          this.$store.commit('projectsettings',{            
            filter:null,
        currentbranche:-1,
        only_public:"1",
        currentPage:1,        
           },
          )  
        },
    status(val) {
      let res = ""
      if (val != undefined) {
        res = "<pre>" + val + "</pre>"
      }
      return res
    },
    companyInfo(item) {
      let res = ""
      if (item.company_name) {
        res = "<b>" + item.company_name + "</b><br>"
        res += this.username_by_id(item.user_id) + "<br>"
        let user = this.user_by_id(item.user_id);
        if (user && user.mobile != undefined) {
          res += "<font-awesome-icon icon=\"mobile\" class=\"multi-append\"/> " + user.mobile + "<br>"
        }
      }
      return res
    },
    confirm_delete_project(id) {
      this.selectedID = id
      let message = this.$i18n.t('labels.confirmdeletehead') + this.project_by_id(id).title + this.$i18n.t('labels.confirmdeletetail')
      this.$modal.hide('popup_modal')
      this.$modal.show(popup_modal, {
        message: message,
      }, {
        name: 'popup_modal',
        draggable: false,
        height: 'auto',
        width: '500px'
      }, {
        'before-close': this.modalClose
      })

      this.$emit('close')

    },
    modalClose() {
      if (this.confirm_modal.id == 1) {
        this.delete_project(this.selectedID);
        for (let i = 0; i < this.projects.length; i++) {
          if (this.projects[i].id == this.selectedID) {
            this.projects.splice(i, 1);
          }
        }
      }
    },

    ...mapActions([
      'delete_project',
      'save_project',
      'add_project'
    ]),
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.projectsettings.currentPage = 1
    },
    onProjectClicked(row) {
      if (this.createAny('project')) {
        this.$router.push({ path: '/project/' + row.id });
      } else {
        this.$router.push({ path: '/projectinfo/' + row.id });
      }

    },
    number_zzpr(zzprusers) {
      return zzprusers.length
    },
    edit_project(project) {
      if (project.id == 0) {
        const dateObj = new Date();
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const day = String(dateObj.getDate()).padStart(2, '0');
        const year = dateObj.getFullYear();
        // let projectnumber = 0
        // for (const pr of this.projects) {
        //   if (pr.id > projectnumber) {
        //     projectnumber = pr.id
        //   }
        // }
        // projectnumber++
        this.$store.commit('new_project', { id: 0, ispublic: 0, company_id: 0, publishdate: year + "-" + month + "-" + day, workfields: [], zzprusers: [] })
        this.editProjectModal(project)
      }
    },
    react_to_project(projectdata) {
      console.log(projectdata)
      this.$modal.hide('subscribe_to_project_modal')
      this.$modal.show(subscribe_to_project_modal, {
        project_id: projectdata,
      }, {
        name: 'subscribe_to_project_modal',
        draggable: '.popup-header',
        height: 'auto',
        width: '90%',
        shiftY: 0
      })

      this.$emit('close')
    },
    clone_project(item) {
      let clone = Object.assign({}, this.project_by_id(item.id))
      if(clone.id == clone.project_reference_number)
        clone.project_reference_number=""
      clone.id = 0;
      clone.title = "(COPY) " + clone.title
      clone.projectnumber = ""
      this.add_project(clone)

    },

  },
}
</script>
