<template>
    <b-container v-if="createAny('bedrijf')" fluid>
        <div class="title-bar">
            <h1>{{ $t("titles.companies") }} ({{totalRows}})</h1>
            <div @click="resetSettings()" class="btn btn-primary">
              <font-awesome-icon icon="undo" /> {{ $t("buttons.reset") }}
          </div>
            <div @click="edit_company({ id: 0 })" class="btn btn-primary">
                <font-awesome-icon icon="plus" /> {{ $t("buttons.addcompany") }}
            </div>
        </div>
        <div class="filter-bar">

            <b-row>
          <b-col md="4" xl>
                <b-form-group :label="$t('labels.filter')">
                    <b-form-input id="filter-input" v-model="companysettings.filter" type="search" ref="searchbox"
                        :placeholder="$t('strings.typetosearch')"></b-form-input>
                </b-form-group>
            </b-col>
            <b-col md="4" xl>
                <b-form-group :label="$t('labels.branche')">
                    <b-form-select name="branches" class="form-control" v-model="companysettings.currentbranche">
                        <option value="-1"> {{ $t("option.all") }}</option>
                        <option v-for="(branche,idx) in activebranches" :key="idx" v-bind:value="branche.id">
                            {{ branche["name"]}}
                        </option>
                    </b-form-select>
                </b-form-group>
            </b-col>
            <b-col md="4" xl>
                <b-form-group :label="$t('labels.addedby')">
                    <select name="admin" class="form-control admins" v-model="companysettings.owner">
                        <option value="-1"> {{ $t("option.all") }}</option>

                        <option v-for="(user,idx) in owners" :key="idx" v-bind:value="user.id">
                            {{ user["firstname"]}} {{ user["middlename"] }} {{ user["lastname"] }}
                        </option>
                    </select>
                </b-form-group>
            </b-col>
            <b-col md="4" xl>
                <b-form-group :label="$t('labels.tag')">
                    <v-select label="tag" :options="tags" class="contactpersoon" v-model="companysettings.selectedtag"></v-select>
                </b-form-group>
            </b-col>
            <b-col cols="12" v-if="settings.EnableBlacklist">
                <b-form-checkbox v-model="companysettings.showblacklist" name="checkbox-1" value="1" unchecked-value="0">{{
                $t("labels.blacklist") }}</b-form-checkbox>
            </b-col>
        </b-row>
</div>
        <b-table sort-icon-left :items="branche_companies" :fields="fields" :current-page="companysettings.currentPage"
            :per-page="perPage" :filter="companysettings.filter" :filter-included-fields="filterOn" :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered"
            @row-clicked="onCompanyClicked" :tbody-tr-class="rowClass">
            <template #cell(photo)="row">
                <img class="profile-photo" :src="companyimageto(row.item.id)" />
            </template>
            <template #cell(branche_id)="row">
                <span v-html='brancheName(row.item)'></span>

            </template>
            <template #cell(company_name)="row">
                <strong>{{ row.item.company_name }}</strong>
                <div v-if="false" v-html='contactpersons(row.item)'></div>

            </template>            
            <template #cell(pulldown)="row">
                <b-dropdown id="dropdown-dropup" dropup :text="$t('buttons.doaction')" variant="primary">
                    <b-dropdown-item @click="confirm_delete_company(row.item.id)">
                        <font-awesome-icon icon="trash" /> {{$t('labels.deletecompany')}}
                    </b-dropdown-item>
                    <b-dropdown-item @click="edit_company(row.item)">
                        <font-awesome-icon icon="cog" /> {{$t('labels.editcompany')}}
                    </b-dropdown-item>
                    <b-dropdown-item v-if="settings.boekhoudprogramma=='boekhoudgemak'" @click="send_to_boekhoudgemak(row.item)">
                      <font-awesome-icon icon="save" /> {{ $t('labels.sendtoboekhoudgemak') }}
                    </b-dropdown-item>            
                    <b-dropdown-item v-if="settings.boekhoudprogramma=='snelstart'" @click="send_to_snelstart(row.item)">
                    <font-awesome-icon icon="save" /> {{ $t('labels.sendtosnelstart') }}
                    </b-dropdown-item>            
                    
                </b-dropdown>
            </template>
        </b-table>
        <b-pagination v-if="totalRows > perPage" v-model="companysettings.currentPage" :total-rows="totalRows" :per-page="perPage">
        </b-pagination>
    </b-container>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import popup_modal from './modals/PopupModal.vue'

export default {
    name: 'companiesscreen',
    data() {
        return {
            // currentPage: 1,
            perPage: 10,
            sortBy: 'company_name',
            sortDesc: false,
            sortDirection: 'asc',
            // filter: null,
            filterOn: [],
            // currentbranche: -1,
            // owner: -1,
            // selectedtag: null,
            selectedID: null,
            showblacklist: 0,


        }
    },
    mounted() {
        this.companysettings.showblacklist = this.showBlacklist
        this.$refs["searchbox"].focus()

    },
    watch: {
        showblacklist: function () {
            this.$store.commit('showBlacklist', this.companysettings.showblacklist);
        }
    },
    computed: {
        branche_companies() {
            // let branche_companies = []
            if (this.companysettings.currentbranche == -1) {
                return this.checkTags(this.companies)
            }

            return this.checkTags(this.companies.filter(company => { return company.branche_id == this.companysettings.currentbranche}))
        },
        totalRows() {
            return this.branche_companies.length
        },
        fields() {
            return [
                { key: 'photo', label: this.$i18n.t('labels.logo'), },
                { key: 'company_name', label: this.$i18n.t('labels.companyname'), sortable: true, sortDirection: 'desc' },
                { key: 'branche_id', label: this.$i18n.t('labels.workfields'), sortable: true, sortDirection: 'desc' },
                { key: 'pulldown', label: this.$i18n.t('labels.actions') },
            ]
        },
        ...mapState([
            'companies',
            'branches',
            'tags',
            'current_user',
            'workfields',
            'showBlacklist',
            'companysettings'

        ]),
        ...mapGetters([
            'user_by_id',
            'branche_by_id',
            'username_by_id',
            'company_by_id',
            'activebranches',
            'confirm_modal',
            'owners',
            'find_last_planning_company',
            'createAny'

        ]),
        translate(path) {
            return this.$i18n.t(path.toLowerCase())
        }
    },
    methods: {
        resetSettings(){
          this.$store.commit('companysettings',{            
            filter:null,
            currentbranche:-1,
            currentrole:-1,
            owner:-1, 
            selectedtag:null,
            currentPage:1,
            showblacklist:0           
           },
          )  
        },
        
        ...mapActions([
            'delete_company',
            'save_company'
        ]),
        confirm_delete_company(id) {
            this.selectedID = id
            let message = this.$i18n.t('labels.confirmdeletehead') + this.company_by_id(id).company_name + this.$i18n.t('labels.confirmdeletetail')
            this.$modal.hide('popup_modal')
            this.$modal.show(popup_modal, {
                message: message,
            }, {
                name: 'popup_modal',
                draggable: false,
                height: 'auto',
                width: '500px'
            }, {
                'before-close': this.modalClose
            })

            this.$emit('close')

        },
        modalClose() {
            if (this.confirm_modal.id == 1) {
                this.delete_company(this.selectedID);
                for (let i = 0; i < this.companies.length; i++) {
                    if (this.companies[i].id == this.selectedID) {
                        this.companies.splice(i, 1);
                    }
                }
            }
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.companysettings.currentPage = 1
        },
        onCompanyClicked(row) {
            this.$router.push({ path: '/company/' + row.id });
        },
        fullName(value) {
            return this.username_by_id(value)
        },
        edit_company(company) {
            if (company.id == 0) {
                this.$store.commit('new_company', { id: 0, owner_id: this.current_user.id, country: "NL" })

            }

            this.editCompanyModal(company)
        },
        send_to_boekhoudgemak(item) {
            this.update_debiteur(item.id)

        },
        send_to_snelstart(item){
            this.update_debiteur_snelstart(item.id)
        }, 

        contactpersons(value){
            var ret = ""
            this.company_by_id(value.id).contactpersons.forEach(c=>{
              ret+= '<a href="/#/user/' + c + '" :style="{ cursor: \'pointer\'}" class="link-item"> ' + this.username_by_id(c) + '</a><br/>'
            })
            return ret
        },
        brancheName(value) {
            var company = this.company_by_id(value.id)
            let ret = ""
            const branch = this.branche_by_id(value.branche_id);
            if (branch) {
                ret += "<b>" + branch.name + "</b><br>"
                for (var i = 0; i < this.workfields.length; i++) {
                    if (this.workfields[i].branche_id == company.branche_id) {
                        if (company.workfields.indexOf(this.workfields[i]["id"]) != -1) {
                            ret += this.workfields[i]["name"] + "<br>"
                        }
                    }
                }


            }
            return ret


        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            //difference in days
            let timediff = (this.find_last_planning_company(item.id) - new Date()) / (1000 * 60 * 60 * 24)
            if (item.blacklist && item.blacklist == 1) {
                return 'status-blacklisted'
            } else {
                if (timediff > 0 || timediff > -7) { return 'status-active' }
                if (timediff < -7 && timediff > -90) { return 'status-inactive' }
                if (timediff < -90 && timediff > -356) { return 'status-inactive2' }
                if (timediff < -356 && timediff > -712) { return 'status-inactive3' }
                if (timediff < -712 && timediff > -7120) { return 'status-inactive4' }
                if (timediff < -7120) { return 'status-inactive5' }
            }

            //  else {
            //         return 'status-active' 
            //  }
        },
        checkTags(items) {
            if (this.companysettings.selectedtag == null) {
                return this.checkowner(items)
            }
            // let checkedTags = []
            // for (let i = 0; i < items.length; i++) {
            //     let add = false;
            //     for (let t = 0; t < items[i].tags.length; t++) {
            //         if (items[i].tags[t].tag_id == this.selectedtag.id) {
            //             add = true
            //             break
            //         }
            //     }
            //     if (add) {
            //         checkedTags.push(items[i])
            //     }

            // }
            return this.checkowner(items.filter(item => 
                 item.tags.some(tag => tag.tag_id == this.companysettings.selectedtag.id)
                ));
        },
        checkowner(items) {
            if (this.companysettings.owner == -1) {
                return this.checkbrancheactive(items)
            }
            return this.checkbrancheactive(items.filter(item => {return item.owner_id==this.companysettings.owner}))
        },
        checkbrancheactive(items) {
            return this.checkBlacklist(items.filter(item => {return item.branche_id && this.branche_by_id(item.branche_id).active == 1}))
        },
        checkBlacklist(items) {
            if (this.companysettings.showblacklist == 1) {
                return items
            } 
            return items.filter(item =>  !(this.companysettings.showblacklist == 0 && item.blacklist == 1))

        }

    },
}
</script>
