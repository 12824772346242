

<template>
  <b-modal id="BSReviewUserID" centered  @show="showMe" @hide="hideMe">
    <template #modal-header>
      <h2 class="modal-title">{{ carouselUser.firstname }} {{ $t("buttons.reviewuser") }} </h2>
    </template>
    <div class="container">
      <div class="row">
        <div class="col">
          <strong>Category 1</strong>
        </div>
        <div class="col">
          <star-rating v-bind:increment="0.5" v-bind:star-size="30" v-model="category1"></star-rating>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <strong>Category 2</strong>
        </div>
        <div class="col">
          <star-rating v-bind:increment="0.5" v-bind:star-size="30" v-model="category2"></star-rating>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <strong>Category 3</strong>
        </div>
        <div class="col">
          <star-rating v-bind:increment="0.5" v-bind:star-size="30" v-model="category3"></star-rating>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <strong>Category 4</strong>
        </div>
        <div class="col">
          <star-rating v-bind:increment="0.5" v-bind:star-size="30" v-model="category4"></star-rating>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <strong>Category 5</strong>
        </div>
        <div class="col">
          <star-rating v-bind:increment="0.5" v-bind:star-size="30" v-model="category5"></star-rating>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <strong>Opmerking</strong>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <b-form-textarea size="sm" rows="8" v-model="remarks" />
        </div>
      </div>      
      <div class="row">
        <div class="col-3">
          <strong>Je naam</strong>
        </div>
        <div class="col">
          <b-form-input v-model="review_name"/>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <strong>Je email</strong>
        </div>
        <div class="col">
          <b-form-input v-model="review_email"/>
        </div>
      </div>
      <span>Iedere beoordeling moet via email bevestigd worden. Je kunt een persoon maar 1 keer beoordelen.</span>
    </div>

  <template #modal-footer>
    <button class="btn btn-primary" @click="close_modal">{{ $t( "buttons.cancel") }}</button>
    <button class="btn btn-primary" @click="close_and_save_modal">{{ $t( "buttons.saveandclose") }}</button>
  </template>

  </b-modal>
</template>


<script>
import axios from 'axios';
import { mapGetters,mapActions,  mapState } from 'vuex'
import StarRating from 'vue-star-rating'


export default {
  name: 'timer-modal',
  data() {
    return {
      category1:null,
      category2:null,
      category3:null,
      category4:null,
      category5:null,
      remarks:"",
      review_email:"",
      review_name:""

    }
  },

  props:{

  },
  mounted(){
  },
  computed: {
    ...mapGetters([
    'user_photo_by_id',
    'user_by_id',
    'zzpers',
    'branche_by_id',
    'carouselUser',
    'carouselToken'

    ]),
    ...mapState([
      'users',
      'workfields'
    ]),

  },
  methods: {
    ...mapActions([ 
    ]),

    async showMe(){

    },
    hideMe(){
      this.close_modal();
    },

    close_modal() {
      this.$bvModal.hide('BSReviewUserID')
    }, 
    async close_and_save_modal() {
      await this.getCarouselToken()
      let review ={}
      review.category1 = this.category1
      review.category2 = this.category2
      review.category3 = this.category3
      review.category4 = this.category4
      review.category5 = this.category5
      review.remarks = this.remarks
      review.review_name = this.review_name
      review.review_email = this.review_email
      review.people_id = this.carouselUser.id;
      axios.post(this.carouselURL+'api/reviewuser', review,  {
              headers: { Authorization: `Bearer ${this.carouselToken}` }
            }
            ).then(console.log).catch(console.log);
      this.$store.dispatch('get_carousel_people')
            
      this.$bvModal.hide('BSReviewUserID')
    },
  },
  components:{StarRating}

}    
</script>
