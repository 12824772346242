
<template>
  <b-container fluid>
    <b-table sort-icon-left :items="offer" :fields="fields" @row-clicked="get_reviews_for">
      <template #cell(workfield)="row">
        <div v-html= "row.item.workfield"></div>
      </template>
      <template #cell(reviewscore)="row" >
          <star-rating :rating="row.item.reviewscore" :read-only="true" :increment="0.01" v-bind:star-size="20"  ></star-rating>
        </template>
    </b-table>
    <BSShowReviews :id="selectedID"/>
  </b-container>
</template>


<script>
// import axios from 'axios';
import { mapGetters, mapState } from 'vuex'
// import send_whatsapp_modal from './modals/SendWhatsappModal'
// import axios from 'axios'
import StarRating from 'vue-star-rating'
import BSShowReviews from '../modals/BSShowReviews.vue';

export default {
  name: 'carouseloffer',
  data() {
    return {
      // carouselPeople:null
      selectedID:Number
    }
},

computed: {
  ...mapState([
    'users',
    // 'branches',
    // 'avatars',
    'planning',
    'workfields',
    'tags',
    'current_user',
    'showBlacklist',
    'companies',
    'projects',
    'usersettings',
    'carouselURL',
    'carouselID',
    'carouselPeople',
    'carouselToken'
  ]),
  ...mapGetters([
    'branche_by_id',
    'user_by_id',
    'user_photo_by_id',
    'activebranches',
    'username_by_id',
    'confirm_modal',
    'owners',
    'find_last_planning_user'
  ]),
  offer(){
    return this.carouselPeople.filter(u =>u.owner!= this.carouselID)
  },
  fields(){
    return [
    { key: 'firstname' },
          { key: 'city', label: this.$i18n.t('labels.city'), sortable: false, sortDirection: 'desc' },
          { key: 'startdate', label: this.$i18n.t('labels.startdate'), sortable: false, sortDirection: 'desc' },
          { key: 'enddate', label: this.$i18n.t('labels.enddate'), sortable: false, sortDirection: 'desc' },
          { key: 'replacement', label: this.$i18n.t('buttons.replace'), sortable: false, sortDirection: 'desc' },
          { key: 'workfield', label: this.$i18n.t('labels.workfields'), sortable: false, sortDirection: 'desc' },
          { key:'reviewscore'},
      ]
    }
  },
  methods:{
    async get_reviews_for(user){
      this.selectedID = user.id
      this.$bvModal.show('BSShowReviewsID')         
    }
  },
  components:{StarRating, BSShowReviews}

}

</script>
