<template>
  <b-modal id="BSShowReviewsID" centered  @show="showMe" @hide="hideMe">
    <template #modal-header>
      <h2 class="modal-title">Reviews</h2>
    </template>
    <div class="container">
      <div v-for="(review,idx) in reviews" :key="idx" >
        <div class="row">
          <div class="col">
            <strong>Category 1</strong>
          </div>
          <div class="col">
            <star-rating :rating="review.category1" :read-only="true" :increment="0.01" v-bind:star-size="20"  ></star-rating>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <strong>Category 2</strong>
          </div>
          <div class="col">
            <star-rating :rating="review.category2" :read-only="true" :increment="0.01" v-bind:star-size="20"  ></star-rating>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <strong>Category 3</strong>
          </div>
          <div class="col">
            <star-rating :rating="review.category3" :read-only="true" :increment="0.01" v-bind:star-size="20"  ></star-rating>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <strong>Category 4</strong>
          </div>
          <div class="col">
            <star-rating :rating="review.category4" :read-only="true" :increment="0.01" v-bind:star-size="20"  ></star-rating>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <strong>Category 5</strong>
          </div>
          <div class="col">
            <star-rating :rating="review.category5" :read-only="true" :increment="0.01" v-bind:star-size="20"  ></star-rating>
          </div>
        </div>
        <div class="row">
          <div class="col">
          {{ review.remarks }}
          </div>
        </div>
        <div class="row">
          <div class="col">
            <strong>Reviewed by</strong>
          </div>
          <div class="col">
            {{ review.review_name }}
          </div>
        </div>        
      </div>
    </div>
  <template #modal-footer>
    <button class="btn btn-primary" @click="close_modal">{{ $t( "buttons.close") }}</button>
  </template>

  </b-modal>
</template>


<script>
import axios from 'axios';
import { mapGetters,mapActions,  mapState } from 'vuex'
import StarRating from 'vue-star-rating'

export default {
  name: 'showreview-modal',
  data() {
    return {
      reviews:""

    }
  },

  props:{
    id:Number
  },
  mounted(){
  },
  computed: {
    ...mapGetters([
    'user_photo_by_id',
    'user_by_id',
    'zzpers',
    'branche_by_id',
    'carouselUser',
    'carouselToken'

    ]),
    ...mapState([
      'users',
      'workfields'
    ]),

  },
  methods: {
    ...mapActions([ 
    ]),

    async showMe(){
      var vm = this
      await this.getCarouselToken()
      await axios.get(this.carouselURL+'api/get_reviews_for/'+ this.id,   {
              headers: { Authorization: `Bearer ${this.carouselToken}` }
            }
            ).then(function (response) {
            vm.reviews =  response.data
          })
    },
    hideMe(){
      this.close_modal();
    },

    close_modal() {
      this.$bvModal.hide('BSShowReviewsID')
    },
  },
components:{StarRating}
}    
</script>
