<template>

    <form class="form">
        <input type="hidden" :key="componentKey" />
        <div class="form-row">
            <div class="col-2">
                <label for=""> {{ $t("labels.language") }}</label>
            </div>
            <div class="col-8">
                <b-form-select v-model="user.language" @change="setLanguage()">
                    <option value="en">English</option>
                    <option value="nl">Nederlands</option>
                </b-form-select>
            </div>
        </div>
        <div class="form-row">
            <div class="col-2">
                <label for=""> {{ $t("meta.waardering") }}</label>
            </div>
            <div class="col-8">
                <select v-if="createAny('users')" name="userrating" class="form-control"
                    v-model="user.rating">
                    <option val='0'>{{ $t('labels.no') }}</option>
                    <option val='1'>*</option>
                    <option val='2'>**</option>
                    <option val='3'>***</option>
                    <option val='4'>****</option>
                    <option val='5'>*****</option>
                </select>
            </div>
        </div>       
        <div class="form-row">
            <div class="col-2">
                <label for=""> {{ $t("meta.speaks") }}</label>
            </div>
            <div class="col-8">
                <b-form-input type="text" class="form-control" v-model="user.speaks"  />

            </div>
        </div>              
        <div class="form-row" v-if="user.role == 'user' && createAny('users')">
            <div class="col-2">
                <label for=""> {{ $t("labels.Vscore") }}</label>
            </div>
            <div class="col-2">
                <b-form-input type="number" class="form-control" v-model="user.V" :formatter="scores" />
            </div>
            <div class="col-8">
                <b-input-group>
                    <b-form-input type="text" class="form-control" v-model="user.Vremarks" :ref="Vremarks" />
                    <b-input-group-append v-if="createAny('users')">
                        <b-button squared class="multi-append"  href="javascript:;"
                            @click="append_data('Vremarks')">
                            <font-awesome-icon icon="plus-circle" />
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </div>
        </div>
        <div class="form-row" v-if="user.role == 'user' && createAny('users')">
            <div class="col-2">
                <label for=""> {{ $t("labels.Ascore") }}</label>
            </div>
            <div class="col-2">
                <b-form-input type="number" class="form-control" v-model.number="user.A" step="any"
                    :formatter="scores" />
            </div>
            <div class="col-8">
                <b-input-group>
                    <b-form-input type="text" class="form-control" v-model="user.Aremarks" :ref="Aremarks" />
                    <b-input-group-append v-if="createAny('users')">
                        <b-button squared class="multi-append"  href="javascript:;"
                            @click="append_data('Aremarks')">
                            <font-awesome-icon icon="plus-circle" />
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </div>
        </div>
        <div class="form-row" v-if="user.role == 'user' && createAny('users')">
            <div class="col-2">
                <label for=""> {{ $t("labels.Kscore") }}</label>
            </div>
            <div class="col-2">
                <b-form-input type="number" class="form-control" v-model="user.K" :formatter="scores" />
            </div>
            <div class="col-8">
                <b-input-group>
                    <b-form-input type="text" class="form-control" v-model="user.Kremarks" :ref="Kremarks" />
                    <b-input-group-append v-if="createAny('users')">
                        <b-button squared class="multi-append"  href="javascript:;"
                            @click="append_data('Kremarks')">
                            <font-awesome-icon icon="plus-circle" />
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </div>
        </div>
        <div class="form-row" v-if="user.role == 'user' && createAny('users')">
            <div class="col-2">
                <label for=""> {{ $t("labels.ZZPinvoicenumber") }}</label>
            </div>
            <div class="col-2">
                {{invoicenumberformat}}
            </div>
            <div class="col-2">
                <b-form-input type="number" class="form-control" v-model="user.invoice_count" />
            </div>

            <div class="col-2">
                <label for=""> {{ $t("labels.ZZPdebnum") }}</label>
            </div>
            <div class="col-2">
                <b-form-input type="text" class="form-control" v-model="user.debnum" />
            </div>
            <div class="btn btn-primary" v-if="(user.debnum==undefined ||user.debnum=='') && settings.boekhoudprogramma=='snelstart'" @click="findsnelstart()">Koppel Snelstart</div>
        </div>
        <div v-if="createAny('users') && settings.EnableBlacklist" class="form-row">
            <div class="col-2"></div>
            <div class="col-3">
                <b-form-checkbox v-model="user.blacklist" name="checkbox-1" value="1" unchecked-value="0">{{
                        $t("labels.blacklist")
                }}</b-form-checkbox>
            </div>
        </div>
        <!-- not used for now -->
        <div class="form-row" v-if="false">
            <div class="col-2">
                <label for=""> {{ $t("labels.call") }}</label>
            </div>
            <div class="col-3">
                <b-form-input type="date" class="form-control" v-model="user.callat" />
            </div>
            <div class="col-7">
                <b-input-group>
                    <b-form-textarea type="text" class="form-control" v-model="user.callbacknote" :ref="callbacknote" />
                    <b-input-group-append v-if="createAny('users')">
                        <b-button squared class="multi-append"  href="javascript:;"
                            @click="append_data_multi('callbacknote')">
                            <font-awesome-icon icon="plus-circle" />
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </div>
        </div>
        <div class="form-row" v-if="user.role == 'contactpersoon' || user.role === 'opdrachtgever'">
            <div class="col-2">
                <label for=""> {{ $t("labels.responsibilities") }}</label>
            </div>
            <div class="col-5">
                <b-form-checkbox v-model="user.company_contact" name="checkbox-1" value="1" unchecked-value="0">{{
                        $t("labels.company_contact")
                }}</b-form-checkbox>
            </div>
            <div class="col-5">
                <b-form-checkbox v-model="user.invoice_contant" name="checkbox-1" value="1" unchecked-value="0">{{
                        $t("labels.invoice_contant")
                }}</b-form-checkbox>
            </div>
        </div>
        <div class="form-row" v-if="user.role == 'contactpersoon' || user.role === 'opdrachtgever'">
            <div class="col-2"></div>
            <div class="col-5">
                <b-form-checkbox v-model="user.document_contact" name="checkbox-1" value="1" unchecked-value="0">{{
                        $t("labels.document_contact")
                }}</b-form-checkbox>
            </div>
            <div class="col-5">
                <b-form-checkbox v-model="user.location_contact" name="checkbox-1" value="1" unchecked-value="0">{{
                        $t("labels.location_contact")
                }}</b-form-checkbox>
            </div>

        </div>
        <div class="form-row" v-if="user.role != 'user' || user.role === 'opdrachtgever'">
            <div class="col-2"></div>
            <div class="col-5">
                <b-form-checkbox v-model="user.sign_contact" name="checkbox-1" value="1" unchecked-value="0">{{
                        $t("labels.sign_contact")
                }}</b-form-checkbox>
            </div>            
        </div>
        <div class="form-row">
            <div class="col-2">
                <label for=""> {{ $t("labels.notificationoptions") }}</label>
            </div>
            <div class="col-5">
                <b-form-checkbox v-model="user.notifications" name="checkbox-1" value="1" unchecked-value="0">{{
                        $t("labels.notifications")
                }}</b-form-checkbox>
            </div>
            <div class="col-5">
                <b-form-checkbox v-model="user.newsletter" name="checkbox-1" value="1" unchecked-value="0">{{
                        $t("labels.newsletter")
                }}</b-form-checkbox>
            </div>
        </div>

        <!-- dynamic ordermetas -->
        <div v-for="meta in user_settings_metasCB" v-bind:key="'cb' + meta.key">
            <div class="form-row" v-if="user[meta.toggle] || !meta.toggle">
                <div class="col-2"></div>
                <div class="col-6">
                    <b-form-checkbox v-model="user[meta.key]" name="checkbox-1" value="1" unchecked-value="0">
                        {{ translate(meta.label) }}</b-form-checkbox>
                </div>
            </div>
        </div>
        
        <div class="form-row" v-if="createAny('users')">
            <div class="col-3">
                <label for=""> {{ $t("labels.signupdate") }}</label>
            </div>
            <div class="col-4">
                <flat-pickr v-model="user.signupdate" :config="config" class="form-control"
                    placeholder="Select date" name="signupdate" />
            </div>
        </div>
        <div v-for="meta in user_settings_metas" v-bind:key="'inp' + meta.key">
            <div class="form-row" v-if="user[meta.toggle] || !meta.toggle && createAny('users')">
                <div v-if="meta.key != 'language'" class="col-3">
                    <label v-bind:for="meta.key">{{ translate(meta.label) }}</label>
                </div>
                <div v-if="meta.type != 'select' && meta.key != 'language'" class="col"
                    v-bind:class="{ 'col-4': meta.type == 'date', 'col-2': meta.type == 'number' }">
                    <b-input-group>
                        <b-form-textarea size="sm" rows="3" v-bind:type="meta.type"
                            v-bind:placeholder=translate(meta.label) v-bind:id="'bind-' + meta.key" :ref="meta.key"
                            class="form-control" v-model="user[meta.key]" :state="checklength(user[meta.key])" />
                        <b-input-group-append v-if="createAny('users')">
                            <b-button squared class="multi-append"  href="javascript:;"
                                @click="append_data_multi(meta.key)">
                                <font-awesome-icon icon="plus-circle" />
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </div>
            </div>
        </div>
        <hr>
        <div class="form-row" v-if="user.role == 'user' && createAny('users')">
            <div class="col-3">
                <label for=""> {{ $t("labels.available_hours") }}</label>
            </div>
            <div class="col-4">
                <b-form-input type="number" class="form-control" v-model="user.available_hours" />
            </div>
            <div class="col-5">
                <b-input-group>
                    <b-form-textarea size="sm" rows="3" class="form-control" v-model="user.available_hours_remarks"
                        :ref="available_hours_remarks" />
                    <b-input-group-append v-if="createAny('users')">
                        <b-button squared class="multi-append"  href="javascript:;"
                            @click="append_data('available_hours_remarks')">
                            <font-awesome-icon icon="plus-circle" />
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </div>
        </div>
        <div class="form-row" v-if="user.role == 'user' && createAny('users')">
            <div class="col-3">
                <label for=""> {{ $t("labels.available_from") }}</label>
            </div>
            <div class="col-4">
                <flat-pickr v-model="user.available_from" :config="config" class="form-control"
                    placeholder="Select date" name="startdate" />
            </div>
        </div>
        <BSFindSnelstartModal :user_id="user_id" />

    </form>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
const Dutch = require("flatpickr/dist/l10n/nl.js").default.nl;

import BSFindSnelstartModal from '../BSFindSnelstartModal.vue';

export default {
    name: 'usersettingsscreen',
    data() {
        return {
            componentKey: 0,
            Vremarks: "Vremarks",
            Aremarks: "Aremarks",
            Kremarks: "Kremarks",
            callbacknote: "callbacknote",
            available_hours_remarks: "available_hours_remarks",
            config: {
                weekNumbers: true,
                locale: Dutch,
                altInput: true,
                altFormat: "d-m-Y",
                dateFormat: "Y-m-d",
            }
        }
    },
    props: {
        user_id: Number
    },
    computed: {
        user() {
            return this.user_id > 0 ? this.user_by_id(this.user_id) : this.user_new
        },

        ...mapGetters([
            'user_settings_metas',
            'user_by_id',
            'activebranches',
            'user_settings_metasCB',
            'createAny',
            'append_data_string'

        ]),
        ...mapState([
            'users',
            'settings',
            'current_user',
            'branches',
        ]),
        usernamecheck() {
            if (this.user.id != 0) {
                return true
            }
            let found = false;
            for (var i = 0; i < this.users.length; i++) {
                if (this.user.username == this.users[i].username) {
                    found = true;
                    break;
                }
            }
            return !found && this.user.username != undefined && this.user.username.length > 5;
        },
        passwordcheck() {
            return this.user.password != undefined && this.user.password.length > 5;
        },
        invoicenumberformat() {
            var yearprefix = String(new Date().getYear()).substring(1, 4)
            let firstletter = this.user.firstname.charAt(0)
            let lastletter = this.user.lastname.charAt(0)
            const zeroPad = (num, places) => String(num).padStart(places, '0')

            return "Z" + yearprefix + firstletter + lastletter + zeroPad(this.user.invoice_count, 5)
        }
    },
    methods: {
        checklength(key) {
            if (key) {
                return key.length < 1000
            } else {
                return true
            }
        },
        translate(path) {
            return this.$i18n.t('meta.' + path.toLowerCase())
        },
        append_data(key) {
            this.user[key] = this.append_data_string
            this.componentKey += 1;
            if (this.$refs[key]) {
                if (this.$refs[key][0]) {
                    this.$refs[key][0].focus()
                } else {
                    this.$refs[key].focus()
                }
            }
        },
        findsnelstart() {
            console.log("zoeke dan")
            this.$bvModal.show('BSFindSnelstartID')
        },         
        append_data_multi(key) {
            var current = ""
            if (this.user[key]) {
                current = "\n" + this.user[key]
            }
            this.user[key] = this.append_data_string + current
            this.componentKey += 1;
            if (this.$refs[key]) {
                if (this.$refs[key][0]) {
                    this.$refs[key][0].focus()
                    this.$nextTick(() => {
                        this.$refs[key][0].selectionEnd = 9
                    })
                } else {
                    this.$refs[key].focus()
                    this.$nextTick(() => {
                        this.$refs[key].selectionEnd = 9
                    })
                }
            }
        },
        ...mapActions([
            'save_user',
            'add_user'
        ]),
        setLanguage() {
            //        if(this.user!=0 && this.current_user.id==this.user.id){
            //            this.$i18n.locale = this.user.language
            //        }
        },
        scores(val) {
            return val;
            //  if(val!=undefined){
            //      return Math.round(val * 10) / 10
            //  }
        }

    },
    mounted() {
    },
    components: { flatPickr , BSFindSnelstartModal} 
}
</script>