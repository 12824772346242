<template>
  <b-container v-if="createAny('users') && currentUserIsSuperAdmin()" fluid>

    <!-- TItle bar -->
    <div class="title-bar">
      <h1>{{$t('titles.carousel')}}</h1>
    </div>
    <b-form-group :label="$t('labels.username')">
      <b-form-input type="text" class="form-control" v-model="settings.carouselusername" />
    </b-form-group>
    <b-form-group :label="$t('labels.password')">
      <b-form-input type="password" class="form-control" v-model="settings.carouselpassword" />
    </b-form-group>        

    
        <!-- Save button -->
        <div @click="save_settings" class="btn btn-primary ">
      <font-awesome-icon icon="plus" /> {{ $t("buttons.savesettings") }}
    </div>
    <div @click="test_settings" class="btn btn-primary ">
      <font-awesome-icon icon="question" /> {{ $t("buttons.testconnection") }}
    </div>
    <span>{{ testresult }}</span>
  </b-container>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import axios from 'axios'

export default {
  name: 'carousel-settings-screen',
  data() {
    return {
      testresult:""

    }
  },

  computed: {

    ...mapState([
      'settings',
      'users',
      'contracts',
      'hours'

    ]),
    ...mapGetters([
      'server_url',
      'username_by_id',
      'user_by_id',
      'project_by_id',
      'contract_by_id',
      'company_by_id',
      'factuur_by_id',
      'confirm_modal',
      'planning_by_id',
      'createAny'
    ]),
  },
  methods: {
    ...mapActions([
    ]),
  
    save_settings() {
      try {
        axios.patch('/api/settings/',
          {
            carouselusername: this.settings.carouselusername,
            carouselpassword: this.settings.carouselpassword,
          },
        )
          .then((response) => {
            console.log(response)
          })
      } catch (err) {
        console.log(err)
      }
    },
  async  test_settings(){
    var vm=this
      await axios.post(this.carouselURL+"api/login",{username:this.settings.carouselusername, password:this.settings.carouselpassword})
          .then(function (result) {
            console.log(result)
            vm.testresult = "Connection OK"
          })
          .catch(function (error) {
            console.log(error)
            vm.testresult = "Connection error"
          });
    }
  },

}
</script>