<template>
  <b-modal id="BSCarouselOptionsID" centered  @show="showMe" @hide="hideMe">
    <template #modal-header>
      <h2 class="modal-title">{{ carouselUser.firstname }} {{ $t("buttons.sendtocarousel") }} </h2>
    </template>
    <div class="container">
      <div class="row">
        <div class="col">
          <strong>{{ $t('labels.startdate') }}</strong>
        </div>
        <div class="col">
          <strong>{{ $t('labels.enddate') }}</strong>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <input type="date" v-model="carouselUser.startdate">
        </div>
        <div class="col">
          <input type="date" v-model="carouselUser.enddate">
        </div>
      </div>  
      <div class="row">
        <div class="col">  
          <b-form-checkbox  v-model="carouselUser.replacement">{{ $t("labels.carouselreplace") }}</b-form-checkbox>
        </div>
      </div>

    </div>

  <template #modal-footer>
    <button class="btn btn-primary" @click="close_modal">{{ $t( "buttons.cancel") }}</button>
    <button class="btn btn-primary" @click="close_and_save_modal">{{ $t( "buttons.saveandclose") }}</button>
  </template>

  </b-modal>
</template>


<script>
import axios from 'axios';
import { mapGetters,mapActions,  mapState } from 'vuex'

export default {
  name: 'timer-modal',
  data() {
    return {

    }
  },

  props:{

  },
  mounted(){
  },
  computed: {
    ...mapGetters([
    'user_photo_by_id',
    'user_by_id',
    'zzpers',
    'branche_by_id',
    'carouselUser'

    ]),
    ...mapState([
      'users',
      'workfields'
    ]),

  },
  methods: {
    ...mapActions([ 
    ]),

    showMe(){
    },
    hideMe(){
      this.close_modal();
    },

    close_modal() {
      this.$bvModal.hide('BSCarouselOptionsID')
    },
    brancheName(value) {
      // console.log(value)
      var user = this.user_by_id(value)
      const branch = this.branche_by_id(user.branche_id);
      var ret = ""
      if (branch) {
        ret += "<b>" + branch.name + "</b><br>"
        for (var i = 0; i < this.workfields.length; i++) {
          if (this.workfields[i].branche_id == user.branche_id) {
            if (user.workfields.indexOf(this.workfields[i]["id"]) != -1 && this.workfields[i].active) {
              ret += this.workfields[i]["name"] + "<br>"
            }
          }
        }

      }
      return ret
    },  
    async close_and_save_modal() {
      await this.getCarouselToken()
      axios.post(this.carouselURL+'api/subscribeuser', this.carouselUser,  {
              headers: { Authorization: `Bearer ${this.carouselToken}` }
            }
            ).then(console.log).catch(console.log);
      this.$store.dispatch('get_carousel_people')
            
      this.$bvModal.hide('BSCarouselOptionsID')
    },
  },

}    
</script>
