<template>
	<div class="popup">
        <div class="popup-header">
            <div v-if="user.id > 0">
              {{$t('titles.zzprinprojdetails')}}  {{user.firstname}} {{user.middlename}} {{user.lastname}} {{$t('titles.zzprinprojin')}} {{project.title}}
            </div>
            <div class="popup-close" @click="cancelEdit">
                <font-awesome-icon icon="times" />
            </div>
        </div>
        <div class="popup-body">
          <input type="hidden" :key="componentKey" />

            <b-tabs vertical>
                <b-tab >
                    <template v-slot:title>Default</template>                    
                    <ContractDefault  v-bind:contract_id="contract_id" ref="default"></ContractDefault>
                </b-tab>
                <b-tab active>
                    <template v-slot:title>Details</template>                    

                    <ProjectZZPerPlanning v-bind:project_id="project_id" ref="planning" @change="detailcheck"></ProjectZZPerPlanning>
                </b-tab>
                
            </b-tabs>        

        </div>

        <div class="popup-footer">
            <div v-if="contract.id == 0" class="btn btn-primary" @click="my_add_contract();">{{ addcontractText() }}</div>
            <div v-else @click="save_and_close" class="btn btn-primary">{{ addcontractText()}}</div>
            <!-- <div class="btn btn-primary" @click="notify_contacts()"><font-awesome-icon icon="envelope"/></div> -->
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import ContractDefault from './settings-screens/ContractDefault'
import ProjectZZPerPlanning from './settings-screens/ProjectZZPerPlanning'
import send_contract_modal from './SendContract'


export default {
    name: 'contract_modal',
    data() {
        return {
            contract_id:null,
            componentKey:0
        }
    },
    props: {
        project_id: Number,
        user_id: Number
    },
    
    mounted() {
      this.local_contract()
        this.$nextTick(function () {
            this.componentKey++
        })	      
    },
    computed: {

        user() {
            return this.user_id > 0 ? this.user_by_id(this.user_id) : this.new_user
        },
        project(){
            return this.project_id > 0 ? this.project_by_id(this.project_id) : this.new_user
        },

        contract() {
            return this.contract_id > 0 ? this.contract_by_id(this.contract_id) : this.contract_new
        },                
        ...mapGetters([
            'user_by_id',
            'project_by_id',
            'contract_by_id',
            'contracts_metas',
            'contract_new',
            'server_url'
        ]),
        ...mapState([
            'users',
            'settings',
            'contracts',
            'current_user',
        ])
    },
    methods: {
        addcontractText(){
            if (this.$refs.planning && this.$refs.planning.validated()==true){
                if(this.contract.id == 0){
                    return this.$i18n.t("buttons.add") 
                } else {
                    return this.$i18n.t("buttons.saveandclose")
                }

            } else { 
                return this.$i18n.t("buttons.invalid") 
            }
        },     
        my_add_contract(){
            if(this.$refs.planning.validated()==true){
                this.add_contract(this.contract)
                this.$emit('close')
            }            
        },    
        detailcheck(){
            this.componentKey++
        },
        local_contract() {
          let newcontract = { id: 0 , project_id:this.project_id, user_id:this.user_id,signed_company:1, signed_zzpr:1 ,planning_id:this.$refs.planning.found_planning_id}
          this.$store.commit('new_contract',newcontract)
          //find contract_id
        //    let contractid=0;
        //   for(var i=0;i<this.contracts.length;i++){
        //     if(this.contracts[i].user_id==this.user_id && this.contracts[i].project_id==this.project_id){
        //       contractid=this.contracts[i].id
        //     }
        //   }
          let found = this.contracts.filter(c=> c.user_id==this.user_id && c.project_id==this.project_id)
          this.contract_id = found.length==0?0:found[0].id
          return newcontract
        },      
        translate(path){
            return this.$i18n.t('meta.'+path.toLowerCase())
        },        
        async save_and_close() {
            await this.$refs.default.set_planning_id(this.$refs.planning.found_planning_id)
            await this.$refs.default.save_data();
            await this.$refs.planning.save_data();
            this.$emit('close')
        },
        cancelEdit(){
            console.log("CanCel")
            this.$store.dispatch('get_planning')
            this.$store.dispatch('get_contracts')

            this.$emit('close')
        },
        ...mapActions([
            'save_contract',
            'add_contract',
        ]),

        notify_contacts(){
            this.$modal.hide('send_contract_modal')
             this.$modal.show(send_contract_modal, {
              contract_id: this.contract_id
             }, {
              name: 'send_contract_modal',
              draggable: '.popup-header',
              height: 'auto',
              width: '800px'
            })
            this.$emit('close')                  

        }

    },
    components:{ContractDefault,ProjectZZPerPlanning}

}
</script>