  <template>
  <b-container>
    <h3>{{ this.titletext }}</h3>
    <div class="form-row" v-if="hasHours"> <div style="color:red; text-transform: uppercase; font-weight: bold;"><font-awesome-icon icon="exclamation" style="color: red;" />{{$t('validation.contractedithashours')}} <font-awesome-icon icon="exclamation" style="color: red;" /></div></div>
    <div class="form-row">
      <div class="col-4">
        {{ $t("labels.zzpcompanyname")}}
      </div>
      <div class="col-1">
        <font-awesome-icon v-if="this.user.companyname!=undefined" icon="check" style="color: green;" />
        <font-awesome-icon v-else icon="times" style="color: red;" />
      </div>
      <div class="col-6">
        <strong> {{this.user.companyname }} titletexttitletext</strong>
      </div>
    </div>
    <div class="form-row">
      <div class="col-4">
        {{ $t("labels.kvk")}}
        </div>
      <div class="col-1">
        <font-awesome-icon
          v-if="this.checkdoc('KvK') && this.checkdate(this.user.kvk_enddate) && this.user.kvkcode!=undefined && this.user.kvkcode!='' "
          icon="check" style="color: green;" />
        <font-awesome-icon v-else icon="times" style="color: red;" />
      </div>
      <div class="col-6">
        {{this.user.kvkcode }} {{this.formatDate(this.user.kvk_enddate)}}
      </div>
    </div>
    <div v-if = "settings.ShowBSN" class="form-row">
      <div class="col-4">
        {{ $t("labels.bsn")}}
      </div>
      <div class="col-1">
        <font-awesome-icon v-if="this.user.bsn!=undefined" icon="check" style="color: green;" />
        <font-awesome-icon v-else icon="times" style="color: red;" />
      </div>
      <div class="col-6">
        {{this.user.bsn }}
      </div>
    </div>
    <div class="form-row">
      <div class="col-4">
        {{ $t("labels.vat")}}
      </div>
      <div class="col-1">
        <font-awesome-icon v-if="this.user.vatcode!=undefined&&this.user.vatcode!=''" icon="check"
          style="color: green;" />
        <font-awesome-icon v-else icon="times" style="color: red;" />
      </div>
      <div class="col-6">
        {{this.user.vatcode }}
      </div>
    </div>

    <div class="form-row">
      <div class="col-4">
        {{ $t("labels.identification")}}
      </div>
      <div class="col-1">
        <font-awesome-icon
          v-if="this.checkdate(this.user.identification_endate) && this.user.identificationcode!=undefined&& this.user.identificationcode!=''"
          icon="check" style="color: green;" />
        <font-awesome-icon v-else icon="times" style="color: red;" />
      </div>
      <div class="col-6">
        {{this.user.identificationcode }} {{this.formatDate(this.user.identification_endate)}}
      </div>
    </div>
    <div class="form-row">
      <div class="col-4">
        {{ $t("labels.iban")}} / {{ $t("labels.BIC")}}
      </div>
      <div class="col-1">
        <font-awesome-icon v-if="this.user.iban!=undefined &&this.user.bic!=undefined" icon="check"
          style="color: green;" />
        <font-awesome-icon v-else icon="times" style="color: red;" />
      </div>
      <div class="col-6">
        {{this.user.iban }} / {{this.user.bic }}
      </div>
    </div>

    <b-row>
      <b-col>
        <hr>
      </b-col>
    </b-row>
    <div class="form-row">
      <div class="col-4">
        {{ $t("labels.bycompany")}}
      </div>
      <div class="col-1">
        <font-awesome-icon v-if="this.company.company_name!=undefined" icon="check" style="color: green;" />
        <font-awesome-icon v-else icon="times" style="color: red;" />
      </div>
      <div class="col-6">
        <strong> {{this.company.company_name }} </strong>
      </div>
    </div>
    <div class="form-row">
      <div class="col-4">
        {{ $t("labels.kvk")}}
      </div>
      <div class="col-1">
        <font-awesome-icon v-if="this.company.kvkcode!=undefined && this.company.kvkcode!='' " icon="check"
          style="color: green;" />
        <font-awesome-icon v-else icon="times" style="color: red;" />
      </div>
      <div class="col-6">
        {{this.company.kvkcode }}
      </div>
    </div>
    <div class="form-row">
      <div class="col-4">
        {{ $t("labels.vat")}}
      </div>  
      <div class="col-1">
        <font-awesome-icon v-if="this.company.vatnumber!=undefined && this.company.vatnumber!='' " icon="check"
          style="color: green;" />
        <font-awesome-icon v-else icon="times" style="color: red;" />
      </div>
      <div class="col-6">
        {{this.company.vatnumber }}
      </div>
    </div>
    <div class="form-row">
      <div class="col-4">
      <strong>{{ $t("meta.projectnumber")}}</strong>
      </div>  
      <div class="col-1">      </div>
      <div class="col-6">
        {{this.project.id }}
      </div>
    </div>    
    <b-row>
      <b-col>
        <hr>
      </b-col>
    </b-row>
    <div class="form-row">
      <div class="col-4">
        {{ $t("labels.statuscolor") }}
      </div>
      <div class="col-1">

        <font-awesome-icon v-if="contract_color!=null" icon="check" color="green" />
        <font-awesome-icon v-else icon="times" color="red" />
      </div>
        <div class="col-6" :style="myStyle">
        <b-form-radio-group id="contraxct_color" v-model="contract_color" name="radio-sub-component"
          :options="status_colors">
        </b-form-radio-group>
        </div>
    </div>
    <div class="form-row">
      <div class="col-4">
        {{ $t("option.contactperson")}}
      </div>
      <div class="col-1">
        <font-awesome-icon v-if="contact_id!=null" icon="check" color="green" />
        <font-awesome-icon v-else icon="times" color="red" /> 
      </div>
      <div class="col-4">
        <select name="contactpersoon" class="form-control contactpersoon" v-model="contact_id">
          <option v-for="(user,idx) in company_contact_persons" v-bind:value="user.id" :key="'contact_id'+idx">
            {{ user["firstname"]}} {{ user["middlename"] }} {{ user["lastname"] }}
          </option>
        </select>
      </div>
      <div class="col-2">
        <font-awesome-icon class="table-contacts-roles" 
          v-if="this.user_by_id(contact_id)&&this.user_by_id(contact_id).company_contact=='1'" icon="address-book"
          :title="$t('labels.company_contact')" />
        <font-awesome-icon class="table-contacts-roles" 
          v-if="this.user_by_id(contact_id)&&this.user_by_id(contact_id).document_contact=='1'" icon="file"
          :title="$t('labels.document_contact')" />
        <font-awesome-icon class="table-contacts-roles" 
          v-if="this.user_by_id(contact_id)&&this.user_by_id(contact_id).invoice_contant=='1'" icon="euro-sign"
          :title="$t('labels.invoice_contant')" />
        <font-awesome-icon class="table-contacts-roles" 
          v-if="this.user_by_id(contact_id)&&this.user_by_id(contact_id).sign_contact=='1'" icon="pencil-alt"
          :title="$t('labels.sign_contact')" />
        <font-awesome-icon class="table-contacts-roles" 
          v-if="this.user_by_id(contact_id)&&this.user_by_id(contact_id).location_contact=='1'" icon="street-view"
          :title="$t('labels.location_contact')" />
      </div>
    </div>
    <div class="form-row">
      <div class="col-4">
         {{ $t("labels.pointofcontact")}}
      </div>
      <div class="col-1">
        <font-awesome-icon v-if="pointcontact_id!=null" icon="check" color="green" />
        <font-awesome-icon v-else icon="times" color="red" />        
      </div>
      <div class="col-4">
        <select name="contactpersoon" class="form-control contactpersoon" v-model="pointcontact_id">
          <option v-for="(user,idx) in company_contact_persons" v-bind:value="user.id" :key="'pointcontact_id'+idx">
            {{ user["firstname"]}} {{ user["middlename"] }} {{ user["lastname"] }}
          </option>
        </select>
      </div>
      <div class="col-2">
        <font-awesome-icon class="table-contacts-roles" 
          v-if="this.user_by_id(pointcontact_id)&&this.user_by_id(pointcontact_id).company_contact=='1'"
          icon="address-book" :title="$t('labels.company_contact')" />
        <font-awesome-icon class="table-contacts-roles" 
          v-if="this.user_by_id(pointcontact_id)&&this.user_by_id(pointcontact_id).document_contact=='1'" icon="file"
          :title="$t('labels.document_contact')" />
        <font-awesome-icon class="table-contacts-roles" 
          v-if="this.user_by_id(pointcontact_id)&&this.user_by_id(pointcontact_id).invoice_contant=='1'"
          icon="euro-sign" :title="$t('labels.invoice_contant')" />
        <font-awesome-icon class="table-contacts-roles" 
          v-if="this.user_by_id(pointcontact_id)&&this.user_by_id(pointcontact_id).sign_contact=='1'" icon="pencil-alt"
          :title="$t('labels.sign_contact')" />
        <font-awesome-icon class="table-contacts-roles" 
          v-if="this.user_by_id(pointcontact_id)&&this.user_by_id(pointcontact_id).location_contact=='1'"
          icon="street-view" :title="$t('labels.location_contact')" />
      </div>
    </div>
    <div class="form-row" v-if="hourregistration=='app'">
      <div class="col-4">
        {{ $t("labels.authorizedsignatory")}}
      </div>
      <div class="col-1">
        <font-awesome-icon v-if="sign_id!=null" icon="check" color="green" />
        <font-awesome-icon v-else icon="times" color="red" />   
        <font-awesome-icon v-if= "signerhasemailnotification(sign_id)" icon="question" color="red" :title="$t('validation.signeremaildisabled')" />   
              
      </div>
      <div class="col-4">
        <select name="contactpersoon" class="form-control contactpersoon" v-model="sign_id">
          <option v-for="(user,idx) in company_signers" v-bind:value="user.id" :key="'sign_id'+idx">
            {{ user["firstname"]}} {{ user["middlename"] }} {{ user["lastname"] }}
          </option>
        </select>
      </div>
      <div class="col-2">
        <font-awesome-icon class="table-contacts-roles" 
          v-if="this.user_by_id(sign_id)&&this.user_by_id(sign_id).company_contact=='1'" icon="address-book"
          :title="$t('labels.company_contact')" />
        <font-awesome-icon class="table-contacts-roles" 
          v-if="this.user_by_id(sign_id)&&this.user_by_id(sign_id).document_contact=='1'" icon="file"
          :title="$t('labels.document_contact')" />
        <font-awesome-icon class="table-contacts-roles" 
          v-if="this.user_by_id(sign_id)&&this.user_by_id(sign_id).invoice_contant=='1'" icon="euro-sign"
          :title="$t('labels.invoice_contant')" />
        <font-awesome-icon class="table-contacts-roles" 
          v-if="this.user_by_id(sign_id)&&this.user_by_id(sign_id).sign_contact=='1'" icon="pencil-alt"
          :title="$t('labels.sign_contact')" />
        <font-awesome-icon class="table-contacts-roles" 
          v-if="this.user_by_id(sign_id)&&this.user_by_id(sign_id).location_contact=='1'" icon="street-view"
          :title="$t('labels.location_contact')" />

      </div>
    </div>
    <b-row>
      <b-col>
        <hr>
      </b-col>
    </b-row>
    <div class="form-row" v-if="current_user.id == '1'">
      <div class="col-2">
        {{ $t("labels.hourregistration") }}
      </div>
      <div class="col-3">
        <b-form-group>
          <b-form-radio :state="hourregistration!=undefined" v-model="hourregistration" value="app">{{
          $t("labels.hourregistrationapp") }}</b-form-radio>
          <b-form-radio :state="hourregistration!=undefined" v-model="hourregistration" value="bon">{{
          $t("labels.hourregistrationbon") }}</b-form-radio>
        </b-form-group>
      </div>
      <div class="col" v-if="false">
        <b-form-checkbox v-model="mailurenzzpr" name="checkbox-1" value="yes" unchecked-value="no">{{
        $t("labels.mailtimesheet") }} {{ $t("labels.tozzpr") }}</b-form-checkbox>
      </div>
    </div>
    <div class="form-row">
      <div class="col-2">
        {{ $t("labels.purchaseinvoice") }}
      </div>
      <div class="col-3">
        <b-form-group>
          <b-form-radio :state="inkoopfactuur!=undefined" v-model="inkoopfactuur" value="no">{{ $t("labels.no") }}
          </b-form-radio>
          <b-form-radio :state="inkoopfactuur!=undefined" v-model="inkoopfactuur" value="yes">{{ $t("labels.yes") }}
          </b-form-radio>
        </b-form-group>
      </div>
      <div v-if="hourregistration=='app'" class="col">
        <b-form-checkbox v-model="mailurencontact" name="checkbox-1" value="yes" unchecked-value="no">{{
        $t("labels.tocontact") }}</b-form-checkbox>
      </div>
    </div>
    <div class="form-row">
      <div class="col-2">
        {{ $t("labels.hourcoding") }}
      </div>
      <div class="col-3">
        <b-form-group>
          <b-form-radio :state="urencodering!=null" v-model="urencodering" value="no">{{ $t("labels.no") }}
          </b-form-radio>
          <b-form-radio :state="urencodering!=null" v-model="urencodering" value="yes">{{ $t("labels.mandatory") }}
          </b-form-radio>
          <b-form-radio :state="urencodering!=null" v-model="urencodering" value="both">{{ $t("labels.mandatory") }} & {{ $t("labels.free") }}
          </b-form-radio>  
          <b-form-radio :state="urencodering!=null" v-model="urencodering" value="free">{{ $t("labels.free") }}
          </b-form-radio>      
        
        </b-form-group>
      </div>
      <div class="col-2" >
        {{ $t("labels.sendcopy") }}
      </div>
      <div class="col-4" >
        <select name="contactpersoon" class="form-control contactpersoon" v-model="copy_id">
          <option v-bind:value="null">{{ $t('labels.no') }}</option>
          <option v-for="(user,idx) in company_contact_persons" v-bind:value="user.id" :key="'copy_id'+idx">
            {{ user["firstname"]}} {{ user["middlename"] }} {{ user["lastname"] }}
          </option>
          <option v-for="(user,idx) in admins" v-bind:value="user.id" :key="'admins'+idx">
            {{ user["firstname"]}} {{ user["middlename"] }} {{ user["lastname"] }}
          </option>
        </select>
      </div>

    </div>
    <div class="form-row" v-if="urencodering=='yes' || urencodering=='both'">
      <div class="col-3" v-if="settings.InvoiceByHourcode">
        {{ $t("labels.invoicebyhourcode") }}
      </div>
      <div class="col-2" v-if="settings.InvoiceByHourcode">
        <b-form-group>
          <b-form-radio v-model="invoiceperhourcode" value="no">{{ $t("labels.no") }}</b-form-radio>
          <b-form-radio v-model="invoiceperhourcode" value="yes">{{ $t("labels.yes") }}</b-form-radio>
        </b-form-group>
      </div>
      <div class="col-2">
        {{ $t("labels.hourcategoryname") }}
      </div>
      <div class="col-4">
        <select name="branche" class="form-control opdrachtgever" v-model="hourcategory">
          <option v-for="(hourcategories,idx) in activehourcategories" :key="'hourcategory'+idx"
            v-bind:value="hourcategories.id">
            {{ hourcategories["name"]}}
          </option>
        </select>
      </div>
    </div>

    
    <div class="form-row" v-if="urencodering=='free' || urencodering=='both'">
      <div class="col-2">
        {{ $t("labels.label1") }}
      </div>
      <div class="col-4">
        <b-form-input type=text class="form-control" v-model="label1" />
      </div>
      <div class="col-2">
        {{ $t("labels.label2") }}
      </div>
      <div class="col-4">
        <b-form-input type=text class="form-control" v-model="label2" />
      </div>
    </div>    
    <div class="form-row" >
      <div class="col-3" v-if="settings.RegisterWorkhours">
        {{ $t("labels.workhours") }}
      </div>
      <div class="col-2" v-if="settings.RegisterWorkhours">
        <b-form-group>
          <b-form-radio v-model="workhours" value="no">{{ $t("labels.no") }}</b-form-radio>
          <b-form-radio v-model="workhours" value="yes">{{ $t("labels.yes") }}</b-form-radio>
        </b-form-group>
      </div>
      <div class="col-2"  v-if="settings.SignWorkhours">
        {{ $t("labels.signworkhours") }}
      </div>
      <div class="col-2" v-if="settings.SignWorkhours">
        <b-form-group>
          <b-form-radio v-model="signworkhours" value="no">{{ $t("labels.no") }}</b-form-radio>
          <b-form-radio v-model="signworkhours" value="yes">{{ $t("labels.yes") }}</b-form-radio>
        </b-form-group>
      </div>
    </div>
    <div class="form-row" >
      <div class="col-3">
        {{ $t("labels.notificationprojectstart") }} 
      </div>
      <div class="col-2" >
        <b-form-group>
          <b-form-radio v-model="startnotification" value="no">{{ $t("labels.no") }}</b-form-radio>
          <b-form-radio v-model="startnotification" value="yes">{{ $t("labels.yes") }}</b-form-radio>
        </b-form-group>
      </div>
      <div class="col-2" >
        {{ $t("labels.notificationendworktime") }}
      </div>
      <div class="col-2" >
        <b-form-group>
          <b-form-radio v-model="sendnotification" value="no">{{ $t("labels.no") }}</b-form-radio>
          <b-form-radio v-model="sendnotification" value="yes">{{ $t("labels.yes") }}</b-form-radio>
        </b-form-group>
      </div>
    </div>

    <b-row>
      <b-col>
        <hr>
      </b-col>
    </b-row>

    <div class="form-row">
      <div class="col-2">
        <label v-bind:for="project_name">{{ $t("meta.projectnumber") }}</label>
      </div>
      <div class="col-10">
        <b-form-input type=text class="form-control" v-model="project_reference_number" />
      </div>
    </div>
    <div class="form-row">
      <div class="col-2">
        <label v-bind:for="project_name">{{ $t("labels.projectname") }}</label>
      </div>
      <div class="col-10">
        <b-form-input type=text class="form-control" v-model="project_name" />
      </div>
    </div>
    <div class="form-row">
      <div class="col-2">
        <label v-bind:for="project_location">{{ $t("labels.location") }}</label>
      </div>
      <div class="col-10">
        <b-form-input type=text class="form-control" v-model="project_location" />
      </div>
    </div>
    <div class="form-row">
      <div class="col-2">
        <label v-bind:for="project_description">{{ $t("labels.description") }}</label>
      </div>
      <div class="col-10">
        <b-form-textarea class="form-control" v-model="project_description" />
      </div>
      <div class="col-3">
      </div>
    </div>
    <div class="form-row" v-if="hasHours"> <div style="color:red; text-transform: uppercase; font-weight: bold;"><font-awesome-icon icon="exclamation" style="color: red;" />{{$t('validation.contractedithashours')}} <font-awesome-icon icon="exclamation" style="color: red;" /></div></div>

    <div class="form-row">
      <div class="col-3">
        <label v-bind:for="startdate">{{ $t("meta.startdatum") }}</label>
      </div>
      <div class="col-3">
        <label v-bind:for="starttime">  {{ $t('labels.workhoursstart') }}</label>
      </div>
      <div class="col-6">
        <label v-bind:for="startdatenote">  {{$t('meta.remarks') }} </label>
      </div>
    </div>
    <div class="form-row">
      <div class="col-3">
        <flat-pickr v-model="startdate" :config="config" class="form-control" placeholder="Select date"
          name="startdate" />
      </div>
      <div class="col-3">
        <b-form-input type="time" v-model="starttime" />
      </div>
      <div class="col-6">
        <b-form-input type=text class="form-control" v-model="startdatenote" />
      </div>
    </div>    
    <div class="form-row">
      <div class="col-3">
        <font-awesome-icon v-if="datecheck" icon="check" style="color: green;"/>
        <font-awesome-icon v-else icon="times" style="color: red;"/>                

        <label v-bind:for="enddate">{{ $t("meta.einddatum") }}</label>
      </div>
      <div class="col-3">
        <label v-bind:for="starttime">  {{ $t('labels.workhoursend') }}</label>
      </div>
      <div class="col-6">
        <label v-bind:for="startdatenote">  {{$t('meta.remarks') }} </label>
      </div>
    </div>    
    <div class="form-row">      
      <div class="col-3">
        <flat-pickr v-model="enddate" :config="config" class="form-control" placeholder="Select date" name="enddate" />
      </div>
      <div class="col-3">
        <b-form-input type="time" v-model="endtime" />
      </div>      
      <div class="col-6">
        <b-form-input type=text class="form-control" v-model="enddatenote" />
      </div>
    </div>
    <b-row>
      <b-col>
        <hr>
      </b-col>
    </b-row>

    <div class="form-row">
      <div class="col-2">
        {{ $t("labels.rate") }}
      </div>
      <div class="col-4">
        <b-input-group>
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">
              <font-awesome-icon icon="euro-sign" />
            </span>
          </div>
          <b-form-input type=number  @blur="roundtarief()" placeholder="00.00" step="0.01" lang="nl" class="form-control" v-model="tarief"
            :state="tarief!=undefined" />
        </b-input-group>
      </div>
      <div class="col-2">
        {{ $t("labels.ratesell") }}
      </div>
      <div class="col-4">
        <b-input-group>
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">
              <font-awesome-icon icon="euro-sign" />
            </span>
          </div>
          <b-form-input type=number @blur="roundverkooptarief()"  placeholder="00.00" step="0.01" lang="nl" class="form-control"
            v-model="verkooptarief" :state="verkooptarief!=undefined" />
        </b-input-group>
      </div>
    </div>
    <div v-if="false" class="form-row">
      <div class="col-6">
      </div>
      <div class="col-2">
        {{ $t("labels.marge") }}
      </div>
      <div class="col-4">
        <b-form-input type=number step="0.01" lang="nl" class="form-control" v-model="marge" />
      </div>
    </div>
    <div class="form-row">
      <div class="col-2">
        {{ $t("labels.registerdeclarations") }}
      </div>
      <div class="col-4">
        <b-form-group>
          <b-form-radio :state="registerdeclarations!=undefined" v-model="registerdeclarations" value="no">{{
          $t("labels.no") }}</b-form-radio>
          <b-form-radio :state="registerdeclarations!=undefined" v-model="registerdeclarations" value="yes">{{
          $t("labels.yes") }}</b-form-radio>
        </b-form-group>
      </div>
      <div class="col-2">
        {{ $t("labels.registernormalworkhours") }}
      </div>
      <div class="col-4">
        <b-form-group>
          <b-form-radio :state="registernormalhours!=undefined" v-model="registernormalhours" value="no">{{
          $t("labels.no") }}</b-form-radio>
          <b-form-radio :state="registernormalhours!=undefined" v-model="registernormalhours" value="yes">{{
          $t("labels.yes") }}</b-form-radio>
        </b-form-group>
      </div>      
    </div>
    <div v-if="registerdeclarations=='yes'">
      <div class="form-row">
        <div class="col-12">
          <h4>{{$t("labels.onregelmatigheidstoeslag")}}</h4>
        </div>
        <div class="col-4">{{ $t("labels.active") }}</div>
        <div class="col-4">{{ $t("labels.byzzpr") }}</div>
        <div class="col-4">{{ $t("labels.client") }}</div>
        <div class="col-3" v-if="false">{{ $t("labels.marge") }}</div>
      </div>
      <div class="form-row" v-for="declar in declaraties" :key="'declar'+declar.declaratie_id">
        <div class="col-4">
          <b-form-checkbox v-model="declar.active" name="checkbox-1" value=1 unchecked-value=0>
            <font-awesome-icon v-if="declar.type=='hours'" icon="clock" />
            <font-awesome-icon v-else-if="declar.type=='distance'" icon="box-check" />
            <font-awesome-icon v-else icon="euro-sign" /> {{declar.name}}
          </b-form-checkbox>
        </div>
        <div class="col-4" v-if="declar.type!='total'">
          <b-form-input type=number class="form-control" v-model="declar.zzper" />
        </div>
        <div class="col-4" v-if="declar.type!='total'">
          <b-form-input type=number class="form-control" v-model="declar.client" />
        </div>
        <div class="col-3" v-if="false">
          <b-form-input type=number class="form-control" v-model="declar.marge" />
        </div>
      </div>
    </div>    
    <b-row>
      <b-col>
        <hr>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <label for=""> {{ $t("labels.payment_zzprnl_zzpr") }}</label>
      </b-col>
      <!-- not used for now -->
      <b-col v-if="false">
        <label for=""> {{ $t("labels.payment_client_zzpr") }}</label>
      </b-col>
      <b-col>
        <label for=""> {{ $t("labels.payment_client_zzprnl") }}</label>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-input type="number" class="form-control" v-model="payment_zzprnl_zzpr"
          :state="!isNaN(parseInt(payment_zzprnl_zzpr))" />
      </b-col>
      <b-col v-if="false">
        <b-form-input type="number" class="form-control" v-model="payment_client_zzpr"
          :state="payment_client_zzpr!=null" />
      </b-col>
      <b-col>
        <b-form-input type="number" class="form-control" v-model="payment_client_zzprnl"
          :state="!isNaN(parseInt(payment_client_zzprnl))" />
      </b-col>
    </b-row>
    <div class="form-row">
      <div class="col-2">
        {{ $t("labels.vatshifted") }}
      </div>
      <div class="col-8">
        <b-form-group>
          <b-form-radio :state="btw_verlegd!=undefined" v-model="btw_verlegd" value="yes">{{ $t("labels.vatverlegd") }}
          </b-form-radio>
          <b-form-radio :state="btw_verlegd!=undefined" v-model="btw_verlegd" value="low">{{ $t("labels.vat9") }}
          </b-form-radio>          
          <b-form-radio :state="btw_verlegd!=undefined" v-model="btw_verlegd" value="no">{{ $t("labels.vat21") }}
          </b-form-radio>
          <b-form-radio :state="btw_verlegd!=undefined" v-model="btw_verlegd" value="kor">{{ $t("labels.vatkors") }}
          </b-form-radio>            
        </b-form-group>
      </div>
    </div>
    <b-row>
      <b-col>
        <hr>
      </b-col>
    </b-row>
    <div class="form-row">
      <div class="col">
        <label v-bind:for="extrainfozzpr">{{ $t("labels.contactinfozzpr") }}</label>
      </div>
    </div>
    <div class="form-row">
      <div class="col">
        <b-form-input type=text class="form-control" v-model="extrainfozzpr" />
      </div>
    </div>
    <div class="form-row">
      <div class="col">
        <label v-bind:for="extrainfoclient">{{ $t("labels.contactinfoopdrachtgever") }}</label>
      </div>
    </div>
    <div class="form-row">
      <div class="col">
        <b-form-input type=text class="form-control" v-model="extrainfoclient" />
      </div>
    </div>
    <div class="form-row">
      <div class="col">
        <label v-bind:for="extrainfoinvoice">{{ $t("labels.contactinfofactuurpdrachtgever") }}</label>
      </div>
    </div>
    <div class="form-row">
      <div class="col">
        <b-form-input type=text class="form-control" v-model="extrainfoinvoice" />
      </div>
    </div>

  </b-container>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

const Dutch = require("flatpickr/dist/l10n/nl.js").default.nl;
import axios from 'axios'

export default {
  name: 'projectzzper-planning',
  data() {
    return {
      startdate: null,
      startdatenote: "",
      enddate: null,
      enddatenote: "",
      planning_id: 0,
      project_reference_number: "",
      project_description: "",
      project_location: "",
      project_name: "",
      sign_id: null,
      copy_id: null,
      contact_id: null,
      contract_id: null,
      pointcontact_id: null,
      urencodering: null,
      urencoderingextended: null,
      invoiceperhourcode:null,
      hourcategory: 0,
      hourregistration: "app",
      inkoopfactuur: null,
      mailurenzzpr: "",
      mailurencontact: "",
      payment_zzprnl_zzpr: null,
      payment_client_zzpr: null,
      payment_client_zzprnl: null,
      tarief: null,
      verkooptarief: null,
      marge: null,
      betaalsysteem: null,
      btw_verlegd: null,
      found_planning_id: -1,
      kleur:"#ff0000",
      config: {
        weekNumbers: true,
        locale: Dutch,
        altInput: true,
        altFormat: "d-m-Y",
        dateFormat: "Y-m-d",
      },
      declaraties: [],
      extrainfoclient: "",
      extrainfozzpr: "",
      extrainfoinvoice: "",
      registerdeclarations: null,
      registernormalhours:'yes',
      contract_color: null,
      label1:"",
      label2:"",
      signworkhours:"",
      workhours:"",
      startnotification: "no",
      sendnotification:"no",
      starttime:null,
      endtime:null
  
    }
  },

  mounted() {
    this.onMounted()
  },
  created() {
    // prepopulate your questionrecord array so you don't throw TypeErrors
    // const dummyArray = Array(this.activedeclaraties);
  },
  props: {
    project_id: Number,
  },
  computed: {
    project: {
      get() {
        let proj = this.project_by_id(this.project_id)
        return proj
      },
    },
    ...mapGetters([
      'zzpers',
      'selectedZZPr',
      'username_by_id',
      'project_by_id',
      'user_by_id',
      'company_by_id',
      'activehourcategories',
      'hourcategory_by_id',
      'planning_new',
      'planning_by_id',
      'admins',
      'activedeclaraties',
      'contract_by_id',
      'contract_new',
    ]),
    ...mapState([
      'planning',
      'contracts',
      'last_contract_id',
      'settings',
      'hours'

    ]),
    hasHours(){
      return this.hours.filter(hours=> hours.user_id == this.selectedZZPr && hours.contract_id== this.contract_id).length>0
    },
    planningItem() {
      return this.planning_id > 0 ? this.planning_by_id(this.planning_id) : this.planning_new
    },
    titletext() {
      //find planning
      //  this.onMounted();
      return this.username_by_id(this.selectedZZPr) + " @ [# " + this.project.projectnumber + "] " + this.project.title + " (" + this.project.startdate + " - " + this.project.enddate + ")"
    },
    checkstartdate() {
      return this.startdate >= this.project.startdate && this.startdate <= this.enddate
    },
    checkenddate() {
      return this.enddate <= this.project.enddate && this.enddate >= this.startdate
    },
    status_colors() {
      let options = []
      for (const key in this.settings.status_colors) {
        options.push({ text: this.$i18n.t('labels.' + this.settings.status_colors[key].name), value: this.settings.status_colors[key].color })
      }
      return options
    },
    myStyle() {
      let style = {}
      style.backgroundColor = this.contract_color
      return style
    },
    company_contact_persons: {
      get() {
        let users = []
        let company_contacts = this.company_by_id(this.project.company_id).contactpersons
        for (var i = 0; i < company_contacts.length; i++) {
          if (company_contacts[i]) {
            if (this.user_by_id(company_contacts[i]) != null && this.user_by_id(company_contacts[i]).blacklist!=1) {
              users.push(this.user_by_id(company_contacts[i]))
            }
          }
        }
        // add beheerder
        //users.push(this.user_by_id(this.company_by_id(this.project.company_id).user_id))

        return users.sort(function(a, b) {
            if (a.lastname < b.lastname) return -1;
            if (a.lastname> b.lastname) return 1;
            return 0;
          });
      }
    },
    company_signers :{
      get(){
        let users = []
        let company_contacts = this.company_by_id(this.project.company_id).sign_contacts
        for (var i = 0; i < company_contacts.length; i++) {
          if (company_contacts[i]) {
            if (this.user_by_id(company_contacts[i]) != null&& this.user_by_id(company_contacts[i]).blacklist!=1) {
              users.push(this.user_by_id(company_contacts[i]))
            }
          }
        }
        return users.sort(function(a, b) {
            if (a.lastname < b.lastname) return -1;
            if (a.lastname> b.lastname) return 1;
            return 0;
          });
      }
    },

    company() {
      return this.company_by_id(this.project.company_id)
    },
    user() {
      return this.user_by_id(this.selectedZZPr)
    },
    userphone() {
      let user = this.user_by_id(this.selectedZZPr)
      if (user != undefined) {
        if (user.mobile != undefined) {
          return user.mobile
        } else {
          if (user.phone != undefined) {
            return user.phone
          } else {
            return "no number available"
          }
        }
      } else {
        return ""
      }
    },
    datecheck(){
          if(this.enddate!=undefined && this.startdate!=undefined){
            if(new Date(this.enddate)>= new Date (this.startdate)){
              return true
            }        
          } else {
            return false
          }
          return false
        }    

  },
  methods: {
    validated() {
      return this.contact_id != null &&
        (this.sign_id != null || this.hourregistration !='app')&&
        this.contract_color != null &
        this.pointcontact_id != null &&
        this.urencodering != null &&
        (this.urencodering == 'no' || 
        (this.urencodering == 'yes' && this.hourcategory != 0 && this.hourcategory != null) || 
        (this.urencodering == 'free' && this.label1 != "" && this.label2 != "") ||
        ((this.urencodering == 'both' && this.hourcategory != 0 && this.hourcategory != null) && 
        (this.urencodering == 'both' && this.label1 != "" && this.label2 != ""))
        ) &&
        this.datecheck &&
        (this.registernormalhours=='yes'||this.registerdeclarations=='yes') &&
        this.hourregistration != null &&
        !isNaN(this.tarief) &&
        !isNaN(this.verkooptarief) &&
        !isNaN(this.payment_zzprnl_zzpr) &&
        !isNaN(this.payment_client_zzprnl) &&
        this.btw_verlegd != null &&
        this.registerdeclarations != null
    },
    onMounted() {
      let sd = this.project.startdate
      let ed = this.project.enddate
      let project_name = this.project.title
      let project_description = this.project.description
      let project_location = this.project.location
      this.contact_id = this.project.user_id
      this.sign_id = this.project.user_id
      this.pointcontact_id = this.project.user_id
      let planning_index = -1
      //find userplanning if available
      for (var i = 0; i < this.planning.length; i++) {
        if (this.planning[i].user_id == this.selectedZZPr && this.planning[i].project_id == this.project_id) {
          sd = this.planning[i].startdate;
          ed = this.planning[i].enddate;
          this.planning_id = this.planning[i].id
          planning_index = i
          project_description = this.planning[i].project_description
          project_name = this.project.project_name;
          project_location = this.project.project_location
          break;
        }
      }
      if (this.planning_id == 0) {
        //prefill data
        this.user_id = this.selectedZZPr
        this.startdate = sd
        this.enddate = ed
        if(this.project.hourcategory){
          this.hourcategory = this.project.hourcategory
        } else {
          this.hourcategory = this.company_by_id(this.project.company_id).hourcategory_id
        }
        if(this.project.urencodering){
          this.urencodering = this.project.urencodering
        } else {
          if (this.company_by_id(this.project.company_id).hourcategory_id) {
            this.urencodering = 'yes'
          } else {
            this.urencodering = 'no'
          }
        }
        this.label1 = this.project.label1
        this.label2 = this.project.label2
        this.workhours = this.company.workhours!=undefined?this.company.workhours: "no";
        this.signworkhours =  this.company.signworkhours!=undefined?this.company.signworkhours: "no";
        this.project_reference_number = this.project.projectnumber

        this.project_name = project_name
        this.project_location = project_location
        this.inkoopfactuur = this.user.inkoopfactuur
        this.payment_zzprnl_zzpr = this.user.betalingstermijn
        this.tarief = this.user.tarief
        this.planningItem.tarief = this.user.tarief
        
        this.payment_client_zzprnl = this.company_by_id(this.project.company_id).betalingstermijn
        this.mailurenzzpr = "yes"
        this.mailurencontact = "yes"
        this.startdatenote = "Aanvang: " 
        if(this.project.starttime){
          this.startdatenote += this.project.starttime
          this.starttime = this.project.starttime
        }
        this.enddatenote = "Tot: "
        if(this.project.endtime){
          this.enddatenote += this.project.endtime
          this.endtime = this.project.endtime
        }
        this.hourregistration = "app"
        this.planningItem.hourregistration = "app"
        this.planningItem.workhours = "no"
        this.planningItem.signworkhours = "no"
        this.planningItem.registernormalhours = 'yes'
        this.btw_verlegd = this.user_by_id(this.user_id).btw_verlegd  
        if(this.project.invoiceperhourcode){
          this.invoiceperhourcode = this.project.invoiceperhourcode
        } else {
          this.invoiceperhourcode =  "no"
        }
        this.project_description = project_description + " Zzp`er zorgt voor eigen vervoer, (hand) gereedschap en PBM."
        //        activedeclaraties 
        for (var actie = 0; actie < this.activedeclaraties.length; actie++) {
          let adeclaratie = {}
          adeclaratie.declaratie_id = this.activedeclaraties[actie].id
          adeclaratie.name = this.activedeclaraties[actie].name
          adeclaratie.type = this.activedeclaraties[actie].type
          adeclaratie.active = 0
          adeclaratie.zzper = parseFloat(this.activedeclaraties[actie].purchase)
          adeclaratie.client = parseFloat(this.activedeclaraties[actie].sale)
          adeclaratie.marge = null
          this.declaraties.push(adeclaratie)
        }
        this.contract_id = 0
        this.startnotification = "no"
        this.sendnotification = "no"

      } else {
        this.found_planning_id = this.planning_id
        //this.planning_id = planning_index
        this.pointcontact_id = this.planning[planning_index].pointcontact_id
        this.contact_id = this.planning[planning_index].contact_id
        this.sign_id = this.planning[planning_index].sign_id
        this.copy_id = this.planning[planning_index].copy_id
        this.inkoopfactuur = this.planning[planning_index].inkoopfactuur
        this.mailurenzzpr = this.planning[planning_index].mailurenzzpr
        this.mailurencontact = this.planning[planning_index].mailurencontact
        this.urencodering = this.planning[planning_index].urencodering
        this.urencoderingextended = this.planning[planning_index].urencoderingextended
        this.hourcategory = this.planning[planning_index].hourcategory
        this.hourregistration = this.planning[planning_index].hourregistration
        this.project_reference_number = this.planning[planning_index].project_reference_number
        this.project_name = this.planning[planning_index].project_name
        this.project_location = this.planning[planning_index].project_location
        this.project_description = this.planning[planning_index].project_description
        this.startdate = this.planning[planning_index].startdate
        this.startdatenote = this.planning[planning_index].startdatenote
        this.enddate = this.planning[planning_index].enddate
        this.enddatenote = this.planning[planning_index].enddatenote
        this.tarief = this.planning[planning_index].tarief
        this.verkooptarief = this.planning[planning_index].verkooptarief
        this.marge = this.planning[planning_index].marge

        this.betaalsysteem = this.planning[planning_index].betaalsysteem
        this.payment_zzprnl_zzpr = this.planning[planning_index].payment_zzprnl_zzpr
        this.payment_client_zzpr = this.planning[planning_index].payment_client_zzpr
        this.payment_client_zzprnl = this.planning[planning_index].payment_client_zzprnl
        this.btw_verlegd = this.planning[planning_index].btw_verlegd
        this.label1 = this.planning[planning_index].label1
        this.label2 = this.planning[planning_index].label2
        this.workhours = this.planning[planning_index].workhours
        this.signworkhours = this.planning[planning_index].signworkhours
        this.startnotification = this.planning[planning_index].startnotification
        this.sendnotification= this.planning[planning_index].sendnotification
        this.starttime = this.planning[planning_index].starttime
        this.endtime = this.planning[planning_index].endtime        
        //find contract_id
        // let contractindex = 0;
        // for (i = 0; i < this.contracts.length; i++) {
        //   if (this.contracts[i].user_id == this.planning[planning_index].user_id && this.contracts[i].project_id == this.planning[planning_index].project_id) {
        //     contractindex = i;
        //   }
        // }
        let planningcontract = this.contracts.filter(c=> c.user_id == this.planning[planning_index].user_id && c.project_id == this.planning[planning_index].project_id)[0]
        // console.log(">LALALA>",planningcontract)
        this.contract_id = planningcontract.id;
        this.contract_color = planningcontract.color

        this.extrainfoclient = planningcontract.extrainfoclient
        this.extrainfozzpr = planningcontract.extrainfozzpr
        this.extrainfoinvoice = planningcontract.extrainfoinvoice
        this.registerdeclarations = planningcontract.registerdeclarations
        this.registernormalhours = planningcontract.registernormalhours!=undefined?planningcontract.registernormalhours:'yes'
        this.invoiceperhourcode = planningcontract.invoiceperhourcode
        for (var acti = 0; acti < this.activedeclaraties.length; acti++) {
          let adeclaratie = {}
          adeclaratie.declaratie_id = this.activedeclaraties[acti].id
          adeclaratie.name = this.activedeclaraties[acti].name
          adeclaratie.type = this.activedeclaraties[acti].type
          adeclaratie.active = 0
          adeclaratie.zzper = null
          adeclaratie.client = null
          adeclaratie.marge = null
          for (const onregel of planningcontract.onregelmatig) {
            if (adeclaratie.declaratie_id == onregel.declaratie_id) {
              adeclaratie.active = onregel.active
              adeclaratie.zzper = onregel.zzpr_amount
              adeclaratie.client = onregel.client_amount
              adeclaratie.marge = onregel.marge
            }
          }
          this.declaraties.push(adeclaratie)
        }

      }
    },

    async save_data() {
      if (this.found_planning_id != -1) {
        this.planningItem.id = this.found_planning_id
      }
      this.planningItem.user_id = this.selectedZZPr
      this.planningItem.project_id = this.project_id
      ///call axois
      await axios.put("/api/planning/" + this.planningItem.id, this.planningItem).then(async (response) => {
        let last_contract_id = response.data.contract_id
        this.planningItem.id = response.data.id
        await axios.post("/api/set_contract_color/", { id: last_contract_id, color: this.contract_color })
        // let count = 0
        for (const decla of this.declaraties) {
          decla.contract_id = last_contract_id
          await axios.put("/api/onregelmatig/", decla)
        }

      })
      .finally(() => {
        console.log("i should go second");
        this.$store.dispatch('get_planning')
        this.$store.dispatch('get_contracts')
      });



      //send stamgegevens naar boekhoudgemak
      if(this.settings.boekhoudprogramma=='boekhoudgemak'){
        this.update_debiteur(this.project.company_id)
        this.update_crediteur(this.selectedZZPr)
      }
      if(this.settings.boekhoudprogramma=='snelstart'){
        this.update_debiteur_snelstart(this.project.company_id)
        this.update_crediteur_snelstart(this.selectedZZPr)
      }
    },
    ...mapActions([
      'add_planning'
    ]),
    checkdoc(name) {
      let found = false
      for (const doc of this.user.documents) {
        if (doc.name == name) {
          found = true;
          break
        }
      }
      return found
    },
    checkdate(item) {
      let expireDate = new Date(item)
      var today = new Date();
      var diff = (expireDate.getTime() - today.getTime()) / (1000 * 3600 * 24)
      if ((diff < 14)) {
        return false
      } else {
        return true
      }
    },
    roundtarief() {
        this.tarief = parseFloat(this.tarief).toFixed(2)
        this.planningItem.tarief = this.tarief
    },
    roundverkooptarief() {
        this.verkooptarief = parseFloat(this.verkooptarief).toFixed(2)
        this.planningItem.verkooptarief = this.verkooptarief
    },    
    signerhasemailnotification(sign_id){
      if(sign_id==null){
        return true
      } else {

      if(this.user_by_id(sign_id)&&this.user_by_id(sign_id).mail==undefined){
        return true
      } else { 
        return this.user_by_id(sign_id).mail==0
      }
    }
    },

  },
  watch: {
    contract_color: function () {
      var contract = this.contract_id > 0 ? this.contract_by_id(this.contract_id) : this.contract_new
      if(contract!=undefined){
        contract.color = this.contract_color
      }
    },
    startdate: function () {
      this.planningItem.startdate = this.startdate
    },
    startdatenote: function () {
      this.planningItem.startdatenote = this.startdatenote
    },
    enddate: function () {
      this.planningItem.enddate = this.enddate
      // set color based on enddate
      // let difdays = (new Date(this.enddate).setHours(0,0,0) - new Date().setHours(0,0,0))/(1000*60*60*24)
      // var contract = this.contract_id > 0 ? this.contract_by_id(this.contract_id) : this.contract_new
      // if(difdays<0 || (difdays>0 && difdays<7)) {
      //   this.contract_color = this.settings.status_colors[2].color      
      // } else {
      //   if(difdays>7 && difdays<=14 ) {
      //     this.contract_color = this.settings.status_colors[1].color      
      //   } else {
      //     this.contract_color= this.settings.status_colors[0].color      
      //   }
      // }
    },
    enddatenote: function () {
      this.planningItem.enddatenote = this.enddatenote
    },
    project_reference_number: function () {
      this.planningItem.project_reference_number = this.project_reference_number
    },
    project_name: function () {
      this.planningItem.project_name = this.project_name
    },
    project_description: function () {
      this.planningItem.project_description = this.project_description
    },
    project_location: function () {
      this.planningItem.project_location = this.project_location
    },
    sign_id: function () {
      this.planningItem.sign_id = this.sign_id
    },
    copy_id: function () {
      this.planningItem.copy_id = this.copy_id
    },
    urencodering: function () {
      this.planningItem.urencodering = this.urencodering
    },
    urencoderingextended: function () {
      this.planningItem.urencoderingextended = this.urencoderingextended
    },
    invoiceperhourcode:function () {
      this.planningItem.invoiceperhourcode = this.invoiceperhourcode
      // this.contracts[this.contract_id].invoiceperhourcode = this.invoiceperhourcode//?this.invoiceperhourcode:"no"
    },
    contact_id: function () {
      this.$emit('change')
      this.planningItem.contact_id = this.contact_id
    },
    pointcontact_id: function () {
      this.planningItem.pointcontact_id = this.pointcontact_id
    },
    hourcategory: function () {
      this.planningItem.hourcategory = this.hourcategory
    },
    hourregistration: function () {
      this.planningItem.hourregistration = this.hourregistration
    },
    inkoopfactuur: function () {
      this.planningItem.inkoopfactuur = this.inkoopfactuur
    },
    mailurenzzpr: function () {
      this.planningItem.mailurenzzpr = this.mailurenzzpr
    },
    mailurencontact: function () {
      this.planningItem.mailurencontact = this.mailurencontact
    },
    payment_zzprnl_zzpr: function () {
      this.planningItem.payment_zzprnl_zzpr = this.payment_zzprnl_zzpr
    },
    payment_client_zzpr: function () {
      this.planningItem.payment_client_zzpr = this.payment_client_zzpr
    },
    payment_client_zzprnl: function () {
      this.planningItem.payment_client_zzprnl = this.payment_client_zzprnl
    },
    // tarief: function (newVal) {
    //   if (newVal > 9) {
    //     this.tarief = parseFloat(newVal).toFixed(2)
    //     this.planningItem.tarief = this.tarief
    //   }
    // },
    // verkooptarief: function (newVal) {
    //   if (newVal > 9) {
    //     this.verkooptarief = parseFloat(newVal).toFixed(2)
    //     this.planningItem.verkooptarief = this.verkooptarief
    //   }
    // },
    marge: function () {
      this.planningItem.marge = this.marge
    },
    betaalsysteem: function () {
      this.planningItem.betaalsysteem = this.betaalsysteem
    },
    btw_verlegd: function () {
      this.planningItem.btw_verlegd = this.btw_verlegd
    },
    extrainfozzpr: function () {
      this.planningItem.extrainfozzpr = this.extrainfozzpr
    },
    extrainfoclient: function () {
      this.planningItem.extrainfoclient = this.extrainfoclient
    },
    extrainfoinvoice: function () {
      this.planningItem.extrainfoinvoice = this.extrainfoinvoice
    },
    registerdeclarations: function () {
      this.planningItem.registerdeclarations = this.registerdeclarations
    }, 
    registernormalhours:function (){
      this.planningItem.registernormalhours =  this.registernormalhours
    },
    label1: function () {
      this.planningItem.label1 = this.label1
    },
    label2: function () {
      this.planningItem.label2 = this.label2
    },
    workhours:function(){
      this.planningItem.workhours = this.workhours
    },
    signworkhours:function(){
      this.planningItem.signworkhours = this.signworkhours
      this.sendnotification = this.signworkhours
    },
    startnotification:function(){
      this.planningItem.startnotification = this.startnotification
    },
    sendnotification:function(){
      this.planningItem.sendnotification = this.sendnotification
    },
    endtime:function(){
      this.planningItem.endtime = this.endtime
    },
    starttime:function(){
      console.log(">>>>>", this.starttime)
      this.planningItem.starttime = this.starttime
    }

  },
  components: {
    flatPickr
  }

}
</script>
