<template>
  <b-container v-if="createAny('project')" fluid>
    <input type="hidden" :key="componentKey" />

    <!-- Title bar -->
    <div class="title-bar">
      <h1 v-if="createAny('users')"> {{ $t("labels.project")}} : {{project.title}}</h1>
      <h1 v-else>{{project.title}}</h1>
      <b-button v-if="createAny('users')" variant="primary" @click="edit_project()">
        <font-awesome-icon icon="cog" /> {{ $t("labels.edit") }}
      </b-button>
    </div>

    <!--  -->
    <b-row>
      <b-col cols="8">
        <h4>{{ $t("labels.description") }}</h4>
        {{project["description"]}}
        <h4>{{ $t("meta.projectrefnumber") }}</h4>
        {{project.id}}  
        <h4>{{ $t("meta.projectnumber") }}</h4>
        {{project["projectnumber"]}}                
      </b-col>
      <b-col>
        <b-row>
          <b-col>
            <h2>{{ $t("labels.duration") }}</h2>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            {{this.formatDate(project["startdate"])}}
          </b-col>
          <b-col>
            {{this.formatDate(project["enddate"])}}
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h2>{{ $t("labels.location") }}</h2>
            {{project["location"]}}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h2>{{ $t("labels.workfields") }}</h2>
        <span v-html="this.brancheName()"></span>
      </b-col>
    </b-row>
    <br />
    <b-row class="filter-bar">
      <b-col>
        <img style="height: 5rem;width: 5rem;object-fit: contain;border-radius: 50%;;"
          :src="companyimageto(project.company_id)" />


        <b-form-group :label="$t('labels.client')">
          <a @click="show_company()" class="link-item" :style="{ cursor: 'pointer'}">
            {{this.company_by_id(project.company_id).company_name }} </a><br />
        </b-form-group>
        <h3>{{ $t('meta.remarks') }}</h3>
        <b-input-group v-if="createAny('users')">
          <b-form-textarea size="sm" rows="8" v-bind:type="projectnotes" v-bind:id="'bind-remarks'"
            :ref="projectnotes" class="form-control" v-model="project['extra']" @blur="saveproject()"/>
          <b-input-group-append>
            <b-button squared class="multi-append"  href="javascript:;"
              @click="append_data_multi_project('extra')">
              <font-awesome-icon icon="plus-circle" />
            </b-button>
          </b-input-group-append>
        </b-input-group>   
        <a href="javascript:;" v-if="createAny('users')" class="action-item" @click="saveproject()">
              <font-awesome-icon icon="save" />
            </a>             
      </b-col>
      <b-col>
        <img style="height: 5rem;width: 5rem;object-fit: contain;border-radius: 50%;;"
        :src="profileto(project.user_id)" />
        <b-form-group :label="$t('labels.contactperson')">
          <a @click="show_user()" :style="{ cursor: 'pointer'}" class="link-item">
            {{this.username_by_id(project.user_id)}}
          </a><br />
          <font-awesome-icon class="multi-append"  icon="mobile" />
          <strong v-if="this.user.mobile">{{this.user.mobile}}</strong><br />
          <div v-if="false">
            {{$t('labels.note')}} {{this.formatDate(user.callat)}}<br />
            <b-input-group v-if="createAny('users')">
              <b-form-textarea size="sm" rows="8" v-bind:type="callbacknote" v-bind:id="'bind-remarks'"
                :ref="callbacknote" class="form-control" v-model="user['callbacknote']" @blur="saveuser()"/>
              <b-input-group-append>
                <b-button squared class="multi-append" href="javascript:;"
                  @click="append_data_multi('callbacknote')">
                  <font-awesome-icon icon="plus-circle" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <pre v-else>{{company['remarks']}}</pre>

            <a href="javascript:;" v-if="createAny('users')" class="action-item" @click="saveuser()">
              <font-awesome-icon icon="save" />
            </a>

            <br /><br /><br />

          </div>

        </b-form-group>
      </b-col>
      <b-col>
        <h3>{{ $t("labels.UserDocuments") }}</h3>
        <b-table  :items="project.documents" :fields="docfields">
          <template #cell(link)="row">
            <a  v-if="isImage(row)" :href="docto(row.value)" v-bind:target="'_blank'"><img  style="height: 10rem;width: 10rem;object-fit: contain;" :src="docto(row.value)"/></a>
            <a v-else :href="docto(row.value)" v-bind:target="'_blank'">{{ row.item.name }}</a>
          </template>

        </b-table>
      </b-col>

    </b-row>
    <b-button v-if="createAny('users')" variant="primary" @click="notifyUsersForProject(project)">
      <font-awesome-icon icon="exclamation" /> {{$t('labels.notify')}}
    </b-button>

    <b-row v-if="zzprers_in_project.length>0">
      <b-col>
        <h3>{{ $t("labels.ZZPersinproject") }}</h3>
        <!-- <b-form-checkbox  v-model="showoptions">Acties</b-form-checkbox> -->

      </b-col>
    </b-row>


    <!-- Table -->
    <b-table v-if="zzprers_in_project.length>0" :items="zzprers_in_project" :fields="fields"
      :tbody-tr-attr="rowClassZZPR">
      <template #cell(photo)="row">
        <img class="profile-photo" :src="profileto(row.item.id)" />
      </template>
      <template #cell(id)="row">
        <p v-html="userinfo(row)" />
      </template>
      
      <template   v-if="showoptions" #cell(pulldown)="row">
        <b-dropdown   id="dropdown-dropup" dropup :text="$t('buttons.doaction')" variant="primary">
          <b-dropdown-item @click="generate_urenbonpdf(row.item.contract_id)">
            <font-awesome-icon icon="hourglass-half" /> {{ $t('labels.showurenbon') }}
          </b-dropdown-item>     
        <b-dropdown-item @click="delete_user(row.item.id)">
            <font-awesome-icon icon="trash" /> {{$t('labels.deletecontract')}}
          </b-dropdown-item>
        <b-dropdown-item @click="generate_company_pdf(row.item.contract_id)">
          <font-awesome-icon icon="file-pdf" /> {{ $t('labels.showcontractcompany') }}
        </b-dropdown-item>
        <b-dropdown-item @click="generate_pdf({contract_id:row.item.contract_id})">
          <font-awesome-icon icon="file-pdf" /> {{ $t('labels.showcontract') }}
        </b-dropdown-item>

        <b-dropdown-item @click="sendConfirmModal(row.item.contract_id)">
          <font-awesome-icon icon="envelope-open" /> {{ $t('buttons.sendconfirm') }}
        </b-dropdown-item>
        <b-dropdown-item @click="sendContractModal(row.item.contract_id)">
          <font-awesome-icon icon="envelope-open" /> {{ $t('buttons.sendcontract') }}
        </b-dropdown-item>

        <b-dropdown-item @click="editContractModal(project.id, row.item.id)">
          <font-awesome-icon icon="cog" /> {{ $t('labels.editcontract') }}
        </b-dropdown-item>
        <b-dropdown-item @click="showUserHours(row.item.contract_id)">
          <font-awesome-icon icon="clipboard-list" /> {{ $t('labels.showhours') }}
        </b-dropdown-item>

        </b-dropdown>
        <div>
          <a v-if="hascontract(row.item.id)!=0" href="javascript:;" class="btn" @click="sign_contract(row.item.id)">
            <font-awesome-icon v-if="contract_signed(row.item.id)==2" icon="check-double" />
            <font-awesome-icon v-else-if="contract_signed(row.item.id)==1" icon="check" />
            <font-awesome-icon v-else icon="question" /> {{$t("meta.extra")}}
          </a>
        </div>
      </template>
    </b-table>

    <div class="text-right">
      <div @click="find_ZZPR()" class="btn btn-primary">
        <font-awesome-icon icon="plus" /> {{ $t("labels.addcontract") }}
      </div>
    </div>

  </b-container>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import project_zzper_modal from './modals/ProjectZZPerModal'
import popup_modal from './modals/PopupModal.vue'

export default {
  name: 'projectscreen',
  data() {
    return {
      selectedZZPer: null,
      selectedID: null,
      componentKey: 0,
      callbacknote: "callbacknote",
      projectnotes: "projectnotes",
      showoptions:false, 
      zzpinproj:[]


    }
  },
  mounted(){
    setTimeout(()=>{ this.showoptions = true}, 1000)
  },
  computed: {
    fields() {
      return [
        { key: 'photo', label: this.$i18n.t('labels.photo'), },
        { key: 'id', label: this.$i18n.t('labels.name'), formatter: "username_by_id", sortable: true, sortDirection: 'desc' },
        { key: 'startdate', formatter: this.formatDate, label: this.$i18n.t('labels.startdate'), sortable: true, sortDirection: 'desc' },
        { key: 'enddate', formatter: this.formatDate, label: this.$i18n.t('labels.enddate'), sortable: true, sortDirection: 'desc' },
        { key: 'description', label: this.$i18n.t('labels.description') },
        { key: 'pulldown', label: this.$i18n.t('labels.actions') },
      ]
    },
    docfields() {
      return [
        { key: 'expires', label: this.$i18n.t('meta.aangemaakt'), formatter: this.formatDate },
        { key: 'link', label: this.$i18n.t('labels.link') },

      ]
    },

    ...mapState([
      'projects',
      'users',
      'companies',
      'contracts',
      'planning',
      'selectedZZPr',
      'workfields'
    ]),
    ...mapGetters([
      'project_metas',
      'project_by_id',
      'company_by_id',
      'opdrachtgevers',
      'contactpersonen',
      'zzpers',
      'branche_by_id',
      'user_by_id',
      'workfield_by_id',
      'username_by_id',
      'confirm_modal',
      'user_photo_by_id',
      'createAny',
      'append_data_string',
      'find_contract_id',


    ]),
    project: {
      get() {
        return this.project_by_id(this.$route.params.id)
      },
    },
    user() {
      return this.user_by_id(this.project.user_id)
    },
    zzprers_in_project() {
      if(this.zzpinproj.length==0){

        this.generate_zzprinproject()
      }
       return this.zzpinproj
    },
    company_contact_persons: {
      get() {
        let users = []
        let company_contacts = this.company_by_id(this.project.company_id).contactpersons
        for (var i = 0; i < company_contacts.length; i++) {
          if (company_contacts[i]) {
            users.push(this.user_by_id(company_contacts[i]))
          }
        }
        return users
      }
    },



  },
  methods: {
    generate_zzprinproject(){
      let users = []
        if (this.project.zzprusers) {
          for (var i = 0; i < this.project.zzprusers.length; i++) {
            let auser = this.user_by_id(this.project.zzprusers[i])
            if (auser) {
              let plan = this.planning.filter(p =>p.project_id== this.project.id && p.user_id==auser.id)[0]
                  auser.startdate = plan.startdate
                  auser.enddate = plan.enddate
                  auser.description = plan.project_description;
                  let contract_id = this.find_contract_id(this.project.id, auser.id)
                  auser.contract_id =contract_id
                  auser.color = this.contract_by_id(contract_id) !=undefined && this.contract_by_id(contract_id).color != undefined ? this.contract_by_id(contract_id).color : '#eeeeee'
                  auser._showDetails = true
              users.push(auser)
            }
          }
        }
        this.zzpinproj  = users      
    },
    userinfo(row) {
            // console.log(row)
          return '<a href="/#/user/' + row.item.id + '" :style="{ cursor: \'pointer\'}" class="link-item"> ' + this.username_by_id(row.item.id) + '</a><br/>' +
        '<a href="mailto:' + this.user_by_id(row.item.id).email + '" class="link-item">' + this.user_by_id(row.item.id).email + '</a>'
        },
    delete_user_project(id) {
      console.log(">del>",id)
      this.selectedID = id.project_id
      let message = this.$i18n.t('labels.confirmdeletehead') + id.title + " " + this.$i18n.t('labels.confirmdeletetail')
      let alert = ""

      if(this.hours.filter(h => h.contract_id== id.contract_id).length>0){
        alert +=  this.$i18n.t('validation.contracthashours')

      }
      this.$modal.hide('popup_modal')
      this.$modal.show(popup_modal, {
        message: message,
        alert:alert
      }, {
        name: 'popup_modal',
        draggable: false,
        height: 'auto',
        width: '500px'
      }, {
        'before-close': this.modalCloseDeleteUserProject
      })

      this.$emit('close')
    },
    docto(id) {
      return this.server_url + '/media_' + id;
    },
    isImage(id){
      let ext = id.item.link.split('.');
      let images=["jpg", "jpeg", "png", "webp"]
      if(images.indexOf(ext[ext.length-1])!=-1){
        return true
      }
      return false

    },

    modalCloseDeleteUserProject() {
      if (this.confirm_modal.id == 1) {
        let project_id = this.selectedID
        var datatosend = { project_id: project_id, user_id: this.user.id }
        this.$store.dispatch('remove_project_relations', datatosend);
        let project = this.project_by_id(project_id);
        project.zzprusers.splice(project.zzprusers.indexOf(this.user.id), 1)
        let spliceplanning = -1
        for (var i = 0; i < this.planning.length; i++) {
          if (this.planning[i].user_id == this.user.id && this.planning[i].project_id == project_id) {
            spliceplanning = i
            this.$store.dispatch('delete_planning', this.planning[i].id);
            break
          }
        }
        if (spliceplanning != -1) {
          this.planning.splice(spliceplanning, 1)
        }

      }
    },    
    saveuser() {
      this.save_user(this.user.id)
    },
    saveproject() {
      this.save_project(this.project.id);
    },
    brancheName() {
      var ret = ""
      if (this.company_by_id(this.project.company_id).branche_id) {
        const branch = this.branche_by_id(this.company_by_id(this.project.company_id).branche_id);
        if (branch) {
          ret += "<b>" + branch.name + "</b><br>"
          for (var i = 0; i < this.workfields.length; i++) {
            if (this.project.workfields.indexOf(this.workfields[i]["id"]) != -1) {
              ret += this.workfields[i]["name"] + "<br>"
            }
          }
        }
      }
      return ret
    },
    show_user() {
      this.$router.push({ path: '/user/' + this.user.id });
    },
    ...mapActions([
      'save_project',
      'save_project_relations',
      'remove_project_relations',
      'save_contract',
      'save_user'

    ]),
    show_company() {
      this.$router.push({ path: '/company/' + this.project.company_id });
    },
    delete_user(id) {
      this.selectedID = id
      let message = this.$i18n.t('labels.confirmdeletehead') + this.username_by_id(id) + this.$i18n.t('labels.confirmdeletetail')
      this.$modal.hide('popup_modal')
      this.$modal.show(popup_modal, {
        message: message,
      }, {
        name: 'popup_modal',
        draggable: false,
        height: 'auto',
        width: '500px'
      }, {
        'before-close': this.modalClose
      })

      this.$emit('close')

    },
    modalClose() {
      if (this.confirm_modal.id == 1) {
        let user_id = this.selectedID
        var datatosend = { project_id: this.project.id, user_id: user_id }
        this.$store.dispatch('remove_project_relations', datatosend);
        this.project.zzprusers.splice(this.project.zzprusers.indexOf(user_id), 1)
        let spliceplanning = -1
        for (var i = 0; i < this.planning.length; i++) {
          if (this.planning[i].user_id == user_id && this.planning[i].project_id == this.project.id) {
            spliceplanning = i
            this.$store.dispatch('delete_planning', this.planning[i].id);
            break
          }
        }
        if (spliceplanning != -1) {
          this.planning.splice(spliceplanning, 1)
        }

      }
    },
    save_zzper() {
      if (this.project.zzprusers.indexOf(this.selectedZZPer) == -1) {
        var datatosend = { project_id: this.project.id, user_id: this.selectedZZPer }
        this.$store.dispatch('save_project_relations', datatosend);
        this.project.zzprusers.push(this.selectedZZPer)
      }
    },
    find_ZZPR() {
      this.$store.commit('new_planning', { id: 0, project_id: 0, user_id: 0, pointcontact_id: 0, sign_id: 0 })

      this.$modal.hide('project_zzper_modal')
      this.$modal.show(project_zzper_modal, {
        project_id: this.project.id,
        company_id: this.project.company_id
      }, {
        name: 'project_zzper_modal',
        draggable: '.popup-header',
        height: 'auto',
        width: '1200px'
      }, {
        'before-close': this.modalNewPlanningClose
      })

      this.$emit('close')
    },
    modalNewPlanningClose(){
      this.$store.dispatch('get_contracts');        
      this.$store.dispatch('get_projects');
      this.$store.dispatch('get_planning');                
      console.log("reload planning")
    },

    hascontract(user_id) {
      // let contractid = 0
      // for (var i = 0; i < this.contracts.length; i++) {
      //   if (this.contracts[i].user_id == user_id && this.contracts[i].project_id == this.project.id) {
      //     contractid = this.contracts[i].id
      //   }
      // }
      // return contractid
      return this.contracts.filter(c => c.user_id == user_id && c.project_id== this.project.id)[0].id
    },
    contract_signed(user_id) {
      let signed = 0;
      // for (var i = 0; i < this.contracts.length; i++) {
      //   if (this.contracts[i].user_id == user_id && this.contracts[i].project_id == this.project.id) {
      //     if (this.contracts[i].signed_zzpr == 1) {
      //       signed++
      //     }
      //     if (this.contracts[i].signed_company == 1) {
      //       signed++
      //     }
      //   }
      // }
      let c =  this.contracts.filter(c => c.user_id == user_id && c.project_id== this.project.id)[0]

          if (c.signed_zzpr == 1) {
            signed++
          }
          if (c.signed_company == 1) {
            signed++
          }
      return signed
    },
    edit_project() {
      this.project.branche_id = this.company_by_id(this.project.company_id).branche_id
      this.editProjectModal(this.project)

    },
    onUserClicked(row) {
      this.$router.push({ path: '/user/' + row.id });

    },
    append_data_multi(key) {
      var current = ""
      if (this.user[key] && this.user[key] != null) {
        current = "\n" + this.user[key]
      }
      this.user[key] = this.append_data_string + current
      this.componentKey += 1;
      if (this.$refs[key]) {
        if (this.$refs[key][0]) {
          this.$refs[key][0].focus()
          this.$nextTick(() => {
            this.$refs[key][0].selectionEnd = 9
          })
        } else {
          this.$refs[key].focus()
          this.$nextTick(() => {
            this.$refs[key].selectionEnd = 9;
          })
        }
      }
    },
    append_data_multi_project(key) {
      var current = ""
      if (this.project[key] && this.project[key] != null) {
        current = "\n" + this.project[key]
      }
      this.project[key] = this.append_data_string + current
      this.componentKey += 1;
      if (this.$refs[key]) {
        if (this.$refs[key][0]) {
          this.$refs[key][0].focus()
          this.$nextTick(() => {
            this.$refs[key][0].selectionEnd = 9
          })
        } else {
          this.$refs[key].focus()
          this.$nextTick(() => {
            this.$refs[key].selectionEnd = 9;
          })
        }
      }
    },    
    sign_contract(user_id) {
      let contractid = this.hascontract(user_id)
      if (contractid != 0) {
        let c = this.contracts.filter(c=> c.id == contractid)[0]
        if (c.signed_zzpr == 1 && c.signed_company == 1) {
              this.sendContractModal(contractid)
            }
      }
    },
    rowClassZZPR(item) {
      return { "style": "background-color:" + item.color }
    },
  },
  watch:{
    project(){
      console.log("project change")
    } ,
    planning(){
      console.log("planning change")
      this.generate_zzprinproject()
    } 
  }

}
</script>

