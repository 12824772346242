import Vue from 'vue'
import add_contract_modal from '../components/modals/AddContractModal.vue'

import user_details_modal from '../components/modals/UserModal'
import companies_details_modal from '../components/modals/CompanyModal'
import calluser_modal from '../components/modals/CallModal'
import check_hours_modal from '../components/modals/CheckHourModal'
import project_details_modal from '../components/modals/ProjectModal'
import contract_modal from '../components/modals/ContractModal'
import send_contract_modal from '../components/modals/SendContract'
import user_hours_modal from '../components/modals/HoursModal'
import send_confirm_modal from '../components/modals/SendConfirmation'
import alert_project_modal from '../components/modals/ProjectAlertModal.vue'


import { mapGetters } from 'vuex'

Vue.mixin({
  computed: {
    ...mapGetters([
      'contract_by_id',
    ]),

  },
  methods: {
    addContractModal(user_id) {
      this.$modal.hide('add_contract_modal')
      this.$modal.show(add_contract_modal, {
        user_id: user_id,
      }, {
        name: 'add_contract_modal',
        draggable: '.popup-header',
        height: 'auto',
        width: '1000px',
        shiftY: 0
      })

      this.$emit('close')
    },
    editUserModal(userid) {
      this.$modal.hide('user-details-modal')
      this.$modal.show(user_details_modal, {
        user_id: userid,
      }, {
        name: 'user-details-modal',
        draggable: '.popup-header',
        height: 'auto',
        width: '90%',
        shiftY: 0
      })
      this.$emit('close')
    },
    editCompanyModal(company) {
      if (company.id == 0) {
        this.$store.commit('new_company', { id: 0, owner_id: this.current_user.id, country: "NL", betalingstermijn: 30 })

      }
      this.$modal.hide('companies_details_modal')
      this.$modal.show(companies_details_modal, {
        company_id: company.id,
      }, {
        name: 'companies_details_modal',
        draggable: '.popup-header',
        height: 'auto',
        width: '1000px',
        shiftY: 0
      })

      this.$emit('close')
    },
    editProjectModal(project) {
      this.$modal.hide('project_details_modal')
      this.$modal.show(project_details_modal, {
        project_id: project.id,
      }, {
        name: 'project_details_modal',
        draggable: '.popup-header',
        height: 'auto',
        width: '90%',
        shiftY: 0
      })

      this.$emit('close')
    },
    editContractModal(project_id, user_id) {
      this.$store.commit('selectedZZPr', user_id)

      this.$modal.hide('contract_modal')
      this.$modal.show(contract_modal, {
        project_id: project_id,
        user_id: user_id,
      }, {
        name: 'contract_modal',
        draggable: '.popup-header',
        height: 'auto',
        width: '1000px',
        shiftY: 0
      })
      this.$emit('close')

    },
    callUserModal(userid) {
      this.$modal.hide('calluser_modal')
      this.$modal.show(calluser_modal, {
        user_id: userid,
      }, {
        name: 'calluser_modal',
        draggable: '.popup-header',
        height: 'auto',
        width: '600px',
        shiftY: 0
      })
      this.$emit('close')
    },
    checkHoursModal(contract_id, weeknumber = -1) {
      // find contract for project + user
      this.$modal.hide('check_hours_modal')
      this.$modal.show(check_hours_modal, {
        contract_id: contract_id,
        week_number: weeknumber
      }, {
        name: 'check_hours_modal',
        draggable: '.popup-header',
        height: 'auto',
        width: '1000px',
        shiftY: 0
      })

      this.$emit('close')

    },
    sendContractModal(contract_id) {
      this.$modal.hide('send_contract_modal')
      this.$modal.show(send_contract_modal, {
        contract_id: contract_id
      }, {
        name: 'send_contract_modal',
        draggable: '.popup-header',
        height: 'auto',
        width: '800px',
        shiftY: 0
      })
      this.$emit('close')
    },
    sendConfirmModal(contract_id) {
      this.$modal.hide('send_confirm_modal')
      this.$modal.show(send_confirm_modal, {
        contract_id: contract_id
      }, {
        name: 'send_confirm_modal',
        draggable: '.popup-header',
        height: 'auto',
        width: '800px',
        shiftY: 0
      })
      this.$emit('close')
    },
    showUserHours(contract_id, weeknumber = -1) {
      // force to load hours from user in contract
      console.log(">>>showUserHours", contract_id, weeknumber)
      let params ={}
      params.params = {}
      params.params.id = this.contract_by_id(contract_id).user_id
      this.$store.dispatch('get_user_hours', params)
      this.$store.dispatch('get_signatures')
      this.$modal.hide('user_hours_modal')
      this.$modal.show(user_hours_modal, {
        contract_id: contract_id,
        week_number: weeknumber
      }, {
        name: 'user_hours_modal',
        draggable: '.popup-header',
        height: 'auto',
        width: '90%',
        shiftY: 0

      }, {
        'before-close': ()=>{
          console.log("Close user hour, reload hours")
          this.$store.dispatch('get_hours')

        }
      })

      this.$emit('close')


    },
    notifyUsersForProject(item) {
      this.$modal.hide('alert_project_modal')
      this.$modal.show(alert_project_modal, {
        project_id: item.id,
      }, {
        name: 'alert_project_modal',
        draggable: '.popup-header',
        height: 'auto',
        width: '90%',
        shiftY: 0
      })

      this.$emit('close')
    }

  }
})