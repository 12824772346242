
<template>
  <b-container>
    <b-table v-if="user.friends.length>0" :items=this.user.friends :fields=fields>
      <template #cell(actions)="row">
        <a v-if="row.item.id!=0" href="javascript:;" class="action-item" @click="delete_friend(row.item.id)">
          <font-awesome-icon icon="trash" />
        </a>
      </template>
    </b-table>
    <h3 v-else>{{ $t("labels.nofriends") }}</h3>
    <b-row>
      <b-col>
        <h2>{{ $t("buttons.addfriend") }}</h2>
      </b-col>
    </b-row>
    <div class="row">

      <div class="col">

        <b-form-group :label="$t('labels.friend')">
          <v-select label="name" :options="other_zzprs" class="contactpersoon" v-model="friend_id"></v-select>
        </b-form-group>
      </div>

    </div>
    <div class="form-row">
      <div class="col-3">
        <div v-if="friend_id!=null" @click="save_friend" class="btn btn-primary">{{ $t("buttons.addfriend") }}</div>
      </div>
    </div>
  </b-container>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  name: 'userfriendssscreen',
  data() {
    return {
      friend_id: null,
    }
  },
  props: {
    user_id: Number
  },
  computed: {
    fields() {
      return [
        { key: 'friend_id', label: this.$i18n.t('labels.name'), formatter: "fullName", sortable: true, sortDirection: 'desc' },
        { key: 'actions', label: this.$i18n.t('labels.actions') },
      ]
    },
    user() {
      return this.user_id > 0 ? this.user_by_id(this.user_id) : this.user_new
    },
    other_zzprs() {
      let other = []
      let ids = [...new Set(this.user.friends.map(item => item.friend_id))]
      this.users.filter(u => ids.indexOf(u.id)==-1).forEach( u =>{
          let name = this.username_by_id(u.id)
          other.push({ name: name, id: u.id });
      })

      // for (let i = 0; i < this.users.length; i++) {
      //   let adduser = this.users[i].id == this.user_id ? false : true;
      //   for (var f = 0; f < this.user.friends.length; f++) {
      //     if (this.user.friends[f].friend_id == this.users[i].id) {
      //       adduser = false;
      //       break;
      //     }
      //   }
      //   if (adduser) {
      //     let name = this.username_by_id(this.users[i].id)
      //     other.push({ name: name, id: this.users[i].id });
      //   }
      // }
      return other
    },
    ...mapGetters([
      'user_metas',
      'user_by_id',
      'activebranches',
      'user_new',
      "username_by_id"
    ]),
    ...mapState([
      'users',
      'branches',
      'avatars'
    ]),

  },
  methods: {
    delete_friend(id) {
      console.log(id,this.user.friends)
      let friend_id=null
      for (let i = 0; i < this.user.friends.length; i++) {
        if (this.user.friends[i].id == id) {
          friend_id=this.user.friends[i].friend_id

          this.user.friends.splice(i, 1);
        }
      }
     this.delete_user_friend(id);
      if(friend_id){
        for (let i = 0; i < this.user_by_id(friend_id).friends.length; i++) {
          if (this.user_by_id(friend_id).friends[i].friend_id == this.user_id) {    
            this.delete_user_friend(this.user_by_id(friend_id).friends[i].id);
          }
        }
      }
    },
    fullName(value) {
      if (value) {
        return this.username_by_id(value)
      } else {
        return ""
      }
    },
    save_friend() {
      var datatosend = { friend_id: this.friend_id.id, user_id: this.user_id, id: 0 }
      this.user.friends.push(datatosend)
      this.$store.dispatch('save_user_friend', datatosend);
      // cross connect
      var crossfriend = { user_id: this.friend_id.id, friend_id: this.user_id, id: 0 }
      this.$store.dispatch('save_user_friend', crossfriend);      
    },
    ...mapActions([
      'save_user_friend',
      'delete_user_friend'
    ]),



  },
  watch:{
    friend_id(){
      if(this.friend_id!=null){
        this.save_friend()
      }
      this.friend_id=null
    }
  }

}    
</script>
