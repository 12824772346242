<template>
    <b-container v-if="createAny('users') && currentUserIsSuperAdmin()" fluid>
      <h1> Default settings</h1>
    <div class="form-row">
      <div class="col-2">
        {{ $t("labels.purchaseinvoice") }}
      </div>
      <div class="col-3">
        <b-form-group>
          <b-form-radio  v-model="inkoopfactuur" value="no">{{ $t("labels.no") }}
          </b-form-radio>
          <b-form-radio  v-model="inkoopfactuur" value="yes">{{ $t("labels.yes") }}
          </b-form-radio>
        </b-form-group>
      </div>
    </div>
    <div class="form-row">
      <div class="col-2">
        {{ $t("labels.hourcoding") }}
      </div>
      <div class="col-3">
        <b-form-group>
          <b-form-radio v-model="urencodering" value="no">{{ $t("labels.no") }}
          </b-form-radio>
          <b-form-radio  v-model="urencodering" value="yes">{{ $t("labels.mandatory") }}
          </b-form-radio>
          <b-form-radio  v-model="urencodering" value="both">{{ $t("labels.mandatory") }} & {{ $t("labels.free") }}
          </b-form-radio>  
          <b-form-radio  v-model="urencodering" value="free">{{ $t("labels.free") }}
          </b-form-radio>      
        
        </b-form-group>
      </div>
    </div>
    </b-container>

</template>
<script>
import { mapGetters, mapState } from 'vuex'


export default {
  name: 'projectzzper-planning',
  data() {
    return {
      startdate: null,
      startdatenote: "",
      enddate: null,
      enddatenote: "",
      planning_id: 0,
      project_reference_number: "",
      project_description: "",
      project_location: "",
      project_name: "",
      sign_id: null,
      copy_id: null,
      contact_id: null,
      contract_id: null,
      pointcontact_id: null,
      urencodering: null,
      urencoderingextended: null,
      invoiceperhourcode:null,
      hourcategory: 0,
      hourregistration: "app",
      inkoopfactuur: null,
      mailurenzzpr: "",
      mailurencontact: "",
      payment_zzprnl_zzpr: null,
      payment_client_zzpr: null,
      payment_client_zzprnl: null,
      tarief: null,
      verkooptarief: null,
      marge: null,
      betaalsysteem: null,
      btw_verlegd: null,
      found_planning_id: -1,
      kleur:"#ff0000",
      declaraties: [],
      extrainfoclient: "",
      extrainfozzpr: "",
      extrainfoinvoice: "",
      registerdeclarations: null,
      registernormalhours:'yes',
      contract_color: null,
      label1:"",
      label2:"",
      signworkhours:"",
      workhours:"",
      startnotification: "no",
      sendnotification:"no",
      starttime:null,
      endtime:null
  
    }
  },

  mounted() {
    this.onMounted()
  },
  computed: {
    ...mapGetters([
      'zzpers'
      , 'createAny',

    ]),
    ...mapState([
      'settings',
    ]),  

  },
  methods: {
    onMounted() {
        //prefill data
        this.user_id = this.selectedZZPr
        if(this.project.hourcategory){
          this.hourcategory = this.project.hourcategory
        } else {
          this.hourcategory = this.company_by_id(this.project.company_id).hourcategory_id
        }
        if(this.project.urencodering){
          this.urencodering = this.project.urencodering
        } else {
          if (this.company_by_id(this.project.company_id).hourcategory_id) {
            this.urencodering = 'yes'
          } else {
            this.urencodering = 'no'
          }
        }
        this.label1 = this.project.label1
        this.label2 = this.project.label2
        this.workhours = this.company.workhours!=undefined?this.company.workhours: "no";
        this.signworkhours =  this.company.signworkhours!=undefined?this.company.signworkhours: "no";
        this.project_reference_number = this.project.projectnumber

        this.payment_zzprnl_zzpr = this.user.betalingstermijn
        this.tarief = this.user.tarief
        this.planningItem.tarief = this.user.tarief
        
        this.payment_client_zzprnl = this.company_by_id(this.project.company_id).betalingstermijn
        this.mailurenzzpr = "yes"
        this.mailurencontact = "yes"
        this.startdatenote = "Aanvang: " 
        if(this.project.starttime){
          this.startdatenote += this.project.starttime
          this.starttime = this.project.starttime
        }
        this.enddatenote = "Tot: "
        if(this.project.endtime){
          this.enddatenote += this.project.endtime
          this.endtime = this.project.endtime
        }
        this.hourregistration = "app"
        this.planningItem.hourregistration = "app"
        this.planningItem.workhours = "no"
        this.planningItem.signworkhours = "no"
        this.planningItem.registernormalhours = 'yes'
        this.btw_verlegd = this.user_by_id(this.user_id).btw_verlegd  
        if(this.project.invoiceperhourcode){
          this.invoiceperhourcode = this.project.invoiceperhourcode
        } else {
          this.invoiceperhourcode =  "no"
        }
        //        activedeclaraties 
        for (var actie = 0; actie < this.activedeclaraties.length; actie++) {
          let adeclaratie = {}
          adeclaratie.declaratie_id = this.activedeclaraties[actie].id
          adeclaratie.name = this.activedeclaraties[actie].name
          adeclaratie.type = this.activedeclaraties[actie].type
          adeclaratie.active = 0
          adeclaratie.zzper = parseFloat(this.activedeclaraties[actie].purchase)
          adeclaratie.client = parseFloat(this.activedeclaraties[actie].sale)
          adeclaratie.marge = null
          this.declaraties.push(adeclaratie)
        }
        this.contract_id = 0
        this.startnotification = "no"
        this.sendnotification = "no"

    },


  },
  watch: {
  },


}
</script>
