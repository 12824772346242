<template>
    <div class="popup">

        <div class="popup-header">
            <div v-if="user.id > 0">
                {{ user.firstname }} {{ user.middlename }} {{ user.lastname }}
            </div>
            <div v-else>
                {{ $t("labels.newuser") }}
            </div>
            <div v-if="user.id == 0" class="popup-close" @click="close_modal">
                <font-awesome-icon icon="times" />
            </div>
            <div v-else class="popup-close" @click="cancel_edit">
                <font-awesome-icon icon="times" />
            </div>
        </div>

        <div class="popup-body" ref="bodyTarget">
            <div ref="bodyTop"></div>
            <input type="hidden" :key="componentKey" />

            <b-tabs  vertical no-nav-style v-if="user.role == 'user' && user.id != 0" v-model="tab">
                <b-tab active>
                    <template v-slot:title>{{ $t("labels.general") }}</template>
                    <UserDefault  v-bind:user_id="user_id" ref="userdefault" @change="usercheck"></UserDefault>
                </b-tab>
                <b-tab v-if="user.id != 0">
                    <template v-slot:title>{{ $t("labels.settings") }}</template>
                    <UserSettings  v-bind:user_id="user.id"></UserSettings>
                </b-tab>
                <b-tab v-if="user.id != 0 && createAny('users')">
                    <template v-slot:title>{{ $t("labels.blocking") }}</template>
                    <UserBlocks v-bind:user_id="user.id"></UserBlocks>
                </b-tab>
                <b-tab v-if="user.role == 'user' && user.id != 0">
                    <template v-slot:title>{{ $t("labels.workfields") }}</template>
                    <UserWorkfieldSettings v-if="tab==3" v-bind:user_id="user.id"></UserWorkfieldSettings>
                </b-tab>
                <b-tab v-if="user.role == 'user' && user.id != 0">
                    <template v-slot:title>{{ $t("labels.friends") }}</template>
                    <UserFriends v-bind:user_id="user.id"></UserFriends>
                </b-tab>
                <b-tab v-if="user.id != 0">
                    <template v-slot:title>{{ $t("labels.photo") }}</template>
                    <UserProfileImage v-bind:user_id="user.id"></UserProfileImage>
                </b-tab>
                <b-tab v-if="user.role == 'user' && user.id != 0 && createAny('users')">
                    <template v-slot:title>{{ $t("titles.projects") }}</template>

                    <UserPlanning  v-bind:user_id="user.id" ref="planning"></UserPlanning>
                </b-tab>
                <b-tab v-if="user.role == 'user' && user.id != 0">
                    <template v-slot:title>{{ $t("labels.ZZPcompany") }}</template>
                    <UserCompany v-bind:user_id="user.id" ref="company"></UserCompany>
                </b-tab>
                <b-tab v-if="user.id != 0 && createAny('users')">
                    <template v-slot:title>{{ $t("labels.UserDocuments") }}</template>
                    <UserDocuments v-bind:user_id="user.id" ref="document"></UserDocuments>
                </b-tab>
                <b-tab v-if="user.role == 'user' && user.id != 0">
                    <template v-slot:title>{{ $t("labels.UserTags") }}</template>
                    <UserTags  v-bind:user_id="user.id"></UserTags>
                </b-tab>

            </b-tabs>
            <b-tabs vertical no-nav-style v-else>
                <b-tab active>
                    <template v-slot:title>{{ $t("labels.general") }}</template>
                    <UserDefault v-bind:user_id="user_id" ref="userdefault" @change="usercheck"></UserDefault>
                </b-tab>
                <b-tab>
                    <template v-if="user.id != 0" v-slot:title>{{ $t("labels.settings") }}</template>
                    <UserSettings v-if="user.id != 0" v-bind:user_id="user_id"></UserSettings>
                </b-tab>
                <b-tab v-if="user.id != 0">
                    <template v-slot:title>{{ $t("labels.photo") }}</template>
                    <UserProfileImage v-if="user.id != 0" v-bind:user_id="user_id"></UserProfileImage>
                </b-tab>
                <b-tab v-if="user.id != 0 && createAny('users')">
                    <template v-slot:title>{{ $t("labels.UserDocuments") }}</template>
                    <UserDocuments v-bind:user_id="user.id" ref="document"></UserDocuments>
                </b-tab>
                <b-tab v-if="user.id != 0">
                    <template v-slot:title>{{ $t("labels.UserTags") }}</template>
                    <UserTags v-bind:user_id="user_id"></UserTags>
                </b-tab>
                <b-tab v-if="user.role == 'user' && user.id != 0">
                    <template v-slot:title>{{ $t("labels.workfields") }}</template>
                    <UserWorkfieldSettings v-bind:user_id="user_id"></UserWorkfieldSettings>
                </b-tab>      
                          
            </b-tabs>
        </div>

        <div class="popup-footer">
            <div v-if="user.id == 0" class="btn btn-primary" @click="my_add_user(); ">{{ adduserText() }}</div>
            <div v-else @click="save_and_close" class="btn btn-primary">{{ adduserText() }}</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import UserWorkfieldSettings from './settings-screens/UserWorkfields'
import UserDefault from './settings-screens/UserDefault'
import UserProfileImage from './settings-screens/UserProfileImage'
import UserPlanning from './settings-screens/UserPlanning'
import UserCompany from './settings-screens/UserCompany'
import UserSettings from './settings-screens/UserSettings'
import UserDocuments from './settings-screens/UserDocuments'
import UserFriends from './settings-screens/UserFriends'
import UserTags from './settings-screens/UserTags'
import UserBlocks from './settings-screens/UserBlocks'

export default {
    name: 'user-modal',
    data() {
        return {
            componentKey: 0,
            tab:null
        }
    },
    props: {
        user_id: Number
    },
    mounted: function () {
        this.$nextTick(function () {
            this.componentKey++
        })
    },
    computed: {
        user() {
            return this.user_id > 0 ? this.user_by_id(this.user_id) : this.user_new
        },
        ...mapGetters([
            'user_metas',
            'user_by_id',
            'activebranches',
            'user_new',
            'createAny',

        ]),
        ...mapState([
            'users',
            'settings',
            'current_user',
            'branches',
        ])
    },
    methods: {
        adduserText() {

            if (this.$refs.userdefault && this.$refs.userdefault.validated() && (this.user.id==0 ||this.user.role!='user'||(this.$refs.company && this.$refs.company.validated()))) {
                if (this.user.id == 0) {
                    return this.$i18n.t("buttons.add")
                } else {
                    return this.$i18n.t("buttons.saveandclose")
                }
            } else {
                return this.$i18n.t("buttons.invalid")
            }
        },
        usercheck() {
            this.componentKey++
        },
        translate(path) {
            return this.$i18n.t('meta.' + path.toLowerCase())
        },
        save_and_close() {
            if (this.$refs.userdefault.validated() == true && (this.user.id==0||this.user.role!='user'||this.$refs.company.validated())) {
                if (this.user.role == 'user') {
                    this.save_user(this.user.id)
                    this.$refs.company.savedata();
                    this.close_modal()
                    // this.update_crediteur(this.user.id);
                } else {
                    this.save_user(this.user.id)
                    this.close_modal()
                }
            }
        },
        cancel_edit() {
            this.get_users()
            this.close_modal()
        },

        ...mapActions([
            'save_user',
            'add_user',
            'get_users'
        ]),
        close_modal() {
            this.$store.commit('new_user', {
          id: 0,
          branche_id: 1,
          workfields: [],
          role:'user',
          owner_id: this.current_user.id,
          active: 1,
          language: "nl",
          country: "NL",
          notifications: 1,
          newsletter: 1,
          mail: 1,
          sms: 1,
          inapp: 1,
          company_contact: 0,
          invoice_contant: 0,
          document_contact: 0,
          sign_contact: 0,
          location_contact: 0,
          isemployee:0
        
        })
            this.$emit('close')
        },
        my_add_user() {
            if (this.$refs.userdefault.validated() == true) {
                this.add_user(this.user)
                this.close_modal()
                //scroll to top 
                this.$refs["bodyTop"].scrollIntoView({ behavior: "smooth" })
            }
        },
    },
    components: { UserTags, UserFriends, UserWorkfieldSettings, UserSettings, UserDefault, UserProfileImage, UserPlanning, UserCompany, UserDocuments, UserBlocks }
}
</script>