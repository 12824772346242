
<template>
  <b-container fluid>
    <b-table sort-icon-left :items="myoffer" :fields="fields" >
    
    <template #cell(replacement)="row">
        <font-awesome-icon v-if="row.item.replacement==1" icon="check" style="color: green;"
          :title="$t('labels.editbrancheoff')" />
        <font-awesome-icon v-else icon="times" style="color: red;" :title="$t('labels.editbrancheon')" />
      </template>
      <template #cell(pulldown)="row">
          <b-dropdown id="dropdown-dropup" dropup :text="$t('buttons.doaction')" variant="primary">
            <b-dropdown-item @click="confirm_delete_user(row.item)" :title="$t('labels.deleteuser')">
              <font-awesome-icon icon="trash" /> {{ $t('labels.deleteuser') }}
            </b-dropdown-item>
            <b-dropdown-item @click="edit_user(row.item)">
              <font-awesome-icon icon="cog" /> {{ $t('labels.editperiod') }}
            </b-dropdown-item>
            <b-dropdown-item @click="review_user(row.item)">
              <font-awesome-icon icon="star" /> {{ $t('buttons.reviewuser') }}
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template #cell(reviewscore)="row">
          <star-rating :rating="row.item.reviewscore" :read-only="true" :increment="0.01" v-bind:star-size="20"  ></star-rating>
        </template>
        <template #cell(qualities)="row">
          <div v-html="qualities(row)"></div>
        </template>        

    </b-table>
    <BSCarouselOptions/>
    <BSReviewUser />
    </b-container>
</template>


<script>
import { mapGetters, mapState } from 'vuex'
import BSCarouselOptions from '../modals/BSCarouselOptions'
import BSReviewUser from '../modals/BSReviewUser.vue';
import popup_modal from '../modals/PopupModal.vue'
import axios from 'axios';
import StarRating from 'vue-star-rating'

export default {
  name: 'carouselmyoffer',
  data() {
    return {
      edituser:null
    }    
},

computed: {
  ...mapState([
    'users',
    // 'branches',
    // 'avatars',
    'planning',
    'workfields',
    'tags',
    'current_user',
    'showBlacklist',
    'companies',
    'projects',
    'usersettings',
    'carouselURL',
    'carouselID',
    'carouselToken',
    'carouselPeople'
  ]),
  ...mapGetters([
    'branche_by_id',
    'user_by_id',
    'user_photo_by_id',
    'activebranches',
    'username_by_id',
    'confirm_modal',
    'owners',
    'find_last_planning_user'
  ]),
  myoffer(){
    return this.carouselPeople.filter(u =>u.owner== this.carouselID)
  },
  fields(){
    return [
          { key: 'firstname' },
          { key: 'city', label: this.$i18n.t('labels.city'), sortable: false, sortDirection: 'desc' },
          { key: 'startdate', label: this.$i18n.t('labels.startdate'), sortable: false, sortDirection: 'desc' },
          { key: 'enddate', label: this.$i18n.t('labels.enddate'), sortable: false, sortDirection: 'desc' },
          { key: 'replacement', label: this.$i18n.t('buttons.replace'), sortable: false, sortDirection: 'desc' },
          { key:'reviewscore'},
          { key:'qualities'},
          { key: 'pulldown', label: this.$i18n.t('labels.actions') },

      ]
    }
  },
  methods:{
    qualities(row){
      console.log("qualities", row)
      if(row.item.qualities){
        let res =  "<dl><dt>Category 1</dt><dl>"+row.item.qualities.category1+"</dl>"
        res+=  "<dt>Category 2</dt><dl>"+row.item.qualities.category2+"</dl>"
        res+=  "<dt>Category 3</dt><dl>"+row.item.qualities.category3+"</dl>"
        return res
      } else{
        return ""
      }
    },
    confirm_delete_user(id) {
      this.selectedID = id.id
      let message = this.$i18n.t('labels.confirmdeletehead') + this.username_by_id(id.remote_user_id) + this.$i18n.t('labels.confirmdeletetail')
      this.$modal.hide('popup_modal')
      this.$modal.show(popup_modal, {
        message: message,
        alert:this.$i18n.t("validation.removecarouseluser")
      }, {
        name: 'popup_modal',
        draggable: false,
        height: 'auto',
        width: '500px'
      }, {
        'before-close': this.modalClose
      })

      this.$emit('close')

    },
    async modalClose() {
      if (this.confirm_modal.id == 1) {
        await axios.delete(this.carouselURL+"api/people/" + this.selectedID,  {
                headers: { Authorization: `Bearer ${this.carouselToken}` }
              }
        )
        .then(() => {
          // console.log(">>>>get_carousel_people>>",response.data)
        })
        for (let i = 0; i < this.carouselPeople.length; i++) {
          if (this.carouselPeople[i].id == this.selectedID) {
            this.carouselPeople.splice(i, 1);
          }
        }
        this.$store.dispatch('get_carousel_people')

      }
    },
    edit_user(id){
      this.edituser = id
      this.$store.commit('carouselUser',id)
      this.$bvModal.show('BSCarouselOptionsID')

    },      
    review_user(id){
      this.$store.commit('carouselUser',id)
      this.$bvModal.show('BSReviewUserID')

    },      
  },
  components:{BSCarouselOptions,BSReviewUser,StarRating}

}
</script>
