<template>
  <b-container fluid>
    <div id="user-project-overview">
      <input type="hidden" :value="componentKey" />

      <div v-if="index==2">
        <div v-for="(project, index) in contact_user_projects" :key="index">
            <div v-if="closedFor(project.contract_id)>0"  class="project tab2" :style="projectTitleClass(closedFor(project.contract_id)==0)">
              <div class="project-title" >
                <h4 class="mb-0">{{username_by_id(project.zzpr)}}</h4>
                <div class="projectnumber">{{ project.projectnumber }}</div>

                <p>{{project.project_name}}</p>

            </div>
              
              <b-row >
                <b-col cols="2"></b-col>
                <b-col><strong>{{$t('labels.week')}}</strong></b-col>
              </b-row>
            </div>
            <div v-for="(weeks, windex) in contractHours(project.contract_id)" :key="windex">
              <div v-if="(invoiceextraweek(project.contract_id,weeks.week, weeks.year).length==0 ||(invoiceextraweek(project.contract_id,weeks.week, weeks.year).length>0 &&invoiceextraweek(project.contract_id,weeks.week, weeks.year )[0].absent==0)) &&weeks.closed&&weeks.signed==0&& weeks.unsigned!=0"
                @click="pageUserHours(project.contract_id,weeks.week, weeks.year)">
                <b-row class="fillhours">
                  <b-col>
                    <font-awesome-icon icon='signature' class="table-contacts-roles"  />
                  </b-col>
                  <b-col>{{weeks.week}}</b-col>
                  <b-col cols="8">
                    <div v-html="gethoursinfo(project.contract_id,weeks)" />
                  </b-col>
                </b-row>
              </div>
            </div>
            <hr v-if="closedFor(project.contract_id)>0">
          </div>
        </div>
      <div v-if="index==3">
        <b-row>
        <b-col>
          <dl v-if="showweek!=-1 || showyear!=-1">
            <dt v-if="showweek!=-1">{{ $t("labels.week") }}</dt>
            <dd  v-if="showweek!=-1">{{ showweek }}</dd>
            <dt  v-if="showyear!=-1">{{ $t("labels.year") }}</dt>
            <dd  v-if="showyear!=-1">{{ showyear }}</dd>
          </dl>
       </b-col>
          <b-col v-if="showweek==-1 || showyear==-1" >
            <div class="form-row">
              <div class="col-3">
                <label for="">{{ $t("labels.week") }}</label>
              </div>
              <div class="col">
                <select v-model="showweek" class="form-control">
                  <option v-for="n in 53 " :key="n" :value="n">{{n}}</option>
                </select>
              </div>
            </div>
            <div class="form-row">
              <div class="col-3">
                <label for="">{{ $t("labels.year") }}</label>
              </div>
              <div class="col">
                <select v-model="showyear" class="form-control">
                  <option v-for="n in 20 " :key="n"> {{2022+n}}</option>
                </select>            
              </div>
            </div>
          </b-col>
          <b-col v-else>
            <b-button :style="AppPrimaryButton()" @click="showAll()">{{ $t("buttons.showall") }}</b-button>
          </b-col>       
        </b-row>
        <hr>
        

        <div v-for="(project, index) in contact_user_projects" :key="index">
            <div v-if="signedFor(project.contract_id)>0"  class="project active">
              <div class="project-title" >
                <h4 class="mb-0">{{username_by_id(project.zzpr)}}</h4>
                <div class="projectnumber">{{ project.projectnumber }}</div>

                <p>{{project.project_name}}</p>
            </div>  
            <b-row>
                <b-col cols="2"></b-col>
                <b-col><strong>{{$t('labels.week')}}</strong></b-col>
              </b-row>          
          </div>
            <div v-for="(weeks, windex) in contractHours(project.contract_id)" :key="windex">
              <div v-if="weeks.signed!=0 && (showweek==-1 || weeks.week==parseInt(showweek))" @click="pageUserHours(project.contract_id,weeks.week, weeks.year)">
                <b-row class="fillhours">
                  <b-col  cols="2">
                    <font-awesome-icon icon='eye' class="table-contacts-roles"  />
                  </b-col>
                  <b-col>{{weeks.week}}</b-col>
                  <b-col cols="8">
                    <div v-html="gethoursinfo(project.contract_id,weeks)" />
                  </b-col>                  
                </b-row>

              </div>
            </div>
            <hr v-if="signedFor(project.contract_id)>0">

          </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  name: 'signer-project-overview',
  data() {
    return {
      showweek: -1,
      showyear:-1,
      componentKey: 0,

    }
  },
  props: {
    user_id: Number,
    show_archive: String,
    index: Number
  },
  async mounted() {
    this.showyear = new Date().getFullYear()
    
  },  
  computed: {
    user: {
      get() {
        return this.user_by_id(this.user_id)
      },
    },
    contact_user_projects() {
      let myprojects = []
      for (var i = 0; i < this.contracts.length; i++) {
        if (this.contracts[i].user_id != null && this.contracts[i].project_id != null) {
          let planning = this.planning_by_id(this.contracts[i].planning_id)
          if (planning && planning.sign_id == this.user_id) {
            var count = 0
            this.contractHours(this.contracts[i].id).forEach(weeks => {
              if (weeks.closed && weeks.signed == 0 && weeks.unsigned!=0 ) {
                count++
              }
            })
            let row = {}
            row.id = this.contracts[i].id;
            row.contract_id = this.contracts[i].id;
            row.project_id = this.contracts[i].project_id;
            row.planning_id = this.contracts[i].planning_id;
            row.company_id = this.project_by_id(this.contracts[i].project_id)?this.project_by_id(this.contracts[i].project_id).company_id:null;
            row.project_name = planning.project_name
            row.project_location = planning.project_location
            row.sortdate = planning.startdate
            row.projectnumber = planning.project_reference_number

            row.startdate = this.formatDate(planning.startdate)
            row.enddate = this.formatDate(planning.enddate)
            row.zzpr = this.contracts[i].user_id
            row.zzprname = this.contracts[i].user_id
            row.signed_company = this.contracts[i].signed_company
            row.hours = this.has_hours(row.contract_id)
            //check old projects
            var now = new Date();
            if (now < this.plus1week(planning.enddate) || this.show_archive != "0" || count > 0) {
              if(this.index==3){
                if(this.showweek==-1||this.showyear==-1){
                  // myprojects.push(row)
                  let startyear = parseInt(this.showyear)    
                  if(this.contractHours(row.id).filter(ch => ch.year==startyear && ch.signed !=0).length>0){
                    myprojects.push(row)
                  }

                } else {
                  let startweek = parseInt(this.showweek)      
                  let startyear = parseInt(this.showyear)    
                  if(this.contractHours(row.id).filter(ch =>ch.week==startweek && ch.year==startyear && ch.signed !=0).length>0){
                    myprojects.push(row)
                  }
                }
              } else{
                myprojects.push(row)
              }
            }
          }
        }
      }
      this.$emit('totalsdone', "done")   

      return this.sortprojects(myprojects)
    },
    contactuserfields() {
      return [
        { key: 'startdate', label: this.$i18n.t('labels.startdate'), sortable: true, sortDirection: 'desc' },
        { key: 'enddate', label: this.$i18n.t('labels.enddate'), sortable: true, sortDirection: 'desc' },
        { key: 'zzpr', label: this.$i18n.t('labels.photo') },
        { key: 'zzprname', label: this.$i18n.t('labels.name') },
        { key: 'project_name', label: this.$i18n.t('labels.title'), sortable: true, sortDirection: 'desc' },
        { key: 'project_location', label: this.$i18n.t('labels.location'), sortable: true, sortDirection: 'desc' },
        { key: 'id', label: this.$i18n.t('titles.project_hours'), },
      ]
    },
    totalClosed() {
      var count = 0
      this.contact_user_projects.forEach(project => {
        this.contractHours(project.contract_id).forEach(weeks => {
          if (weeks.closed && weeks.signed == 0) {
            // console.log(this.invoiceextraweek(project.contract_id,weeks.week, weeks.year))
            if(this.invoiceextraweek(project.contract_id,weeks.week, weeks.year).length>0 &&this.invoiceextraweek(project.contract_id,weeks.week, weeks.year )[0].absent==0)
              count++
            if(this.invoiceextraweek(project.contract_id,weeks.week, weeks.year).length==0)
              count++

          }
        })
      })
      return count
    },
    totalSigned() {
      var count = 0
      this.contact_user_projects.forEach(project => {
        this.contractHours(project.contract_id).forEach(weeks => {
          if (weeks.signed > 0) {
            count++
          }
        })
      })
      return count
    },
    ...mapGetters([
      'user_metas',
      'user_by_id',
      'activebranches',
      'user_new',
      'branche_by_id',
      'createAny',
      'planning_by_id',
      'contract_by_id',
      'project_by_id',
      'username_by_id',
      'find_contract_id',
      'user_photo_by_id',
      'server_url',
      'append_data_string',
      'confirm_modal',
      'company_by_id',
      'find_last_planning_user',
      'companyname_by_id'



    ]),
    ...mapState([
      'users',
      'current_user',
      'branches',
      'workfields',
      'avatars',
      'projects',
      'contracts',
      'hours',
      'companies',
      'planning',
      'urenbonnen',
      'invoiceextra'

    ]),
  },
  methods: {
    usershours(contract_id, user_id) {
      return this.hours.filter(obj => {
        return obj.user_id === user_id
      }).filter(obj2 => {
        return obj2.contract_id === contract_id
      })
    },
    invoiceextraweek(contract_id, weeknumber,yearnumber) {
      return this.invoiceextra.filter(i => i.contract_id == contract_id && i.week == weeknumber && i.year == yearnumber)
    }, 
    pageUserHours(contract, week, year) {
      this.$router.push({ path: '/userhours/' + contract + "/" + week + "/" + year });

    },
    sortprojects(myprojects) {
      let a = myprojects.sort((a, b) => (a.sortdate > b.sortdate) ? -1 : ((b.sortdate > a.sortdate) ? 1 : 0))
      if (this.show_archive == "0") {
        //      a = a.slice(0, 3);
      }
      this.componentKey++

      return a
    },
    closedFor(contract_id) {
      var count = 0
      this.contractHours(contract_id).forEach(weeks => {
        if (weeks.closed && weeks.signed == 0) {
          if((this.invoiceextraweek(contract_id,weeks.week, weeks.year).length>0 &&this.invoiceextraweek(contract_id,weeks.week, weeks.year )[0].absent==0) || (this.invoiceextraweek(contract_id,weeks.week, weeks.year).length==0))
            count++          
        }
      })
      return count
    },
    signedFor(contract_id) {
      var count = 0
      this.contractHours(contract_id).forEach(weeks => {
        if (weeks.closed && weeks.waiting == 0) {
          count++
        }
      })
      return count
    },
    ...mapActions([
      'save_user',
      'add_user',
      'save_contract',
    ]),
    has_hours(contract_id) {
      return this.hours.filter(ah =>ah.contract_id == contract_id).length
    },


    sign_by_zzpr(contract) {
      this.contract_by_id(contract.id).signed_zzpr = 1
      this.save_contract(contract.id);
    },
    gethoursinfo(contract_id, week) {
      var totalnormal = 0
      var declaraties = new Object()
      let contract = this.contract_by_id(contract_id)
      let userplanning = this.planning_by_id(this.contract_by_id(contract_id).planning_id)
      // let start = this.getWeekNumber(new Date(userplanning.startdate))
      // let end = this.getWeekNumber(new Date(userplanning.enddate))
      let weeknumber = parseInt(week.week)
      let contractstart = new Date(userplanning.startdate);
      let contractend = new Date(userplanning.enddate);
      // let startdate = new Date(start[0], 0, 1);
      // if(startdate.getDay()==1){
      //   startdate.setDate(startdate.getDate() + ((weeknumber-1) * 7));

      // } else {
      //   startdate.setDate(startdate.getDate() + (weeknumber * 7));

      // }
      let year = parseInt(week.year)
      // fix year gap
      // if(weeknumber<parseInt(start[1])){
      //   weeknumber--
      //   year+=1
      // }
      var startdate = new Date(this.getWeekOfYear(year,weeknumber)[0])
      // console.log(">STARTDATE ", week, start[1], start[0], startdate)

      if (startdate < new Date(userplanning.startdate)) {
        startdate = new Date(userplanning.startdate)
      }
      // let enddate = new Date(end[0], 0, 1);
      // if(enddate.getDay()==1){
      //   enddate.setDate(enddate.getDate() + ((weeknumber-1) * 7) + 2);

      // }else{
      //   enddate.setDate(enddate.getDate() + ((weeknumber) * 7) + 2);

      // }
      let enddate = new Date(startdate.getTime()+6*24*60*60*1000)
      if (enddate > new Date(userplanning.enddate)) {
        enddate = new Date(userplanning.enddate)
      }
      // let startmonday = this.getMonday(startdate);


      var loop = new Date(startdate);
      let alldates = []
      var tddate = new Date(loop);
      tddate.setDate(tddate.getDate() + 6)
      console.log( "contractinfo", loop, tddate)
      while (loop < tddate) {
        let thisday = this.dateToYYYYMMDD(loop)
        if (loop.setHours(0,0,0) < contractstart.setHours(0,0,0) || loop.setHours(0,0,0) > contractend.setHours(0,0,0)) {
          alldates.push("0")
        } else {
          alldates.push(thisday)
        }
        var newDate = loop.setDate(loop.getDate() + 1);
        loop = new Date(newDate);
      }
      if (alldates) {
        for (var adate of alldates) {
          
          for (var ahour of this.hours.filter(ah => ah.user_id==contract.user_id)) {
            if (ahour.contract_id == contract.id && ahour.workdate == adate && ahour.user_id == contract.user_id) {
              if(ahour.hours) {
                totalnormal += parseFloat(ahour.hours)
              }
              for (const onregel of contract.onregelmatig) {
                let declaratie_cat = this.declaratie_by_id(onregel.declaratie_id)
                if (ahour[declaratie_cat.name] != undefined && declaratie_cat.active == 1) {
                  if (declaratie_cat.name in declaraties) {
                    if(ahour[declaratie_cat.name]) {
                      declaraties[declaratie_cat.name] += parseFloat(ahour[declaratie_cat.name])
                    }
                  } else {
                    if(ahour[declaratie_cat.name]) {
                      declaraties[declaratie_cat.name] = parseFloat(ahour[declaratie_cat.name])
                    }
                  }
                }
              }
            }
          }
        }
      }
      var retstring = this.$i18n.t("labels.totalhours") + "<strong> " + totalnormal + "</strong><br/>";
      for (var k in declaraties) {
        retstring += k + "<strong> " + declaraties[k] + "</strong><br/>"
      }
      //urenbonnen
      let bonnen = this.urenbonnen.filter(bon => {
        return bon.contract_id == contract_id && bon.week == week && bon.name.substring(0,7)!="intern_"
      })
      if (bonnen.length > 0) {
        // retstring += "€"
      }
      return retstring
    },
    showAll(){
      this.showweek = -1
      this.showyear = new Date().getFullYear()
    }    
  },
  watch:{
    index (){
      this.showweek = -1
    },
    hours: function () {
      this.generated_my_projects = [];
      this.flushContractHours()
      this.$emit('totalsdone', "done")   
      this.componentKey++;   
      // this.flushContractHours()
      // this.$emit('totalsdone', "done")
    },    
  }

}
</script>